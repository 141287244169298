import React, {useEffect, useState} from 'react';
import {Modal, Table} from 'react-bootstrap';
import {isoToFormattedDate, trimToHostname} from "../../utils/utils";
import arrow from '../../assets/images/arrow-orders.svg';
import {makeStyles} from '@material-ui/core';
import Order, {OrderTask, OrderTaskStatus} from "../../models/Order";
import {useLocation, useNavigate} from "react-router-dom";
import {useApiService} from "../../context/ApiServiceContext";
import {SiteType} from "../../models/Link";
import {AttachFile} from "@material-ui/icons";

import boostImg from "../../assets/images/rego-arrow.png"
import premiumImg from "../../assets/images/diamond.png"
import superRegoImg from "../../assets/images/super-links-icon.png"
import packageIcon from "../../assets/images/asset-shopping.png"
import eyeIcon from "../../assets/images/eyeIcon.svg"
import Spinner from "../../views/Spinner";

const typeToImage: any = {
    [SiteType.BOOST]: boostImg,
    [SiteType.SUPER_REGO]: superRegoImg,
    [SiteType.PREMIUM]: premiumImg
}

const useStyles = makeStyles({
    customTableHeader: {
        '& thead th': {
            backgroundColor: '#f5f5f5', // Light purple background
            color: '#6E6893',           // Dark purple text
            border: 'none',
        },
        '& tbody tr': {
            border: 'none',
            lineHeight: "36px"
        },
        '& tbody td': {
            border: 'none',
        },
    },
    tableOpen: {
        '& td': {
            color: "#6E6893",
            backgroundColor: '#CBC7FD'
        }
    },
    tableOpenBoost: {
        '& td': {
            color: "#6E6893",
            backgroundColor: '#E0DFF0!important'
        }
    },
    tableOpenSuper: {
        '& td': {
            color: "#6E6893",
            backgroundColor: '#FEF6EC!important'
        }
    },
    tableOpenPremium: {
        '& td': {
            color: "#6E6893",
            backgroundColor: '#EEF9ED!important'
        }
    },
    taskRowOdd: {
        '&:nth-child(even) td': {
            backgroundColor: '#FAFAFF!important'
        }
    },
    evenRowMain: {
        '&:nth-child(even) td': {
            backgroundColor: '#FAFAFF', // Replace with your desired color
        },
    },
    status: {
        padding: "4px 10px",
        borderRadius: "5px"
    },
    buttonTable: {
        padding: "4px 10px",
        borderRadius: "5px",
        border: "1px solid #AB9FED",
        cursor: "pointer"
    }
});


interface Props {
    handleClose: () => void;
    open: boolean;
    openOrdersOnly: boolean;
}

const OrderListModal: React.FC<Props> = ({handleClose, open, openOrdersOnly}) => {
    const apiService = useApiService();
    const location = useLocation();
    const classes = useStyles();
    const navigate = useNavigate();
    const [orders, setOrders] = useState<Order[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openTasks, setOpenTasks] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        if (!open) return;

        setIsLoading(true);

        let params = '';
        if (openOrdersOnly) {
            params = '?openOrdersOnly=true'
        }

        apiService.api.get(`/order/list${params}`)
            .then(response => {
                setOrders(response.data);
                // Initialize all task lists as open
                const tasksVisibility = {}
                // const tasksVisibility = response.data.reduce((acc: Record<string, boolean>, order: Order) => {
                //     acc[order.id] = true; // Set to false if you want all task lists to be closed initially
                //     return acc;
                // }, {});
                setOpenTasks(tasksVisibility);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching orders:', error);
                setIsLoading(false);
            });
    }, [open]);

    const toggleTaskVisibility = (orderId: string) => {
        setOpenTasks(prevOpenTasks => ({
            ...prevOpenTasks,
            [orderId]: !prevOpenTasks[orderId]
        }));
    };

    function openOrder(id: string) {
        const currentPath = location.pathname;
        navigate(`${currentPath}?showOrder=${id}`, {replace: true});
    }

    function getStatusDisplay(status: string) {
        if (status === OrderTaskStatus.COMPLETED) {
            return 'באוויר'
        } else if (status === OrderTaskStatus.SENT) {
            return 'נשלח לביצוע'
        } else if (status === OrderTaskStatus.PENDING) {
            return 'ממתין'
        } else if (status === OrderTaskStatus.PENDING_DATA) {
            return 'ממתין לחומרים'
        } else {
            return 'בעבודה'
        }
    }

    function getOrderStatus(tasks: OrderTask[]) {
        const status = tasks[0].status
        if (tasks.every(x => x.status == status)) {
            return status
        } else {
            return undefined
        }
    }

    function getOrderStatusDisplay(tasks: OrderTask[]) {
        const status = getOrderStatus(tasks)
        if (status) {
            return getStatusDisplay(status)
        } else {
            return "-"
        }
    }

    function isPremiumOrder(order: Order) {
        return order.tasks.every(task => task.linkingSite?.type === SiteType.PREMIUM && task.usersContent)
    }

    function getStatusColor(status?: OrderTaskStatus) {
        switch (status) {
            case OrderTaskStatus.PENDING:
                return "#D8D5FB"
            case OrderTaskStatus.IN_PROGRESS:
            case OrderTaskStatus.PENDING_DATA:
            case OrderTaskStatus.SENT:
                return "#EDDC9F"
            case OrderTaskStatus.COMPLETED:
                return "#9feda9"
        }
    }

    function getClassByLinkType(linkType: false | SiteType | undefined) {
        switch (linkType) {
            case SiteType.BOOST:
                return classes.tableOpenBoost
            case SiteType.SUPER_REGO:
                return classes.tableOpenSuper
            case SiteType.PREMIUM:
                return classes.tableOpenPremium
            default:
                return classes.tableOpen
        }
    }

    return (
        <Modal show={open} onHide={handleClose} centered={true} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className="me-3">ההזמנות שלי</span>
                    {isLoading && <Spinner size={35}/>}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight: '500px', overflowY: 'auto', padding: 0}}>
                {isLoading ? (
                    <div className="p-3 fs-5">טוענים את רשימת ההזמנות, זה יקח עוד רגע</div> // Replace with a proper loading spinner or skeleton loader
                ) : (
                    orders.length == 0 ?
                        <div className="p-3 fs-5">אין הזמנות להציג, רכוש את הקישור הראשון שלך על מנת ליצור הזמנה</div>
                        :
                        <Table hover responsive className={`text-center ${classes.customTableHeader}`}>
                            <thead>
                            <tr>
                                <th></th>
                                <th>כמות קישורים</th>
                                <th>מחיר כולל</th>
                                <th>תאריך הזמנה</th>
                                <th>סטטוס</th>
                                <th></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {orders.map((order, index) => {
                                const linkType = order.itemType === 'link' && order.tasks[0].linkingSite?.type
                                let typeImg = packageIcon;
                                if (order.itemType === 'link') {
                                    typeImg = linkType && typeToImage[linkType]
                                }
                                return (<React.Fragment key={order.id}>
                                    <tr className={`${classes.evenRowMain} ${openTasks[order.id] && getClassByLinkType(linkType)}`}
                                        onClick={() => toggleTaskVisibility(order.id)} style={{cursor: 'pointer'}}>
                                        <td>{
                                            typeImg && <img style={{height: "18px"}} src={typeImg}
                                                            alt="link type logo"/>
                                        }</td>
                                        <td>{`${order.tasks.filter(x => x.status != 'pending').length}/${order.tasks.length}`}</td>
                                        <td>{order.totalPrice}₪</td>
                                        <td>{isoToFormattedDate(order.createdAt)}</td>
                                        <td>
                                            {
                                                <span className={`${classes.status} text-nowrap`} style={{
                                                    backgroundColor: getStatusColor(getOrderStatus(order.tasks))
                                                }}>
                                                                    {getOrderStatusDisplay(order.tasks)}
                                                                </span>
                                            }
                                        </td>
                                        <td>
                                            {
                                                <span className={`${classes.buttonTable} text-nowrap`}
                                                      onClick={() => openOrder(order.id)}>
                                                    <img src={eyeIcon} alt="הצג" className="pe-2"/>
                                                    <span style={{color: "#8577D4"}}>
                                                                    לצפייה בהזמנה
                                                    </span>
                                                </span>
                                            }
                                        </td>
                                        <td>
                                            <img
                                                src={arrow}
                                                alt="Toggle Details"
                                                onClick={() => toggleTaskVisibility(order.id)}
                                                style={{
                                                    pointerEvents: 'none',
                                                    transform: openTasks[order.id] ? 'rotate(180deg)' : 'none',
                                                    transition: 'transform 0.3s ease'
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    {openTasks[order.id] && (
                                        <tr>
                                            <td colSpan={7} style={{padding: 0}}>
                                                <Table size="sm" style={{margin: 0}}
                                                       onClick={() => openOrder(order.id)}>
                                                    <thead>
                                                    <tr>
                                                        <th>מספר קישור</th>
                                                        <th>אתר מקשר</th>
                                                        <th>אנקור</th>
                                                        <th>קישור לאנקור</th>
                                                        <th>תאריך הזמנה</th>
                                                        <th>תאריך ביצוע</th>
                                                        {isPremiumOrder(order) && <th>קובץ</th>}
                                                        <th>סטטוס</th>
                                                        <th></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {order.tasks.map((task, taskIndex) => (
                                                        <tr key={taskIndex} style={{cursor: 'pointer'}}
                                                            className={classes.taskRowOdd}>
                                                            <td>{taskIndex + 1}</td>
                                                            <td>{trimToHostname(task.linkingSite?.hostname) || '-'}</td>
                                                            <td>{task.anchor || '-'}</td>
                                                            <td>{trimToHostname(task.anchorLink) || '-'}</td>
                                                            <td>{isoToFormattedDate(task.orderTime) || '-'}</td>
                                                            <td>{isoToFormattedDate(task.scheduledTime) || '-'}</td>
                                                            {isPremiumOrder(order) && <td>{task.documentUrl &&
                                                                <AttachFile fontSize="small"/> || '-'}</td>}
                                                            <td>
                                                                <span className={`${classes.status} text-nowrap`}
                                                                      style={{
                                                                          backgroundColor: getStatusColor(task.status)
                                                                      }}>
                                                                    {getStatusDisplay(task.status)}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {task.articleUrl &&
                                                                    <span className={`${classes.buttonTable} text-nowrap`}
                                                                          onClick={(e) => {
                                                                              e.stopPropagation()
                                                                              window.open(task.articleUrl, "_blank")
                                                                          }}>
                                                                        <img src={eyeIcon} alt="הצג" className="pe-2"/>
                                                                        <span style={{color: "#8577D4"}}>
                                                                                        לצפייה בקישור
                                                                        </span>
                                                                    </span>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>)
                            })}
                            </tbody>
                        </Table>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default OrderListModal;
