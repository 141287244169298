import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, Modal, Row, Table} from 'react-bootstrap';
import {toast} from "react-toastify";
import ConfirmationModal from "../../../utils/ConfirmationModal";
import * as yup from 'yup';
import {useFormik} from 'formik';
import {AxiosResponse} from "axios";
import {useApiService} from "../../../context/ApiServiceContext";
import Category from "../../../models/Category";
import Promo from "../../../models/Promo";
import Package from "../../../models/Package";
import Link from "../../../models/Link";
import {useNavigate, useParams} from "react-router-dom";
import promo from "../../../models/Promo";
import {PackageRequestBody} from "../AdminPackages";
import {CircularProgress} from "@mui/material";


interface AdminPromoPackageProps {
    handleClose: () => void;
    open: boolean;
    selectedPackage: Package | null
    onSubmitCallback?: (updatedPackageData: Package) => void;
}

const AdminPromoPackage: React.FC<AdminPromoPackageProps> = ({
                                                                 handleClose,
                                                                 open,
                                                                 selectedPackage,
                                                                 onSubmitCallback
                                                             }) => {
    const apiService = useApiService();
    const [categories, setCategories] = useState<Category[]>([]);
    const [isFileUploading, setFileUploading] = useState<boolean>(false);
    const navigate = useNavigate()

    const [selectedSubCategories, setSelectedSubCategories] = useState<{ id: string, name: string }[]>(selectedPackage?.subCategories ?? []);

    // Function to handle subcategory selection
    const handleSelectSubCategory = (categoryId: string) => {
        const category = categories.find(c => c.id === categoryId);
        if (category && !selectedSubCategories.find(sc => sc.id === categoryId)) {
            setSelectedSubCategories([...selectedSubCategories, {id: categoryId, name: category.name}]);
        }
    };

    // Function to remove a selected subcategory
    const removeSubCategory = (categoryId: string) => {
        setSelectedSubCategories(selectedSubCategories.filter(sc => sc.id !== categoryId));
    };

    useEffect(() => {
        formik.setFieldValue("subCategoryIds", selectedSubCategories.map(sc => sc.id));
    }, [selectedSubCategories]);

    const fetchCategories = useCallback(async () => {
        try {
            const res = await apiService.api.get('/category/list?per_page=50');
            setCategories(res.data);
        } catch (error: any) {
            toast.error(`בעיה בעת משיכת הקטגוריות: ${error.message}`);
        }
    }, [apiService]);

    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    useEffect(() => {
        if (open) {
            formik.resetForm({
                values: {
                    packageName: selectedPackage ? selectedPackage.name : '',
                    linksCount: selectedPackage ? selectedPackage.linksCount : 0,
                    categoryId: selectedPackage ? selectedPackage.category.id : '',
                    subCategoryIds: selectedPackage ? selectedPackage.subCategories.map(x => x.id) : [],
                    siteType: selectedPackage ? selectedPackage.siteType : '',
                    thumbnail: selectedPackage ? selectedPackage.thumbnail || '' : '',
                    price: selectedPackage ? selectedPackage.price : 0,
                    info: {
                        title: selectedPackage ? selectedPackage.info?.title || '' : '',
                        description: selectedPackage ? selectedPackage.info?.description || '' : '',
                        bullets: [...(selectedPackage?.info?.bullets || []), '', '', ''].slice(0, 3),
                        oldPrice: selectedPackage ? selectedPackage.info?.oldPrice || 0 : 0,
                        discountPercentage: selectedPackage ? selectedPackage.info?.discountPercentage || 0 : 0,
                    },
                }
            })
        }
    }, [open]);

    // Package validation schema
    const packageSchema = yup.object().shape({
        packageName: yup.string()
            .required('שם החבילה נדרש')
            .min(2, 'שם החבילה קצר מדי')
            .max(50, 'שם החבילה ארוך מדי'),
        linksCount: yup.number()
            .required('נדרש מספר קישורים')
            .min(0, 'מספר הקישורים לא יכול להיות שלילי'),
        categoryId: yup.string()
            .required('קטגוריה נדרשת'),
        subCategoryIds: yup.array().of(yup.string().required('תת-קטגוריות נדרשת'))
            .min(0, 'נדרשת לפחות תת-קטגוריות אחת'),
        siteType: yup.string()
            .required('סוג האתר נדרש'),
        price: yup.number()
            .required('מחיר נדרש')
            .min(0, 'המחיר לא יכול להיות שלילי'),
        info: yup.object({
            description: yup.string(),
            bullets: yup.array().of(yup.string()),
            oldPrice: yup.number()
                .min(0, 'המחיר לא יכול להיות שלילי'),
            discountPercentage: yup.number()
                .min(0, 'המחיר לא יכול להיות שלילי'),
        }),
    });

    const handleUpdate = (packageId: string, updatedPackageData: PackageRequestBody) => {
        apiService.api
            .put<Package>(`/package/${packageId}`, {
                ...updatedPackageData,
                type: "promo"
            })
            .then((response: AxiosResponse<Package>) => {
                onSubmitCallback?.(response.data)
                handleClose()
                toast.success('החבילה עודכנה בהצלחה');
            })
            .catch(() => {
                toast.error('תקלה בעת עדכון החבילה');
            });

    };

    const handleCreate = (newPackageData: PackageRequestBody) => {
        apiService.api
            .post('/package/', {
                ...newPackageData,
                type: "promo"
            })
            .then((packageRes: AxiosResponse<Package>) => {
                onSubmitCallback?.(packageRes.data)
                handleClose()
                toast.success('החבילה הוספת בהצלחה');
            })
            .catch(() => {
                toast.error('בעיה בעת הוספת החבילה');
            });
    };

    const formik = useFormik({
        initialValues: {
            packageName: '',
            linksCount: 0,
            categoryId: '',
            subCategoryIds: [] as string[],
            siteType: '',
            thumbnail: '',
            price: 0,
            info: {
                title: '',
                description: '',
                bullets: ['', '', ''],
                oldPrice: 0,
                discountPercentage: 0,
            },
        }, // Add other fields
        validationSchema: packageSchema,
        onSubmit: values => {
            if (formik.isValid) {
                const packageData = {
                    name: values.packageName,
                    linksCount: values.linksCount,
                    categoryId: values.categoryId,
                    subCategoryIds: values.subCategoryIds,
                    siteType: values.siteType,
                    price: values.price,
                    thumbnail: values.thumbnail === '' ? undefined : values.thumbnail,
                    info: {
                        title: values.info?.title,
                        description: values.info?.description,
                        bullets: values.info?.bullets?.filter(bullet => bullet.trim() !== ''),
                        oldPrice: values.info?.oldPrice,
                        discountPercentage: values.info?.discountPercentage,
                    },
                };

                if (selectedPackage) {
                    handleUpdate(selectedPackage.id, packageData);
                } else {
                    handleCreate(packageData);
                }
            }
        },
        validateOnBlur: true,
        validateOnMount: true,
    });


    const handleFileChange = (files: File[]) => {
        if (files && files.length > 0) {
            setFileUploading(true)

            const formData = new FormData();
            files.forEach((file, index) => {
                formData.append(`file${index}`, file);
            });

            apiService.api.post(`/package/thumbnail/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    formik.setFieldValue('thumbnail', response.data.urls[0]);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setFileUploading(false)
                });
        }
    };


    return (
        <Modal show={open} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{selectedPackage ? 'ערוך חבילה' : 'הוסף חבילה חדשה'}</Modal.Title>
            </Modal.Header>
            <Form noValidate onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>שם החבילה</Form.Label>
                        <Form.Control
                            type="text"
                            name="packageName"
                            value={formik.values.packageName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.packageName && !!formik.errors.packageName}
                            placeholder="הכנס את שם החבילה"
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.packageName}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>כמות קישורים</Form.Label>
                        <Form.Control
                            type="number"
                            name="linksCount"
                            value={formik.values.linksCount}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.linksCount && !!formik.errors.linksCount}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.linksCount}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>קטגוריה</Form.Label>
                        <Form.Control
                            as="select"
                            name="categoryId"
                            value={formik.values.categoryId}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.categoryId && !!formik.errors.categoryId}
                        >
                            <option value="">בחר קטגוריה</option>
                            {categories.map(category => (
                                <option key={category.id} value={category.id}>{category.name}</option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.categoryId}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>תת-קטגוריות</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={(e) => handleSelectSubCategory(e.target.value)}
                            defaultValue=""
                        >
                            <option value="" disabled>בחר תת-קטגוריות</option>
                            {categories.map(category => (
                                <option key={category.id} value={category.id}>{category.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    {/* Display Selected Subcategories */}
                    <ul>
                        {selectedSubCategories.map(sc => (
                            <li key={sc.id}>
                                {sc.name}
                                <Button variant="danger" className="p-0 ms-1"
                                        style={{width: "20px", height: "20px", lineHeight: "12px"}}
                                        onClick={() => removeSubCategory(sc.id)}>X</Button>
                            </li>
                        ))}
                    </ul>

                    <Form.Group className="mb-3">
                        <Form.Label>סוג האתר</Form.Label>
                        <Form.Control
                            as="select"
                            name="siteType"
                            value={formik.values.siteType}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.siteType && !!formik.errors.siteType}
                        >
                            <option value="">בחר סוג אתר</option>
                            <option value="boost">Boost</option>
                            <option value="super">SuperRego</option>
                            <option value="premium">Premium</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.siteType}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {/*<Form.Group className="mb-3">*/}
                    {/*    <Form.Label>אתר חזק</Form.Label>*/}
                    {/*    <Form.Check*/}
                    {/*        type="checkbox"*/}
                    {/*        name="powerSite"*/}
                    {/*        label="אתר חזק"*/}
                    {/*        checked={formik.values.powerSite}*/}
                    {/*        onChange={formik.handleChange}*/}
                    {/*    />*/}
                    {/*</Form.Group>*/}

                    <Form.Group className="mb-3">
                        <Form.Label>מחיר</Form.Label>
                        <Form.Control
                            type="number"
                            name="price"
                            value={formik.values.price}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.price && !!formik.errors.price}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.price}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>מחיר לפני הנחה</Form.Label>
                        <Form.Control
                            type="number"
                            name="info.oldPrice"
                            value={formik.values.info.oldPrice}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.info?.oldPrice && !!formik.errors.info?.oldPrice}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.info?.oldPrice}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>אחוזי הנחה</Form.Label>
                        <Form.Control
                            type="number"
                            name="info.discountPercentage"
                            value={formik.values.info.discountPercentage}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.info?.discountPercentage && !!formik.errors.info?.discountPercentage}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.info?.discountPercentage}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>תמונה
                            {formik.values.thumbnail && formik.values.thumbnail.trim() !== "" &&
                                <>
                                    {" ("}
                                    <a href={formik.values.thumbnail} target="_blank">פתח בחלון חדש</a>
                                    {")"}
                                </>
                            }
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="thumbnail"
                            placeholder="הכנס תמונה ממוזערת"
                            value={formik.values.thumbnail}
                            onChange={formik.handleChange}
                            isInvalid={formik.touched.thumbnail && !!formik.errors.thumbnail}
                            disabled
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.thumbnail}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId={`file`}>
                        <Form.Label>
                            <div>
                                <span>העלאת קובץ לתמונה</span>
                                <CircularProgress className="ms-3" color="secondary"
                                                  size={13}
                                                  hidden={!isFileUploading}/>
                            </div>
                        </Form.Label>
                        <Form.Control
                            type="file"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleFileChange(e.target.files ? Array.from(e.target.files) : [])}
                            accept=".png,.jpg,.jpeg,.webp"
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>תיאור</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            name="info.description"
                            placeholder="הכנס תיאור"
                            value={formik.values.info.description}
                            onChange={formik.handleChange}
                            isInvalid={formik.touched.info?.description && !!formik.errors.info?.description}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.info?.description}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>נקודה 1</Form.Label>
                        <Form.Control
                            type="text"
                            name="info.bullets[0]"
                            value={formik.values.info.bullets?.[0]}
                            onChange={formik.handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>נקודה 2</Form.Label>
                        <Form.Control
                            type="text"
                            name="info.bullets[1]"
                            value={formik.values.info.bullets?.[1]}
                            onChange={formik.handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>נקודה 3</Form.Label>
                        <Form.Control
                            type="text"
                            name="info.bullets[2]"
                            value={formik.values.info.bullets?.[2]}
                            onChange={formik.handleChange}
                        />
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>סגור</Button>
                    <Button type="submit" variant="primary" disabled={isFileUploading}>{selectedPackage ? 'עדכן' : 'צור'}</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default AdminPromoPackage;
