import {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {Navigate} from 'react-router-dom';
import {AppDispatch} from "../utils/store";
import {useDispatch, useSelector} from "react-redux";
import {checkLoginStatus, selectIsLoggedIn} from "../utils/userSlice";
import {useApiService} from "../context/ApiServiceContext";

interface RequireAuthProps {
    children: React.ReactNode;
    redirectTo: string;
}

const RequireAuth: React.FC<RequireAuthProps> = ({children, redirectTo}) => {
    const apiService = useApiService();
    const dispatch: AppDispatch = useDispatch();
    const [cookies] = useCookies(['accessToken']);
    const [isAdmin, setIsAdmin] = useState<boolean | null>(null);

    const isLoggedIn = useSelector(selectIsLoggedIn);

    useEffect(() => {
        const checkAdminStatus = async () => {
            try {
                const response = await apiService.api.get('/user');
                setIsAdmin(response.data.admin);
            } catch (error) {
                setIsAdmin(false);
            }
        };

        if (isLoggedIn !== undefined) {
            if (!isLoggedIn) {
                setIsAdmin(false);
            } else {
                checkAdminStatus();
            }
        }
    }, [dispatch, cookies.accessToken, isLoggedIn]);

    if (isAdmin === null) {
        // Loading state while checking admin status
        return null;
    } else if (!isAdmin) {
        return <Navigate to={redirectTo} replace={true}/>;
    } else {
        return <>{children}</>;
    }
};

export default RequireAuth;
