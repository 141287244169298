import React from "react";
import { Modal, Button } from "react-bootstrap";

interface ConfirmationModalProps {
    show: boolean;
    title: string;
    message: string;
    onClose: () => void;
    onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ show, title, message, onClose, onConfirm }) => (
    <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>
                בטל
            </Button>
            <Button variant="danger" onClick={onConfirm}>
                אשר
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ConfirmationModal;
