import React from 'react';
import {makeStyles} from "@material-ui/core";
import logoStrip from "../../assets/images/logo-strip.png"
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
    logoStrip: {
        width: '100%',
        height: '100px', // Adjust the height as needed
        backgroundImage: `url(${logoStrip})`,
        backgroundRepeat: 'repeat-x',
        backgroundSize: "cover",
        animation: '$scrollLogo 25s linear infinite',
        [theme.breakpoints.down('sm')]: {
            animation: '$scrollLogo 35s linear infinite',
        }
    },
    '@keyframes scrollLogo': {
        '0%': {
            backgroundPosition: '0',
        },
        '100%': {
            backgroundPosition: '2560px', // Adjust this value based on the actual width of your image
        },
    },
}));

const LogoStrip: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.logoStrip}></div>
    );
}

export default LogoStrip;