import {Col, Container, Row} from "react-bootstrap";
import {MenuItem} from "@mui/material";
import {Link} from "react-router-dom";
import React from "react";
import {styled} from "@mui/material/styles";

const StyledCol = styled(Col)(({theme}) => ({
    textAlign: "center",
    "& a, span": {
        display: "inline-block",
        lineHeight: 1,
        color: "white"
    }
}));

export default function Footer() {
    return <Container style={{maxWidth: "unset"}}>
        <Row className={"p-4"} style={{backgroundColor: "#000000"}}>
            <StyledCol>
                <MenuItem component={Link} to="/legal/terms">תנאי שימוש ותקנון</MenuItem>
                <span> | </span>
                <MenuItem component={Link} to="/legal/privacy-policy">מדיניות פרטיות</MenuItem>
                <span> | </span>
                <MenuItem component={Link} to="/legal/accessibility">הצהרת נגישות</MenuItem>
                <span> | </span>
                <MenuItem component={Link} to="/page/about">אודות</MenuItem>
            </StyledCol>
        </Row>
    </Container>;
}