// LinkGrid.tsx
import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {Col, Container, Row} from 'react-bootstrap';
import {Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useApiService} from "../context/ApiServiceContext";
import SkeletonLoader from "./SkeletonLoader";
import right0BG from "../assets/images/bg/purple/right0.png";
import {useNavigate, useParams} from "react-router-dom";
import LegalDocument from "../models/LegalDocument";
import Skeleton from "@mui/material/Skeleton";

const useStyles = makeStyles({
    card: {
        width: '100%',
        height: '220px',
        borderRadius: '10px',
        border: '0',
        boxShadow: '0 8px 13px 2px rgba(163, 163, 163, 0.40)',
        cursor: 'pointer'
    },
    formControl: {
        minWidth: 120,
        marginBottom: 20,
    },
    gridInputImage: {
        boxShadow: `8px 4px 10px 0px rgba(0, 0, 0, 0.12)`,
        border: 0,
        zIndex: 1
    },
    gridInput: {
        boxShadow: `4px 4px 10px 0px rgba(0, 0, 0, 0.12)`,
        border: 0
    },
    bg: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
    },
    cloudStart: {
        position: "absolute",
        right: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
    },
    cloudMiddleStart: {
        position: "absolute",
        top: "150vh",
        right: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
    },
    category: {
        borderRadius: "8px",
        border: "1px solid #EAEDF1",
        background: "#F0EFFA",
        color: "#251FB6",
        display: "inline-block",
        padding: "7px 15px",
        margin: "5px 0!important"
    },
    img: {}
});

const Root = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column'
}));

const Legals: React.FC = ({}) => {
    const {legalPath} = useParams();
    const classes = useStyles({});
    const navigate = useNavigate();

    const [legal, setLegal] = useState<LegalDocument | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const apiService = useApiService();

    useEffect(() => {
        fetchLegals()
    }, [legalPath]);

    const fetchLegals = useCallback(async () => {
        setIsLoading(true)
        // Update the API request to include searchTerm in the query
        apiService.api.get(`/legal-document/${legalPath}`)
            .then(response => {
                setLegal(response.data);
                setIsLoading(false)

                scrollToTop()
            }).catch(() => navigate('/'));
    }, [apiService, legalPath]);

    function scrollToTop() {
        const anchorElement = document.getElementById("main");
        if (anchorElement) {
            const appBarHeight = 100;
            const yOffset = -appBarHeight; // Negative offset to account for the AppBar
            const y = anchorElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }

    return (
        <Root>
            <div className={classes.bg}>
                <div className="float-start w-100" style={{
                    height: "1000px",
                    background: `radial-gradient(circle at -6% 500px in hsl shorter hue, #0060ff8f 0%, rgba(255, 255, 255, 0) 400px)`
                }}></div>
                <img className={classes.cloudStart} src={right0BG}/>
            </div>
            <Container className="mb-4 mt-5 text-center" style={{height: "auto",}}>
                <Row className="justify-content-md-center">
                    <Col xl={5}>
                        {isLoading ?
                            <Skeleton variant="rounded" width={150}
                                      height={80}/>
                            :
                            <Typography variant="h3" style={{marginTop: "10px"}}>{legal?.title}</Typography>
                        }
                    </Col>
                </Row>
                {false && <Row xl={5} className="justify-content-md-center">
                    <Col xl={5}>
                        <Typography variant="body1" style={{marginTop: "10px"}}>לורם איפסום דולור סיט אמט, קונסקטורר
                            אדיפיסינג אלית קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף נולום ארווס סאפיאן -
                            פוסיליס קוויס, אקווזמן.</Typography>
                    </Col>
                </Row>}
            </Container>

            <Container className="mb-4 mt-5" style={{height: "auto",}}>
                <Row>

                    {isLoading ? (
                        <SkeletonLoader amount={1} marginTop={false}/>
                    ) : (

                        <Col
                            xs={12}
                            className="mb-4"
                        >
                            <div dangerouslySetInnerHTML={{__html: legal?.text ?? ''}}/>
                        </Col>
                    )}
                </Row>
            </Container>
        </Root>
    );
};

export default Legals;