import React, {useState, useRef} from 'react';
import {Container, Row} from "react-bootstrap";
import IconButton from '@mui/material/IconButton';
import {PlayCircleFilled} from "@material-ui/icons";
import {styled} from "@material-ui/core";
import mainThumb from '../../assets/images/main-thumb.png';


const NoHoverIconButton = styled(IconButton)({
    height: "100%",
    '&:hover': {
        backgroundColor: 'transparent!important',
    },
    '&:hover::after': {
        content: '""',
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
});

interface MainVideoProps {
    id: string;
}

const MainVideo: React.FC<MainVideoProps> = ({id}) => {
    const [showControls, setShowControls] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    const handlePlayClick = () => {
        setShowControls(true);
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    return (
        <Container id={id} style={{maxHeight: "450px", maxWidth: "700px", margin: "72px auto", position: "relative"}}>
            <Row className="d-inline-block rounded-5 bg-light w-100 m-0 overflow-hidden"
                 style={{aspectRatio: 16 / 9, position: "relative"}}>
                <video preload="auto" ref={videoRef} className="p-0" height="100%" width="100%" controls={showControls}
                       poster={mainThumb}>
                    <source src="https://rego-document-files.s3.il-central-1.amazonaws.com/home-video.mp4?"
                            type="video/mp4"/>
                </video>
                {!showControls && (
                    <NoHoverIconButton
                        style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}
                        onClick={handlePlayClick}>
                        <PlayCircleFilled style={{fontSize: 60, color: '#251FB6'}}/>
                    </NoHoverIconButton>
                )}
            </Row>
        </Container>
    );
};

export default MainVideo;
