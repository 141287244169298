import React from 'react';
import LinkBase from "./LinkBase";
import superLinks from  "../../assets/images/super-links-icon.png"
import premium from  "../../assets/images/link/super-bg.png"
import {SiteType} from "../../models/Link";

const LinkSuperRego: React.FC = () => {
    return <LinkBase
        header={{
            title: "קישורי SuperRego",
            subtitle: "כל האתרים שלנו חזקים, אבל יש כאלו שעבדו שעות נוספות במכון :) קטגוריה מובילה זו מציגה את הקישורים האיכותיים והחזקים ביותר מבית ReGo ובעזרתם תוכלו לקדם את המותג שלכם ביעילות ובמהירות.",
        }}
        lineColor="#FC8619"
        subtitle="קישורי SupeReGo"
        subtitleImage={superLinks}
        linkBG={premium}
        siteType={SiteType.SUPER_REGO}
        allowProvideContent={false}
    />;
};

export default LinkSuperRego;