import React, {useCallback, useEffect, useState} from 'react';
import {Modal, Table, Button, Row, Col, Form, InputGroup} from 'react-bootstrap';
import {useApiService} from "../../context/ApiServiceContext";
import Order, {OrderTask, OrderTaskBody, OrderTaskStatus} from "../../models/Order";
import {isoToFormattedDate} from "../../utils/utils";
import {toast} from "react-toastify";

export interface AdminOrderTaskBody {
    allowSecondAnchor: boolean;
}

const AdminOrders: React.FC = () => {
    const apiService = useApiService();
    const [orders, setOrders] = useState<Order[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const linksPerPage = 20; // Adjust as needed
    /* End of Pagination */

    const [expandedOrderId, setExpandedOrderId] = useState<string | null>(null);
    const [searchEmail, setSearchEmail] = useState('');
    const [searchId, setSearchId] = useState('');

    const fetchOrders = useCallback(async (page = 1, email: string | null = null, orderId: string | null = null) => {
        try {
            setIsLoading(true);
            let url = `/admin/order/list?page=${page}&per_page=${linksPerPage}`;
            if (email) {
                url += `&email=${encodeURIComponent(email)}`;
            }
            if (orderId) {
                url += `&id=${encodeURIComponent(orderId)}`;
            }
            const res = await apiService.api.get(url);
            if (Array.isArray(res.data)) {
                setOrders(prevOrders => page === 1 ? res.data : [...prevOrders, ...res.data]);
                setHasMorePages(res.data.length === linksPerPage);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching orders:', error);
            setIsLoading(false);
        }
    }, [linksPerPage]);

    useEffect(() => {
        setOrders([])
        fetchOrders(currentPage, searchEmail, searchId);
    }, [currentPage, fetchOrders, searchEmail, searchId]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: "email" | "id") => {
        if (type === 'email') {
            setSearchEmail(e.target.value);
        } else if (type === 'id') {
            setSearchId(e.target.value);
        }
    };

    const handleSearch = (e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>, type: 'email' | 'id') => {
        e.preventDefault();
        setCurrentPage(1); // Reset to the first page
    };

    const toggleOrderDetails = (orderId: string) => {
        if (expandedOrderId === orderId) {
            setExpandedOrderId(null); // Hide details if clicking the same order
        } else {
            setExpandedOrderId(orderId); // Show details for the clicked order
        }
    };

    const updateOrder = (orderId: string, payload: AdminOrderTaskBody) => {
        return apiService.api.put<OrderTask>(`/admin/order/${orderId}`, payload);
    };

    const toggleAllowSecondAnchor = async (orderId: string, newValue: boolean) => {

        const updatedOrders = orders.map((order) => {
            if (order.id === orderId) {
                // Toggle the allowSecondAnchor value for the matched order
                return { ...order, allowSecondAnchor: !order.allowSecondAnchor };
            }
            return order; // Return other orders as-is
        });

        try {
            await updateOrder(orderId, { allowSecondAnchor: newValue }); // Your API call function
            setOrders(updatedOrders)
            toast.success('ההזמנה עודכנה בהצלחה');
        } catch (error) {
            toast.error("בעיה בעת עדכון ההזמנה")
            console.error("Failed to update order", error);
            // Handle error (e.g., show a notification to the user)
        }
    };

    const PaginationBar = () => {
        return (
            <div className="d-flex justify-content-center my-3">
                <Button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="me-2"
                >
                    הקודם
                </Button>
                <Button
                    disabled={!hasMorePages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    הבא
                </Button>
            </div>
        );
    };

    function getStatusInHebrew(status: OrderTaskStatus) {
        const statusMap = {
            [OrderTaskStatus.PENDING]: "ממתין",
            [OrderTaskStatus.PENDING_DATA]: "ממתין לנתונים",
            [OrderTaskStatus.SENT]: "נשלח",
            [OrderTaskStatus.IN_PROGRESS]: "בתהליך",
            [OrderTaskStatus.COMPLETED]: "הושלם",
        };
        return statusMap[status] || status;
    }

    return (
        <>
            <Row className="justify-content-center py-md-5">
                <Col md={4}>
                    <h1 className="mb-2">הזמנות</h1>
                </Col>
                <Col md={6} className="d-flex justify-content-end align-items-center">
                    <Form onSubmit={event => handleSearch(event, 'email')} className="me-4 w-50">
                        <InputGroup>
                            <Form.Control
                                placeholder="חפש לפי אימייל"
                                onChange={event => handleSearchChange(event, 'email')}
                                value={searchEmail}
                            />
                            <Button variant="outline-secondary" onClick={event => handleSearch(event, 'email')}>
                                חפש
                            </Button>
                        </InputGroup>
                    </Form>
                    <Form onSubmit={event => handleSearch(event, 'id')} className="me-4 w-50">
                        <InputGroup>
                            <Form.Control
                                placeholder="חפש לפי מספר הזמנה"
                                onChange={event => handleSearchChange(event, 'id')}
                                value={searchId}
                            />
                            <Button variant="outline-secondary" onClick={event => handleSearch(event, 'id')}>
                                חפש
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
                <Col md={10}>
                    {isLoading ? (
                        <div>טוען מידע...</div> // Ideally replace with a spinner or skeleton
                    ) : (
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>מספר הזמנה</th>
                                <th>אימייל</th>
                                <th>כמות קישורים</th>
                                <th>מחיר כולל</th>
                                <th>תאריך הזמנה</th>
                                <th>2 אנקורים</th>
                            </tr>
                            </thead>
                            <tbody>
                            {orders.map((order) => (
                                <>
                                    <tr key={order.id} onClick={() => toggleOrderDetails(order.id)} style={{ cursor: 'pointer' }}>
                                        <td>{order.id}</td>
                                        <td>{order.user?.email ?? 'לא נמצא'}</td>
                                        <td>{`${order.tasks.filter((x) => x.status !== 'pending').length}/${order.tasks.length}`}</td>
                                        <td>{order.totalPrice}₪</td>
                                        <td>{isoToFormattedDate(order.createdAt)}</td>
                                        <td>
                                            {order.allowSecondAnchor ? 'כן' : 'לא'}
                                            <span> </span>
                                            <Button
                                                className="small"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent row click
                                                    toggleAllowSecondAnchor(order.id, !order.allowSecondAnchor);
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                שנה
                                            </Button>
                                        </td>
                                    </tr>
                                    {expandedOrderId === order.id && (
                                        <tr>
                                            <td colSpan={6}>
                                                <Table size="sm">
                                                    <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>מיקום הקישור</th>
                                                        <th>אנקורים</th>
                                                        <th>אתר מקושר</th>
                                                        <th>תאריך הזמנה</th>
                                                        <th>תאריך ביצוע</th>
                                                        <th>סטטוס</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {order.tasks.map((task, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{task.linkingSite?.hostname || '-'}</td>
                                                            <td>{task.anchor || '-'}</td>
                                                            <td>{task.anchorLink || '-'}</td>
                                                            <td>{isoToFormattedDate(task.orderTime) || '-'}</td>
                                                            <td>{isoToFormattedDate(task.scheduledTime) || '-'}</td>
                                                            <td>{getStatusInHebrew(task.status)}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    )}
                                </>
                            ))}
                            </tbody>
                        </Table>
                    )}
                    <PaginationBar />
                </Col>
            </Row>
        </>
    );
};

export default AdminOrders;
