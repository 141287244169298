import React, {useEffect, useMemo, useState} from 'react';
import {Container, Row} from 'react-bootstrap';
import {Link, SiteType} from "../models/Link";
import {Typography} from "@mui/material";
import LinkItem from "./LinkItem";
import BottomBorder from "./BottomBorder";
import {useApiService} from "../context/ApiServiceContext";
import SkeletonLoader from "./SkeletonLoader";
import {styled} from "@mui/system";
import Slider from "react-slick";
import {ArrowLeft, ArrowRight} from "../views/arrow";

const StyledSlider = styled(Slider)(({theme}) => ({
    '& .slick-list': {
        '@media screen and (min-width: 768px)': {
            padding: '10px 0 20px 8px',
            textAlign: 'right',
        },
    },
    '& .slick-track': {
        '@media screen and (min-width: 768px)': {
            marginLeft: '-10px',
            marginRight: '-10px',
        },
    },
    '& .slick-next, & .slick-prev': {
        width: 'auto'
    },
}));

const StyledContainerFun = (lineColor: string) => styled('div')(({theme}) => ({
    background: `radial-gradient(circle at center bottom, ${lineColor}75 0%, #ffffffd6 84%)`,
    padding: "50px 60px 40px",
    borderRadius: "40px",
    border: `4px solid ${lineColor}2b`,
    boxShadow: "0px 13px 43px 0px rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down('sm')]: {
        padding: '20px 20px 10px', // Smaller font size for small screens
    },
    [theme.breakpoints.between('sm', 'md')]: {
        padding: '30px 30px 20px', // Medium font size for medium screens
    },
}));

const RowWithAdaptivePadding = styled(Row)(({theme}) => ({
    padding: "0 60px",
    [theme.breakpoints.down('sm')]: {
        padding: '10px', // Smaller font size for small screens
    },
    [theme.breakpoints.between('sm', 'md')]: {
        padding: '30px', // Medium font size for medium screens
    },
}))

interface RecommendedLinksLinkProps {
    className: string;
    lineColor: string;
    linkBG: string;
    siteType: SiteType;
    allowProvideContent?: boolean;
}

const RecommendedLinks: React.FC<RecommendedLinksLinkProps> = ({
                                                                   className,
                                                                   lineColor,
                                                                   linkBG,
                                                                   siteType,
                                                                   allowProvideContent = false,
                                                               }) => {
    const apiService = useApiService();

    const [links, setLinks] = useState<Link[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const StyledContainer = StyledContainerFun(lineColor)

    useEffect(() => {
        setIsLoading(true)
        apiService.api.get(`/link/list?siteType=${siteType}&recommended=true&per_page=50`)
            .then(response => {
                setLinks(response.data);
                setIsLoading(false)
            });
    }, []);

    type ArrowProps = {
        className?: string;
        style?: React.CSSProperties;
        onClick?: () => void;
        currentSlide?: number;
        slideCount?: number;
        src: string;
    };

    const Arrow = (props: ArrowProps) => {
        const {className, style, onClick, src} = props;
        return (
            <img src={src}
                 className={className}
                 style={{...style}}
                 onClick={onClick}
            />
        );
    }

    const settings = {
        dots: false,
        infinite: links.length > 3,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        slidesToShow: 3, // show up to 4 items
        slidesToScroll: 3, // scroll 4 items at a time
        arrows: links.length > 3,
        swipe: true,
        rtl: true,
        nextArrow: <ArrowRight/>,
        prevArrow: <ArrowLeft/>,
        responsive: [
            {
                breakpoint: 1400, // md breakpoint
                settings: {
                    infinite: links.length > 2,
                    slidesToShow: 2,
                    slidesToScroll: 2
                },
            },
            {
                breakpoint: 1024, // md breakpoint
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                },
            },
            {
                breakpoint: 768, // xs breakpoint
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // arrows: false,
                },
            },
            // You can add more breakpoints as needed
        ],
    };

    const sliderItems = useMemo(() => {
        return links.map((link, index) => (
            <div key={index} dir='rtl'>
                <LinkItem
                    {...link}
                    backgroundImage={linkBG}
                    allowProvideContent={allowProvideContent}
                />
            </div>
        ))
    }, [links]);

    return (
        <Container className={`${className} pb-5`} hidden={!isLoading && links.length == 0}>
            <StyledContainer>
                <Row>
                    <Typography variant="h3">המלצות המערכת</Typography>
                    <BottomBorder color={lineColor} width={69}></BottomBorder>
                </Row>
                <RowWithAdaptivePadding style={{marginTop: "22px"}}>
                    {isLoading ? (
                        <SkeletonLoader amount={2} marginTop={false}/>
                    ) : (
                        <StyledSlider {...settings}>
                            {sliderItems}
                        </StyledSlider>
                    )}
                </RowWithAdaptivePadding>
            </StyledContainer>
        </Container>
    );
};

export default RecommendedLinks;