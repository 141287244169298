import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import Cookies from 'js-cookie';
import {AppDispatch} from "../utils/store";
import {clearUserData, logout} from "../utils/userSlice";
import config from "../config/config";
import AnalyticsService from "./AnalyticsService";

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
    _retry?: boolean;
}

const BASE_URL = config.apiUrl

export class ApiService {
    private static instance: ApiService;

    api: AxiosInstance
    private isRefreshing: boolean = false;
    private failedQueue: Array<{
        resolve: (value?: any) => void,
        reject: (value?: any) => void
    }> = [];

    private constructor(private dispatch: AppDispatch) {
        this.api = axios.create({
            baseURL: BASE_URL,
        });

        this.initInterceptors()
    }

    public static getInstance(dispatch: AppDispatch): ApiService {
        if (!ApiService.instance) {
            ApiService.instance = new ApiService(dispatch);
        }

        return ApiService.instance;
    }

    processQueue = (error: any, token: any = null) => {
        this.failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        this.failedQueue = [];
    };

    initInterceptors() {

        this.api.interceptors.request.use(config => {
            const token = Cookies.get('accessToken');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        }, error => {
            return Promise.reject(error);
        });
        this.api.interceptors.response.use((response: AxiosResponse) => {
            return response;
        }, (error: any) => {
            const originalRequest: CustomAxiosRequestConfig = error.config;

            if (error.response.status === 401 && !originalRequest._retry) {
                if (this.isRefreshing) {
                    return new Promise((resolve, reject) => {
                        this.failedQueue.push({resolve, reject})
                    }).then(token => {
                        if (token) {
                            originalRequest.headers!!['Authorization'] = 'Bearer ' + token;
                        } else if (token == null) {
                            delete originalRequest.headers!!['Authorization']
                        }
                        return this.api(originalRequest);
                    }).catch(err => {
                        return Promise.reject(err);
                    });
                }

                originalRequest._retry = true;
                this.isRefreshing = true;

                const refreshToken: string | undefined = Cookies.get('refreshToken');
                if (refreshToken) {
                    return new Promise((resolve, reject) => {
                        axios.post(`${BASE_URL}/oauth/refresh`, {}, {
                            headers: {
                                'Authorization': refreshToken ? `Bearer ${refreshToken}` : undefined
                            }
                        })
                            .then(({data}) => {
                                Cookies.set('accessToken', data.accessToken, {
                                    path: '/',
                                    expires: 7 // Expires in 7 days
                                });
                                Cookies.set('userId', data.userId, {
                                    path: '/',
                                    expires: 7 // Expires in 7 days
                                });

                                const analyticsService = AnalyticsService.getInstance();
                                analyticsService.setUserId(data.userId)

                                if (data.accessToken) {
                                    originalRequest.headers!!['Authorization'] = 'Bearer ' + data.accessToken;
                                }
                                this.processQueue(null, data.accessToken);
                                resolve(this.api(originalRequest));
                            })
                            .catch((err) => {
                                console.error("refreshToken failed", err);
                                clearUserData(this.dispatch)
                                this.processQueue(null, null);
                                resolve(this.api(originalRequest));
                            })
                            .finally(() => {
                                this.isRefreshing = false;
                            });
                    });
                } else {
                    console.error("no refreshToken found");
                    clearUserData(this.dispatch)

                    return new Promise((resolve, reject) => {
                        this.failedQueue.push({resolve, reject})
                        this.processQueue(null, null);
                    }).then(token => {
                        if (token) {
                            originalRequest.headers!!['Authorization'] = 'Bearer ' + token;
                        } else if (token == null) {
                            delete originalRequest.headers!!['Authorization']
                        }
                        return this.api(originalRequest);
                    }).catch(err => {
                        return Promise.reject(err);
                    });
                }
            }

            return Promise.reject(error);
        });
    }
}
