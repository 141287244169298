export enum AnalyticsEventName {
    LinkInitiated = 'LinkInitiated',
    Search = 'Search',
    // Add more event names as needed
}

class AnalyticsService {
    private static instance: AnalyticsService;
    private userId: string | undefined;

    private constructor() {

    }

    public static getInstance(): AnalyticsService {
        if (!AnalyticsService.instance) {
            AnalyticsService.instance = new AnalyticsService();
        }
        return AnalyticsService.instance;
    }

    public sendCustomEvent(eventName: AnalyticsEventName, eventParams: Record<string, any> = {}): void {
        try {
            // @ts-ignore
            flashy('CustomEvent', {
                "event_name": eventName,
                context: {
                    userId: this.userId,
                    ...eventParams
                }
            });
        } catch (e) {
        }
    }

    public sendViewContentEvent(id: string, eventParams: Record<string, any> = {}): void {

        try {
            // @ts-ignore
            flashy('ViewContent', {
                "content_ids": [id],
                context: {
                    userId: this.userId,
                    ...eventParams
                }
            });
        } catch (e) {
        }
    }

    public createOrUpdateUser(eventParams: Record<string, any>) {
        try {
            // @ts-ignore
            flashy.contacts.createOrUpdate(eventParams);
        } catch (e) {
        }
    }

    public updateCart(ids: string[], value: number, eventParams: Record<string, any>): void {
        try {
            // @ts-ignore
            flashy('UpdateCart', {
                "content_ids": ids,
                value,
                context: {
                    userId: this.userId,
                    ...eventParams
                }
            });

        } catch (e) {
        }
    }

    sendPageViewEvent(eventParams: Record<string, any>) {
        try {
            // @ts-ignore
            flashy('PageView', {
                context: {
                    userId: this.userId,
                    ...eventParams
                }
            });
        } catch (e) {
        }
    }

    setUserId(userId: string | undefined) {
        this.userId = userId;
    }

    identify(email: string) {
        try {
            // @ts-ignore
            flashy('setCustomer', {
                email
            });
        } catch (e) {
        }
    }

}

export default AnalyticsService;
