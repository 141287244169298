// ApiServiceContext.tsx
import React, {createContext, ReactNode, useContext} from 'react';
import AnalyticsService from "../services/AnalyticsService";
import {useCookies} from "react-cookie";

// Create the context
const AnalyticsServiceContext = createContext<AnalyticsService | undefined>(undefined);

// Provider component
export const AnalyticsServiceProvider = ({ children }: { children: ReactNode }) => {
    const analyticsService = AnalyticsService.getInstance();
    const [cookies] = useCookies(['userId']);
    analyticsService.setUserId(cookies.userId)

    return (
        <AnalyticsServiceContext.Provider value={analyticsService}>
            {children}
        </AnalyticsServiceContext.Provider>
    );
};

// Hook to use the context
export const useAnalyticsService = () => {
    const context = useContext(AnalyticsServiceContext);
    if (!context) {
        throw new Error('useApiService must be used within a ApiServiceProvider');
    }
    return context;
};
