import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import * as yup from 'yup';
import {ApiService} from "../services/api";
import {useApiService} from "../context/ApiServiceContext";
import linkngoMain from "../assets/images/linkngo-main.png";
import byRego from "../assets/images/rego-logo.png";
import {makeStyles} from "@material-ui/core";
import {Theme, Typography} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
    logo: {
        [theme.breakpoints.down('sm')]: {
            height: '60px', // Smaller screen size (small)
        },
        [theme.breakpoints.between('sm', 'md')]: {
            height: '80px', // Medium screen size (medium)
        },
        [theme.breakpoints.up('lg')]: {
            height: 'auto', // Larger screen size (large)
        },
    }
}));

const ResetPassword: React.FC = () => {
    const classes = useStyles()
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const hash = searchParams.get('hash');
    const apiService = useApiService();

    useEffect(() => {
        if (hash === undefined) {
            navigate("/")
        }
    }, [hash])

    const passwordSchema = yup.object().shape({
        password: yup.string()
            .required('שדה חובה')
            .min(6, 'הסיסמא צריכה להכיל לפחות 6 תווים')
            .matches(/[a-z]/, 'הסיסמא צריכה להכיל לפחות אות קטנה אחת')
            .matches(/[A-Z]/, 'הסיסמא צריכה להכיל לפחות אות גדולה אחת')
            .matches(/[!@#$%^&*(),.?":{}|<>]/, 'הסיסמא צריכה להכיל לפחות תו מיוחד אחד'),
        confirmPassword: yup.string()
            .oneOf([yup.ref('password'), ''], 'הסיסמאות חייבות להיות תואמות')
    });

    const validatePasswords = async () => {
        try {
            await passwordSchema.validate({password, confirmPassword});
            return true;
        } catch (error: any) {
            toast.error(error.message);
            return false;
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!(await validatePasswords())) {
            return;
        }

        try {
            // Replace with your API endpoint
            await apiService.api.post('/oauth/reset-password', {token: hash, newPassword: password});
            toast.success('הסיסמא אופסה בהצלחה');
            navigate('/'); // Redirect to login page or dashboard
        } catch (error) {
            toast.error('בעיה באיפוס הסיסמא, נסה שוב מאוחר יותר');
        }
    };

    return (
        <Container className="d-flex flex-column align-items-center justify-content-center vh-100 gap-3">
            <Row className="justify-content-center mb-4" style={{width: "300px"}}>
                <Col sm={12} className="mb-2 text-center">
                    <img src={linkngoMain} style={{maxWidth: "90%"}}/>
                </Col>
                <Col sm={"auto"} className="pe-md-0 text-center" dir="ltr">
                    <span style={{fontSize: "20px", fontWeight: 300, marginRight: "10px"}}>By</span>
                    <img src={byRego}
                         className={classes.logo}
                         alt="Subtitle imagery"
                         style={{paddingLeft: 0, width: "100px"}}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Typography variant="h3">שכחתי סיסמא</Typography>
                </Col>
            </Row>
            <div className="w-100" style={{maxWidth: '400px'}}>
                <Form onSubmit={handleSubmit} className='d-flex gap-3 flex-column'>
                    <Form.Group controlId="formPassword">
                        <Form.Label>סיסמא חדשה</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="הכנס את הסיסמא"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formConfirmPassword">
                        <Form.Label>אימות סיסמא חדשה</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="הכנס את הסיסמא החדשה בשנית"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Button variant="primary" className="simple-button blue" style={{width: "100%"}} type="submit">
                        אפס את הסיסמא
                    </Button>
                </Form>
            </div>
        </Container>
    );
};

export default ResetPassword;
