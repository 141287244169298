import React from "react";
import regoArrow from "../assets/images/rego-arrow.png";

const Spinner: React.FC<{
    size?: number;
    hidden?: boolean;
    className?: string;
}> = ({size = 63, hidden, className}) => (
    <img src={regoArrow} className={`rotating object-fit-contain ${className ? className : ''}`} width={size} height={size}
         hidden={hidden}/>
);

export default Spinner