export const prettifyNumber = (num: number): string => {
    if (num >= 1e6) {
        return `${(num / 1e6).toFixed(1)}m`;
    } else if (num >= 1e3) {
        return `${(num / 1e3).toFixed(1)}k`;
    } else {
        return num.toString();
    }
}

export function isoToFormattedDate(isoDate: string) {
    if (!isoDate) {
        return undefined
    }

    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 because months are 0-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}


export function trimToHostname(url?: string, def = '-') {
    if (!url) return def;

    let hostname;

    // Remove protocol (http://, https://, etc.)
    if (url.indexOf("://") > -1) {
        hostname = url.split('/')[2];
    } else {
        hostname = url.split('/')[0];
    }

    // Remove 'www.' if present
    hostname = hostname.replace(/^www\./, '');

    // Remove port number and URL path
    hostname = hostname.split(':')[0].split('?')[0];

    return hostname.trim();
}

export function getRandomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const debounce = (func: (...args: any[]) => any, delay: number): { debouncedFunc: () => void, timerId: () => NodeJS.Timeout | undefined } => {
    let timer: NodeJS.Timeout | undefined;
    const debouncedFunc = (...args: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(...args);
        }, delay);
    };
    const timerId = () => timer;
    return {debouncedFunc, timerId};
};

export const prettifyCurrency = (number:number) => {
    return  new Intl.NumberFormat('he-IL').format(number);
};
