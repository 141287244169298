import React from 'react';
import VideoModal from "./VideoModal";

interface HelperVideoModalProps {
    show: boolean;
    handleClose: () => void;
}

const HelperVideoModal: React.FC<HelperVideoModalProps> = ({show, handleClose}) => {
    return (
        <VideoModal show={show} handleClose={handleClose}
                    url="https://rego-document-files.s3.il-central-1.amazonaws.com/home-video.mp4?"/>
    );
};

export default HelperVideoModal;