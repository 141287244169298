// store.ts
import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './cartSlice';
import userReducer from './userSlice';
import modalReducer from './modalSlice';
import ordersReducer from './ordersSlice';

const store = configureStore({
    reducer: {
        cart: cartReducer,
        user: userReducer,
        modals: modalReducer,
        orders: ordersReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
