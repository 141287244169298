// ApiServiceContext.tsx
import React, {createContext, ReactNode, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {ApiService} from "../services/api";

// Create the context
const ApiServiceContext = createContext<ApiService | undefined>(undefined);

// Provider component
export const ApiServiceProvider = ({ children }: { children: ReactNode }) => {
    const dispatch = useDispatch();
    const apiService = ApiService.getInstance(dispatch);

    return (
        <ApiServiceContext.Provider value={apiService}>
            {children}
        </ApiServiceContext.Provider>
    );
};

// Hook to use the context
export const useApiService = () => {
    const context = useContext(ApiServiceContext);
    if (!context) {
        throw new Error('useApiService must be used within a ApiServiceProvider');
    }
    return context;
};
