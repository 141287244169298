import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    selectCart,
    removeProductFromCart,
    clearCart, finishPurchase
} from '../utils/cartSlice';
import {
    List,
    ListItem,
    ListItemText,
    Typography,
    IconButton,
    Divider,
    Button,
} from '@mui/material';
import {styled} from '@mui/system';
import {makeStyles} from '@material-ui/core';
import {Close as CloseIcon, Close as RemoveIcon} from '@material-ui/icons';
import {AppDispatch} from "../utils/store";
import CartItem from "../models/CartItem";
import {Modals, showModal} from "../utils/modalSlice";
import {Form} from "react-bootstrap";

const useStyles = makeStyles({
    container: {
        width: "100%",
        background: '#FFF',
        padding: '20px',
        textAlign: 'right'
    },
    itemImage: {
        margin: '0 10px',
        width: '60px',
        height: '60px',
        objectFit: 'contain'
    },
    itemPrice: {
        color: '#000',
    },
    totalSection: {
        padding: '10px 0',
        textAlign: 'center'
    },
    checkoutButton: {
        width: '100%',
        marginTop: '10px',
    },
    emptyCartText: {
        textAlign: 'center',
        color: '#FF0000',
        textDecoration: 'none',
        cursor: 'pointer',
        margin: '5px 0 0',
        fontSize: '14px'
    },
});

const StyledCloseButton = styled(IconButton)({
    position: 'absolute',
    top: '0px',
    left: '0px',
});

const StyledListItem = styled(ListItem)({
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '10px 0',
});

interface Props {
    handleClose: () => void;
}

const ShoppingCart: React.FC<Props> = ({handleClose}) => {
    const classes = useStyles();
    const dispatch: AppDispatch = useDispatch();
    const [couponCode, setCouponCode] = useState<string | null>(null);
    const cart = useSelector(selectCart);

    const handleFinishPurchase = async () => {
        handleClose();
        dispatch(finishPurchase(couponCode))
    };

    function getSubtitle(item: CartItem) {
        switch (item.itemType) {
            case "link":
                return "קישור"
            case "package":
                return "חבילה"
        }
        return "";
    }

    function handleShowVideoModal() {
        dispatch(showModal(Modals.HELPER_VIDEO))
    }

    return (
        <div className={classes.container}>
            <StyledCloseButton onClick={handleClose}>
                <CloseIcon fontSize="small"/>
            </StyledCloseButton>
            {cart.length === 0 ? (
                <Typography variant="subtitle1" className="p-3 text-center">
                    אין פריטים בעגלה
                </Typography>
            ) : (
                <>
                    <List className="mt-1">
                        {cart.map((item, index) => (
                            <React.Fragment key={item.itemId}>
                                <StyledListItem>
                                    <div className="flex-fill overflow-hidden">
                                        <ListItemText className="mb-0"
                                            primary={<Typography
                                                className="fw-bold text-truncate">{item.displayName}</Typography>}
                                            secondary={<Typography variant='subtitle2'
                                                                   className={`${classes.itemPrice}`}>{getSubtitle(item)}</Typography>}
                                        />
                                        {typeof item.wordCount === 'number' && item.wordCount >= 500 && (
                                            <Typography
                                                variant="subtitle2" className={"d-flex align-items-center fw-semibold"}>
                                                <span className="text-gray me-1">{`כמות מילים:`}</span>
                                                {' '} {/* This adds a space between the two spans */}
                                                <span className="text-gray">{item.wordCount}</span>
                                            </Typography>
                                        )}
                                        <Typography variant="body1" className={"d-flex align-items-center fw-semibold"}>
                                            <span className="text-gray me-1 text-small">{`X${item.quantity}`}</span>
                                            {' '} {/* This adds a space between the two spans */}
                                            <span className="fs-5">{`${item.price}₪`}</span>
                                        </Typography>
                                    </div>
                                    <img src={item.thumbnailUrl} alt={item.displayName} className={classes.itemImage}/>
                                    <IconButton onClick={() => dispatch(removeProductFromCart(item))}>
                                        <RemoveIcon fontSize={"small"}/>
                                    </IconButton>
                                </StyledListItem>
                            </React.Fragment>
                        ))}
                    </List>
                    <Divider/>
                    <div className={classes.totalSection}>
                        <Typography variant="subtitle1">
                            <span className="text-gray">סכום ביניים:</span><span
                            className="fw-semibold fs-5"> {cart.reduce((sum, item) => sum + item.price * item.quantity, 0)}₪</span>
                        </Typography>
                        <Typography variant="subtitle2">
                            <span className="text-gray">סך הפריטים בעגלה:</span><span
                            className="fw-semibold"> {cart.length > 1 ? `${cart.length} פריטים` : "פריט אחד"}</span>
                        </Typography>
                    </div>
                    <Divider/>

                    <Form.Group className="mb-2">
                        <Form.Control type="text" placeholder="יש לי קוד קופון"
                                      onChange={(e) => setCouponCode(e.target.value)}/>
                    </Form.Group>
                    <Button variant="contained" className={`${classes.checkoutButton} simple-button blue fw-bold`}
                            onClick={handleFinishPurchase}>
                        לתשלום
                    </Button>
                    <Typography className={classes.emptyCartText} onClick={() => dispatch(clearCart())}>
                        רוקן עגלה
                    </Typography>

                    <div>

                        <Typography variant={"subtitle1"} className="fw-bold mt-2" onClick={() => dispatch(clearCart())}>
                            שימו לב:
                        </Typography>
                        <Typography variant={"body1"} onClick={() => dispatch(clearCart())}>
                            משלמים  - ואז וממלאים את הפרטים ב"הזמנות שלי" (האנקור, אתר מקשר, URL לאנקור)
                        </Typography>
                        <Typography sx={{
                            color: "#0d6efd",
                            textDecoration: "underline",
                            cursor: "pointer"
                        }} onClick={handleShowVideoModal}>לינק לסרטון הדרכה</Typography>
                    </div>
                </>
            )}
        </div>
    );
};

export default ShoppingCart;
