import {SiteType} from "./Link";

export interface OrderTaskBody {
    linkingSiteId?: string;
    anchor?: string;
    anchorLink?: string;
    scheduledTime: string;
    secondAnchor?: string;
    secondAnchorLink?: string;
    preciseAnchor?: boolean;
}

export interface OrderCategory {
    id: string;
    name: string;
}

export interface OrderTask {
    id: string;
    linkingSite?: {
        id: string;
        hostname: string;
        type: SiteType;
    };
    status: OrderTaskStatus;
    anchor?: string;
    anchorLink?: string;
    secondAnchor?: string;
    secondAnchorLink?: string;
    orderTime: string;
    scheduledTime: string;
    createdAt: string;
    updatedAt: string;
    siteType: string; // to identify package site type
    articleUrl?: string;
    category?: OrderCategory;
    subCategories?: OrderCategory[];
    usersContent?: boolean;
    documentUrl?: string;
    preciseAnchor?: boolean;
}

interface Order {
    id: string;
    itemType: string;
    tasks: OrderTask[];
    status: string;
    totalPrice: number;
    createdAt: string;
    updatedAt: string;
    user?: {
        id?: string;
        email?: string;
    };
    allowSecondAnchor: boolean;
}


export interface EditableOrderTask extends OrderTask{
    linkingSiteId: string | undefined;
    linkingSiteHostname: string | undefined;
}

export interface EditableOrder extends Order{
    tasks: EditableOrderTask[];
}

export enum OrderTaskStatus {
    PENDING = "pending",
    PENDING_DATA = "pending_data",
    SENT = "sent",
    IN_PROGRESS = "in_progress",
    COMPLETED = "completed",
}

export default Order