import * as React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Box, Card, CardContent, Typography, Avatar, ListItemText, css} from '@mui/material';
import {styled} from '@mui/material/styles';
import userImage1 from "../../assets/images/testimonial/idan.png";
import {useEffect, useRef, useState} from "react";
import Testimonial from "../../models/Testimonial";
import {useApiService} from "../../context/ApiServiceContext";
import SkeletonLoader from "../SkeletonLoader";

const BubbleCard = styled(Card)(({theme}) => ({
    position: 'relative',
    background: '#ffffff',
    color: '#000000', // Changed to black for contrast
    fontSize: '20px',
    lineHeight: '1.5em', // Adjusted for better text display
    boxShadow: '0px 13px 11px -5px rgba(163, 163, 163, 0.47)',
    textAlign: 'right',
    width: 'auto',
    height: 'auto', // Changed to auto to contain variable text lengths
    borderRadius: '10px',
    padding: '10px', // Added padding for internal spacing
    overflow: 'unset',
    marginBottom: '21px',
    '&:after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        width: 0,
        zIndex: 1,
        borderStyle: 'solid',
        borderWidth: '0 20px 20px 0',
        borderColor: 'transparent #ffffff transparent transparent',
        bottom: '-20px',
        left: '78%',
        marginLeft: '-10px',
    },
}));

const StyledAvatar = styled(Avatar)(({theme}) => ({
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
}));

const CustomDot = styled('div')(({theme}) => ({
    width: '100%',
    height: '8px',
    backgroundColor: '#e4e4e4', // Change these colors as needed
    margin: '0 5px',
    cursor: 'pointer',
    borderRadius: '3px',
    '.slick-active &': {
        backgroundColor: '#0d47a1', // Modify this color as needed for active state
        // Add other styles here if needed
    },
}));

const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    rtl: true,
    customPaging: () => <CustomDot/>,
    arrows: false,
    dotsClass: 'slick-dots',
    responsive: [
        {
            breakpoint: 1400, // md breakpoint
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            },
        },
        {
            breakpoint: 1024, // md breakpoint
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            },
        },
        {
            breakpoint: 768, // xs breakpoint
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        // You can add more breakpoints as needed
    ],
};

const StyledSlider = styled(Slider)(({theme}) => ({
    '& .slick-dots li': {
        width: '60px',
    },
    '& .slick-dots': {
        position: 'relative',
    }
}));

export const CustomerStoriesSlider: React.FC = ({}) => {
    const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const apiService = useApiService();

    const slideRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [tallestSlide, setTallestSlide] = useState<number | undefined>(undefined);

    useEffect(() => {
        setIsLoading(true)
        apiService.api.get(`/testimonial/list?per_page=50`)
            .then(response => {
                setTestimonials(response.data);
                setIsLoading(false)
            });
    }, []);

    useEffect(() => {
        if (testimonials?.length > 0 && testimonials?.length == slideRefs.current?.length) {
            setTimeout(() => { // hack for mobile height
                let maxHeight = 0;
                slideRefs.current.forEach((slide, index) => {
                    console.log(index, slide?.offsetHeight, maxHeight)
                    if (slide && slide.offsetHeight > maxHeight) {
                        maxHeight = slide.offsetHeight;
                    }
                });
                console.log(maxHeight)
                setTallestSlide(maxHeight);
            }, 100)
        }
    }, [testimonials, slideRefs]);

    return (
        <Box sx={{direction: 'rtl'}}> {/* RTL support */}
            {isLoading ? (
                    <SkeletonLoader amount={1} marginTop={false}/>
                ) :
                <StyledSlider {...settings}>
                    {testimonials.map((testimonial, index) => (
                        <div key={index} dir='rtl'>
                            <BubbleCard ref={el => slideRefs.current[index] = el}
                                        style={{height: tallestSlide}}>
                                <CardContent>
                                    <Typography variant="body2" className="fw-bold">
                                        {testimonial.title}
                                    </Typography>
                                    <Typography variant="body1">
                                        {testimonial.description}
                                    </Typography>
                                </CardContent>
                            </BubbleCard>
                            <div className="d-flex align-items-center">
                                {/*<StyledAvatar src={testimonial.thumbnailUrl} alt={testimonial.name}/>*/}
                                <ListItemText
                                    primary={<Typography className="fw-bold">{testimonial.name}</Typography>}
                                    secondary={<Typography variant='subtitle2'>{testimonial.company}</Typography>}
                                />
                            </div>
                        </div>
                    ))}
                </StyledSlider>
            }
        </Box>
    );
};

export default CustomerStoriesSlider;