import React, {useState, useEffect, useCallback} from 'react';
import Papa from 'papaparse';
import {Button, Col, Row, Table} from 'react-bootstrap';
import Link, {SiteType} from "../../models/Link";
import {LinkRequestBody} from "./AdminLinks";
import {makeStyles} from "@material-ui/core";
import {useApiService} from "../../context/ApiServiceContext";
import {trimToHostname} from "../../utils/utils";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import Category from "../../models/Category";
import category from "../../models/Category";

export interface LinkCSVRow {
    website: string;
    category: string;
    subCategories: string;
    price: string;
    siteType: string;
    'title': string;
    'description': string;
    'bullets.0': string;
    'bullets.1': string;
    'bullets.2': string;
    'traffic': string;
    'rd': string;
    'dr': string;
    recommended: string;
    exampleSite: string;
    exampleArticleUrl: string;
}

const useStyles = makeStyles({
    changedCell: {
        backgroundColor: 'lightyellow!important',
    },
    validationError: {
        backgroundColor: 'lightpink!important',
    },
    newLinkRow: {
        backgroundColor: 'lightgreen!important',
    },
    normalCell: {
        backgroundColor: 'inherit',
    },
});

const AdminLinksImport: React.FC = () => {
    const classes = useStyles();
    const apiService = useApiService();
    const navigate = useNavigate();
    const [links, setLinks] = useState<Link[]>([]);
    const [existingWebsites, setExistingWebsites] = useState<string[]>([]);
    const [csvLinks, setCsvLinks] = useState<LinkRequestBody[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [validationError, setValidationError] = useState<boolean>(false);

    useEffect(() => {
        apiService.api.get('/category/list?per_page=100')
            .then(res => setCategories(res.data))
    }, [])

    const getCategoryIds = useCallback((categories: Category[]) => {
        return categories.map(x => x.id)
    }, [])

    const fetchLinksFromDatabase = async (parsedLinks: LinkRequestBody[]) => {
        const websites = parsedLinks.map(x => trimToHostname(x.website))
        const response = await apiService.api.get(`/link/list?per_page=1000&websites=${encodeURIComponent(websites.join(","))}`)
        const linksWithHostname: Link[] = response.data.map((link: Link) => ({
            ...link,
            website: trimToHostname(link.website)
        }))

        const eWebsites = new Array(...new Set(linksWithHostname.map(x => x.website)))
        setExistingWebsites(eWebsites)
        setLinks(linksWithHostname)

        return eWebsites
    };

    const undefinedIfEmpty = (value: any) => {
        if (typeof value === 'string') {
            value = value.trim();
        } else if (Array.isArray(value)) {
            if (value.length === 0) {
                return undefined;
            }
        }
        return value === "" || value === 0 || value === null || value === undefined ? undefined : value;
    };

    const parseNumber = (numStr: string) => {
        return Number(numStr?.trim()?.replace(',', '')) ?? 0
    }

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, categories: Category[]) => {
        setValidationError(false)

        const file = event.target.files?.[0];
        if (file) {
            Papa.parse<LinkCSVRow>(file, {
                header: true,
                complete: async (result) => {
                    const parsedLinks: LinkRequestBody[] = result.data.map(row => ({
                        website: trimToHostname(row.website),
                        categoryId: undefinedIfEmpty(categories.find(c => c.name === row.category)?.id ?? row.category),
                        subCategoryIds: row.subCategories.split(',').map(x => x.trim())
                                .map(category => undefinedIfEmpty(categories.find(c => c.name === category)?.id))
                                .filter(x => x !== undefined)
                            ?? row.subCategories,
                        siteType: undefinedIfEmpty(row.siteType),
                        price: undefinedIfEmpty(parseNumber(row?.price)),
                        info: {
                            title: undefinedIfEmpty(row['title']),
                            description: undefinedIfEmpty(row['description']),
                            bullets: undefinedIfEmpty([row['bullets.0'], row['bullets.1'], row['bullets.2']].filter(Boolean) as string[])
                        },
                        metadata: {
                            traffic: undefinedIfEmpty(parseNumber(row['traffic'])),
                            rd: undefinedIfEmpty(parseNumber(row['rd'])),
                            dr: undefinedIfEmpty(parseNumber(row['dr'])),
                        },
                        recommended: undefinedIfEmpty(row['recommended'] === "1"),
                        exampleSite: undefinedIfEmpty(row['exampleSite'] === "1"),
                        exampleArticleUrl: undefinedIfEmpty(row['exampleArticleUrl']),
                    }));

                    const websitesFromDB = await fetchLinksFromDatabase(parsedLinks)

                    const categoryIds = getCategoryIds(categories)
                    const unknownCategory = parsedLinks.find(x => x.categoryId && !categoryIds.includes(x.categoryId))
                    const unknownSiteType = parsedLinks.find(x => x.siteType && !([SiteType.BOOST, SiteType.SUPER_REGO, SiteType.PREMIUM] as string[]).includes(x.siteType))
                    if (unknownCategory || unknownSiteType) {
                        setValidationError(true)
                    }

                    const newLinks = parsedLinks.filter(x => !websitesFromDB.includes(trimToHostname(x.website)))
                    const linksWithoutSiteType = newLinks.find(x => !x.siteType)
                    if (linksWithoutSiteType) {
                        setValidationError(true)
                    }

                    setCsvLinks(parsedLinks);
                },
            });
        }
    };

    const updateLinks = async (existingWebsites: string[], csvLinks: LinkRequestBody[]) => {
        const newLinks = csvLinks.filter((link) => !existingWebsites.includes(link.website));
        let updatedLinks = csvLinks.filter((link) => existingWebsites.includes(link.website));

        updatedLinks = updatedLinks.map(link => ({
            id: findLinkByWebsite(link.website)!!.id,
            ...link
        }))

        try {
            const response = await apiService.api.post(`/link/bulk`, {
                update: updatedLinks,
                insert: newLinks
            })

            toast.success(`הוכנסו בהצלחה ${response.data['inserted']}, עודכנו בהצלחה ${response.data['updated']}`)
            navigate("/admin/links")
        } catch (e) {
            toast.error("שגיאה בהכנסת הנתונים")
        }
    };

    const getNestedValue = (obj: any, path: string) => {
        return path.split('.').reduce((current, pathPart) => current ? current[pathPart] : undefined, obj);
    };

    // Function to check if a field has changed using a path
    const getClass = (csvLink: LinkRequestBody, dbLink: Link | undefined = undefined, path: string, bodyPath: string | undefined = undefined) => {
        if (bodyPath == 'categoryId') {
            const unknownCategory = csvLink.categoryId && !getCategoryIds(categories).includes(csvLink.categoryId)
            if (unknownCategory) {
                return classes.validationError
            }
        }
        if (path == 'siteType') {
            const unknownSiteType = csvLink.siteType && !([SiteType.BOOST, SiteType.SUPER_REGO, SiteType.PREMIUM] as string[]).includes(csvLink.siteType)
            if (unknownSiteType) {
                return classes.validationError
            }
        }

        if (!existingWebsites.includes(csvLink.website)) {
            return classes.newLinkRow
        }

        const csvValue = getNestedValue(csvLink, bodyPath || path);
        const dbValue = getNestedValue(dbLink, path);
        return csvValue !== dbValue ? classes.changedCell : '';
    };

    // Function to check if a field has changed using a path
    const getClassForSubCategories = (csvLink: LinkRequestBody, dbLink: Link | undefined = undefined) => {
        if (!csvLink.subCategoryIds.every(category => getCategoryIds(categories).includes(category))) {
            return classes.validationError
        }

        if (!existingWebsites.includes(csvLink.website)) {
            return classes.newLinkRow
        }

        const csvValue = csvLink.subCategoryIds.sort().join(",");
        const dbValue = dbLink?.subCategories?.map(x=>x.id).sort().join(",");
        return csvValue !== dbValue ? classes.changedCell : '';
    };

    const findLinkByWebsite = (website: string) => {
        return links.find(link => link.website === website);
    };

    return (
        <Row className="justify-content-center py-md-5">
            <Col md={4}>
                <h1 className="mb-2">עדכון קישורים מcsv</h1>
            </Col>
            <Col md={8} className="d-flex justify-content-end align-items-center">
                <Button disabled={validationError || !csvLinks || csvLinks.length === 0} className="me-2"
                        onClick={() => updateLinks(existingWebsites, csvLinks)}>עדכן את הקישורים מהרשימה</Button>
                <input type="file" onChange={(e) => handleFileUpload(e, categories)} accept=".csv"/>
            </Col>
            <Col md={12}>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>אתר</th>
                        <th>קטגוריה</th>
                        <th>תת-קטגוריות</th>
                        <th>סוג אתר</th>
                        <th>מחיר</th>
                        <th>כותרת</th>
                        <th>תיאור</th>
                        <th>נקודה 1</th>
                        <th>נקודה 2</th>
                        <th>נקודה 3</th>
                        <th>Traffic</th>
                        <th>RD</th>
                        <th>DR</th>
                        <th>מומלץ</th>
                        <th>אתר לדוגמא</th>
                        <th>לינק לכתבה לדוגמא</th>
                    </tr>
                    </thead>
                    <tbody>
                    {csvLinks.map((link, index) => {
                        const dbLink = findLinkByWebsite(link.website);
                        return (
                            <tr key={index}>
                                <td>{link.website}</td>
                                <td className={getClass(link, dbLink, 'category.id', 'categoryId')}>{
                                    categories.find(c => link.categoryId == c.id)?.name ?? link.categoryId
                                }</td>
                                <td className={getClassForSubCategories(link, dbLink)}>{
                                    categories.filter(c => link.subCategoryIds.includes(c.id))
                                        .map(category => 'name' in category ? category.name : category).sort().join(", ")
                                    ?? link.subCategoryIds.join(", ")
                                }</td>
                                <td className={getClass(link, dbLink, 'siteType')}>{link.siteType}</td>
                                <td className={getClass(link, dbLink, 'price')}>{link.price}</td>
                                <td className={getClass(link, dbLink, 'info.title')}>{link.info.title}</td>
                                <td className={getClass(link, dbLink, 'info.description')}>{link.info.description}</td>
                                <td className={getClass(link, dbLink, 'info.bullets.0')}>{link.info.bullets?.[0]}</td>
                                <td className={getClass(link, dbLink, 'info.bullets.1')}>{link.info.bullets?.[1]}</td>
                                <td className={getClass(link, dbLink, 'info.bullets.2')}>{link.info.bullets?.[2]}</td>
                                <td className={getClass(link, dbLink, 'metadata.traffic')}>{link.metadata.traffic}</td>
                                <td className={getClass(link, dbLink, 'metadata.rd')}>{link.metadata.rd}</td>
                                <td className={getClass(link, dbLink, 'metadata.dr')}>{link.metadata.dr}</td>
                                <td className={getClass(link, dbLink, 'recommended')}>{link.recommended ? 'כן' : 'לא'}</td>
                                <td className={getClass(link, dbLink, 'exampleSite')}>{link.exampleSite ? 'כן' : 'לא'}</td>
                                <td className={getClass(link, dbLink, 'exampleArticleUrl')}>{link.exampleArticleUrl}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
};

export default AdminLinksImport;
