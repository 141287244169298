import Package from "../../../models/Package";
import {useEffect, useState} from "react";
import {useApiService} from "../../../context/ApiServiceContext";
import {toast} from "react-toastify";
import {Button, Form, Modal} from "react-bootstrap";

interface AttachPackageToUserModalProps {
    userId: string;
    show: boolean;
    onHide: () => void;
}

const AttachPackageToUserModal: React.FC<AttachPackageToUserModalProps> = ({ userId, show, onHide }) => {
    const apiService = useApiService();
    const [packages, setPackages] = useState<Package[]>([]);
    const [selectedPackage, setSelectedPackage] = useState('');

    useEffect(() => {
        if (show) {
            apiService.api.get('/package/list').then((res) => {
                setPackages(res.data);
                if (res.data.length > 0) {
                    setSelectedPackage(res.data[0].id);
                }
            });
        }
    }, [show, apiService.api]);

    const handleAttachPackage = () => {
        apiService.api.post(`/admin/package/${selectedPackage}/attach`, {
            userId,
        })
            .then(() => {
                toast.success('הענקת החבילה הושלמה בהצלחה');
                onHide(); // Close the modal on success
            })
            .catch(() => {
                toast.error('בעיה בעת שיוך החבילה');
            });
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>בחר חבילה</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Select
                    aria-label="Select package"
                    value={selectedPackage}
                    onChange={(e) => setSelectedPackage(e.target.value)}
                >
                    {packages.map((pkg) => (
                        <option key={pkg.id} value={pkg.id}>
                            {`${pkg.name} | ${pkg.siteType}`}
                        </option>
                    ))}
                </Form.Select>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    סגור
                </Button>
                <Button variant="primary" onClick={handleAttachPackage}>
                    הענקת חבילה
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AttachPackageToUserModal;