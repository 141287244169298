import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppDispatch, RootState} from "./store";
import {toast} from "react-toastify";
import User from "../models/User";
import Cookies from "js-cookie";
import {clearCartAction} from "./cartSlice";
import {hideAllModals} from "./modalSlice";
import {RegisterFormState} from "../components/modals/RegisterModal";
import {ApiService} from "../services/api";
import AnalyticsService from "../services/AnalyticsService";

interface UserState {
    isLoggedIn?: boolean;
    user: User | null;  // Assuming you might want more user details in the future
    // ... other user-related state
}

const initialUserState: UserState = {
    isLoggedIn: undefined,
    user: null
}
const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        checkLoginStatus: (state) => {
            const token = Cookies.get('accessToken');
            state.isLoggedIn = !!token; // Updates the login status based on the cookie's presence
        },
        login: (state) => {
            state.isLoggedIn = true;
        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload;

            if (action.payload && action.payload.email) {
                const analyticsService = AnalyticsService.getInstance();
                analyticsService.identify(action.payload.email)
                analyticsService.createOrUpdateUser({
                    'first_name': action.payload.name,
                    'email': action.payload.email,
                    'phone': action.payload.phone
                })
            }
        }
        // ... other user-related reducers
    }
});

export const {checkLoginStatus, setUser} = userSlice.actions;
export const selectIsLoggedIn = (state: RootState) => state.user.isLoggedIn;
export const selectUser = (state: RootState) => state.user.user;

export const login = (email: string, password: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const apiService = ApiService.getInstance(dispatch);

    try {
        const response = await apiService.api.post('/oauth/login', {
            email,
            password
        });

        if (response.data.accessToken && response.data.refreshToken) {
            Cookies.set('accessToken', response.data.accessToken, {
                path: '/',
                expires: 7 // Expires in 7 days
            });
            Cookies.set('refreshToken', response.data.refreshToken, {
                path: '/',
                expires: 7 // Expires in 7 days
            });
            Cookies.set('userId', response.data.userId, {
                path: '/',
                expires: 7 // Expires in 7 days
            });

            const analyticsService = AnalyticsService.getInstance();
            analyticsService.setUserId(response.data.userId)

            dispatch(userSlice.actions.login())
        }

        (window as any)?.dataLayer?.push({
            'event': 'login',
            email
        });

        toast.success('התחברת למערכת בהצלחה');
        return true
    } catch (error) {
        toast.error('התחברות נכשלה. אנא בדוק את פרטי הכניסה שלך.');
    }
    return false

}

export const forgotPassword = (email: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const apiService = ApiService.getInstance(dispatch);

    try {
        const response = await apiService.api.post('/oauth/forgot-password', {
            email,
        });

        toast.success('אימייל עם הוראות לאיפוס הסיסמא נשלח בהצלחה');
        return true
    } catch (error) {
        toast.error('שגיאה, נסה שוב מאוחר יותר');
    }
    return false

}

export const register = (data: RegisterFormState) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const apiService = ApiService.getInstance(dispatch);
    const analyticsService = AnalyticsService.getInstance();

    try {
        (window as any)?.dataLayer?.push({
            'event': 'register',
            'email': data.email
        });

        const registerBody = {
            ...data
        }
        delete registerBody.allowMarketing;
        await apiService.api.post('/oauth/register', registerBody);

        const response = await apiService.api.post('/oauth/login', {
            email: data.email,
            password: data.password
        });

        if (response.data.accessToken && response.data.refreshToken) {
            Cookies.set('accessToken', response.data.accessToken, {
                path: '/',
                expires: 7 // Expires in 7 days
            });
            Cookies.set('refreshToken', response.data.refreshToken, {
                path: '/',
                expires: 7 // Expires in 7 days
            });
            Cookies.set('userId', response.data.userId, {
                path: '/',
                expires: 7 // Expires in 7 days
            });
            analyticsService.setUserId(response.data.userId)

            dispatch(userSlice.actions.login())
        }

        analyticsService.createOrUpdateUser({
            'first_name': data.name,
            'email': data.email,
            'field': data.field,
            'companySize': data.companySize,
            'websites': data.websites,
            'lists': {
                25201: data.allowMarketing ?? false
            }
        })

        toast.success('נרשמת למערכת בהצלחה');
        return true
    } catch (error: any) {
        if (error.response.data.code === '1000/1') {
            toast.error('ההרשמה נכשלה. פרטי המשתמש כבר קיימים במערכת.');
        } else {
            toast.error('ההרשמה נכשלה. אנא בדוק שכל הפרטים מולאו לפי ההוראות.');
        }
    }
    return false

}

export const logout = () => async (dispatch: AppDispatch, getState: () => RootState) => {

    try {
        // const response = await api.post('/oauth/login', {
        //     email,
        //     password
        // });

        clearUserData(dispatch)

        toast.success('התנתקת מהמערכת בהצלחה');

        return true
    } catch (error) {
        toast.error('התנתקות מהמערכת נכשלה. אנא נסה שוב מאוחר יותר.');
    }
    return false

}

export const clearUserData = (dispatch: AppDispatch) => {

    dispatch(userSlice.actions.logout())
    dispatch(clearCartAction())
    dispatch(hideAllModals())

    Cookies.remove('accessToken')
    Cookies.remove('refreshToken')
    Cookies.remove('userId')

    const analyticsService = AnalyticsService.getInstance();
    analyticsService.setUserId(undefined)
}

export default userSlice.reducer;