import React, {useEffect, useState} from 'react';
import SkeletonLoader from '../../SkeletonLoader';
import {Container, Modal, Row} from "react-bootstrap";
import {AppDispatch} from "../../../utils/store";
import {useDispatch, useSelector} from "react-redux";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {styled} from "@mui/material/styles";
import {useApiService} from "../../../context/ApiServiceContext";
import DemoArticle from "../../../models/DemoArticle";
import DemoArticleItem from "./DemoArticleItem";
import {ArrowLeft, ArrowRight} from "../../../views/arrow";
import AnalyticsService from "../../../services/AnalyticsService";
import {selectUser} from "../../../utils/userSlice";

const StyledSlider = styled(Slider)(({theme}) => ({
    '& .slick-list': {
        paddingBottom: '20px',
    },
    '& .slick-next, & .slick-prev': {
        width: 'auto'
    },
}));

const DemoArticleList: React.FC = () => {
    const apiService = useApiService();
    const dispatch: AppDispatch = useDispatch();
    const [demoArticles, setDemoArticles] = useState<DemoArticle[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedDemoArticle, setSelectedDemoArticle] = useState<DemoArticle | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const user = useSelector(selectUser);

    const analyticsService = AnalyticsService.getInstance();

    useEffect(() => {
        setIsLoading(true);
        apiService.api.get('/demo-article/list?per_page=50')
            .then(response => {
                setDemoArticles(response.data);
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        if (selectedDemoArticle) {
            const preventDefaultAction = (e: Event) => e.preventDefault();

            const events = ['copy', 'cut', 'dragstart'] as const;
            events.forEach(event => {
                document.addEventListener(event, preventDefaultAction);
            });

            analyticsService.sendViewContentEvent(`article_${selectedDemoArticle.id}`, {
                userId: user?.id,
                type: 'article'
            })

            return () => {
                events.forEach(event => {
                    document.removeEventListener(event, preventDefaultAction);
                });
            };
        }
    }, [selectedDemoArticle])

    const settings = {
        dots: false,
        infinite: demoArticles.length > 4,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        slidesToShow: 4, // show up to 4 items
        slidesToScroll: 4, // scroll 4 items at a time
        arrows: demoArticles.length > 4,
        swipe: true,
        rtl: true,
        nextArrow: <ArrowRight/>,
        prevArrow: <ArrowLeft/>,
        responsive: [
            {
                breakpoint: 1400, // md breakpoint
                settings: {
                    infinite: demoArticles.length > 3,
                    slidesToShow: 3,
                    slidesToScroll: 3
                },
            },
            {
                breakpoint: 1024, // md breakpoint
                settings: {
                    infinite: demoArticles.length > 2,
                    slidesToShow: 2,
                    slidesToScroll: 2
                },
            },
            {
                breakpoint: 768, // xs breakpoint
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // arrows: false,
                },
            },
            // You can add more breakpoints as needed
        ],
    };

    return (
        <>
            {isLoading ? (
                <SkeletonLoader amount={2}/>
            ) : (
                <Container className="pb-5 px-4 px-sm-0">
                    <Row style={{marginTop: "42px"}}>
                        <StyledSlider {...settings}>
                            {demoArticles.map((article, index) => (
                                <div key={index} dir='rtl'>
                                    <DemoArticleItem {...article} onClick={() => {
                                        setSelectedDemoArticle(article);
                                        setShowModal(true)
                                    }}/>
                                </div>
                            ))}
                        </StyledSlider>
                    </Row>
                </Container>
            )}

            <Modal size={"lg"} show={showModal} onHide={() => {
                setShowModal(false);
                setSelectedDemoArticle(null)
            }} style={{
                marginTop: "90px",
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedDemoArticle?.title ?? ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    maxHeight: "80%",
                    height: "500px",
                    overflowY: "scroll",
                    userSelect: "none"
                }}>
                    <div dangerouslySetInnerHTML={{__html: selectedDemoArticle?.text ?? ''}}/>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DemoArticleList;