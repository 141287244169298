// ordersSlice.js
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppDispatch, RootState} from "./store";
import Order, {OrderTask, OrderTaskBody} from "../models/Order";
import {ApiService} from "../services/api";


interface OrdersState {
    orders: Order[];
    selectedOrder: Order | null;
}

const initialOrdersState: OrdersState = {
    orders: [],
    selectedOrder: null,
};

const ordersSlice = createSlice({
    name: 'orders',
    initialState: initialOrdersState,
    reducers: {
        setOrders: (state, action: PayloadAction<Order[]>) => {
            state.orders = action.payload;
        },
        setSelectedOrder: (state, action: PayloadAction<Order | null>) => {
            state.selectedOrder = action.payload;
        },
        updateOrderTask: (state, action: PayloadAction<[Order, OrderTask]>) => {
            const [updatedOrder, updatedTask] = action.payload;
            const orderIndex = state.orders.findIndex(order => order.id === updatedOrder.id);
            if (orderIndex !== -1) {
                const taskIndex = state.orders[orderIndex].tasks.findIndex(task => task.id === updatedTask.id);
                state.orders[orderIndex].tasks[taskIndex] = updatedTask;
            }
        },
        // Other reducers can be added here
    },
});

export const {setOrders, setSelectedOrder, updateOrderTask} = ordersSlice.actions;

export const fetchOrders = () => async (dispatch: AppDispatch) => {
    try {
        const apiService = ApiService.getInstance(dispatch);
        const response = await apiService.api.get('/order/list');
        dispatch(setOrders(response.data));
    } catch (error) {
        console.error('Error fetching orders:', error);
    }
};

export const fetchOrderById = (orderId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const apiService = ApiService.getInstance(dispatch);
    const response = await apiService.api.get(`/order/${orderId}`);
    // Optionally, you can dispatch an action to add this order to the store
    dispatch(setOrders([response.data]));
};

export const selectOrderById = (state: RootState, orderId: string) =>
    state.orders.orders.find(order => order.id === orderId);

export const selectOrders = (state: RootState) => state.orders.orders;
export const selectSelectedOrder = (state: RootState) => state.orders.selectedOrder;

export default ordersSlice.reducer;
