import React, { useState, useEffect, useRef } from 'react';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    countdownTimer: {
        display: 'flex',
        justifyContent: 'end',
    },
    timeSection: {
        textAlign: 'center',
        margin: '0 10px',
    },
    timeValue: {
        fontSize: '53px',
        color: '#251FB6',
        fontWeight: 700,
        lineHeight: 0.7
    },
    timeLabel: {
        fontSize: '23px',
        color: '#251FB6',
    },
});

interface CountdownTimerProps {
    targetDate: Date;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ targetDate }) => {
    const classes = useStyles();
    const [timeLeft, setTimeLeft] = useState({
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00'
    });

    // Using useRef to store the interval ID so it doesn't trigger a re-render
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const intervalCallback = () => {
        const now = new Date();
        const difference = targetDate.getTime() - now.getTime();

        if (difference <= 0) {
            // Time's up! Clear interval and set timeLeft to 0
            clearInterval(intervalRef.current!);
            setTimeLeft({
                days: '00',
                hours: '00',
                minutes: '00',
                seconds: '00'
            });
            return;
        }

        // Calculate time left
        const days = Math.floor(difference / (1000 * 60 * 60 * 24)).toString().padStart(2, '0');
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24).toString().padStart(2, '0');
        const minutes = Math.floor((difference / 1000 / 60) % 60).toString().padStart(2, '0');
        const seconds = Math.floor((difference / 1000) % 60).toString().padStart(2, '0');

        // Update the state with the new time
        setTimeLeft({ days, hours, minutes, seconds });
    }

    useEffect(() => {
        // Update the countdown timer every second
        intervalRef.current = setInterval(intervalCallback, 1000);
        intervalCallback()

        // Clear the interval on unmount
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [targetDate]);

    return (
        <div className={classes.countdownTimer}>
            <div className={classes.timeSection}>
                <div className={classes.timeValue}>{timeLeft.seconds}</div>
                <div className={classes.timeLabel}>שניות</div>
            </div>
            <div className={classes.timeSection}>
                <div className={classes.timeValue}>{timeLeft.minutes}</div>
                <div className={classes.timeLabel}>דקות</div>
            </div>
            <div className={classes.timeSection}>
                <div className={classes.timeValue}>{timeLeft.hours}</div>
                <div className={classes.timeLabel}>שעות</div>
            </div>
            <div className={classes.timeSection}>
                <div className={classes.timeValue}>{timeLeft.days}</div>
                <div className={classes.timeLabel}>ימים</div>
            </div>
        </div>
    );
};

export default CountdownTimer;
