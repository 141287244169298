// LinkGrid.tsx
import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Col, Container, Row} from 'react-bootstrap';
import topBG from "../assets/images/bg/purple/left0.svg"
import right0BG from "../assets/images/bg/purple/right0.png"
import rightMiddleBG from "../assets/images/bg/purple/middle_r_blue.png"
import {useNavigate} from "react-router-dom";
import NumbersSet from "./main/NumbersSet";
import MainHero from "./main/MainHero";
import TestimonialSlider from "./main/TestimonialSlider";
import MainVideo from "./main/MainVideo";
import ContactViaWhatsapp from "./main/ContactViaWhatsapp";
import CustomerStoriesSlider from "./main/CustomerStoriesSlider";
import {Typography} from "@mui/material";
import BottomBorder from "./BottomBorder";
import SiteList from "./site-list/SiteList";
import PackageList from "./package-list/PackageList";
import {styled} from "@mui/material/styles";
import Promos from "./main/promos/Promos";
import LogoStrip from "./main/LogoStrip";
import DemoArticles from "./main/demo-articles/DemoArticles";
import FAQAccordion from "./main/FAQAccordion";

const useStyles = makeStyles({
    card: {
        border: '1px solid white',
        borderRadius: 10,
        margin: 10,
    },
    formControl: {
        minWidth: 120,
        marginBottom: 20,
    },
    gridInputImage: {
        boxShadow: `8px 4px 10px 0px rgba(0, 0, 0, 0.12)`,
        border: 0,
        zIndex: 1
    },
    gridInput: {
        boxShadow: `4px 4px 10px 0px rgba(0, 0, 0, 0.12)`,
        border: 0
    },
    bg: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
    },
    cloudStart: {
        position: "absolute",
        top: "150px",
        right: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
    },
    cloudMiddleStart: {
        position: "absolute",
        top: "150vh",
        right: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
    }
});

const Root = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column'
}));


const Main: React.FC = ({}) => {
    const classes = useStyles({});
    const navigate = useNavigate();

    const videoId = 'video-guide'

    return (
        <Root>

            <div className={classes.bg}>
                <img className="float-end" src={topBG}/>
                <img className={classes.cloudStart} src={right0BG}/>
                <img className={classes.cloudMiddleStart} src={rightMiddleBG}/>
            </div>
            <MainHero videoId={videoId}/>
            <Container className="text-center">
                <Row className="justify-content-md-center d-inline-block mt-5">
                    <NumbersSet/>
                </Row>
            </Container>

            <PackageList/>

            <MainVideo id={videoId}/>

            <Container fluid>
                <Row className="justify-content-center overflow-hidden mb-sm-5">
                    <LogoStrip/>
                </Row>
            </Container>

            <Promos className="mt-5 mb-sm-5"/>

            <Container className="mt-5 mb-5">
                <Row>
                    <TestimonialSlider/>
                </Row>
            </Container>

            <Container className="mt-sm-5 mb-sm-5">
                <SiteList/>
            </Container>

            <Container className="mt-sm-5 mb-sm-5">
                <Row>
                    <Typography variant="h3">דוגמאות למאמרים</Typography>
                    <BottomBorder width={90}></BottomBorder>
                </Row>
                <Row>
                    <DemoArticles/>
                </Row>
            </Container>

            <Container className="mt-sm-5 mb-5">
                <Row className="mb-3">
                    <Typography variant="h3">לקוחות מספרים</Typography>
                    <BottomBorder width={69}></BottomBorder>
                </Row>
                <Row>
                    <CustomerStoriesSlider/>
                </Row>
            </Container>

            <Container className="mt-sm-5 mb-5">
                <Row className="mb-3">
                    <Typography variant="h3">שאלות נפוצות</Typography>
                    <BottomBorder width={69}></BottomBorder>
                </Row>
                <Row>
                    <Col md={10}>
                        <FAQAccordion/>
                    </Col>
                </Row>
            </Container>

            <Container className="mt-5 mb-5">
                <Row>
                    <ContactViaWhatsapp/>
                </Row>
            </Container>

        </Root>
    );
};

export default Main;