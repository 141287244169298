// LinkGrid.tsx
import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {Card, Col, Container, Row} from 'react-bootstrap';
import right0BG from "../assets/images/bg/purple/right0.png"
import {Button, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useApiService} from "../context/ApiServiceContext";
import Recommendation from "../models/Recommendation";
import SkeletonLoader from "./SkeletonLoader";

const useStyles = makeStyles(theme => ({
    card: {
        border: '1px solid white',
        borderRadius: 10,
        margin: 10,
    },
    formControl: {
        minWidth: 120,
        marginBottom: 20,
    },
    gridInputImage: {
        boxShadow: `8px 4px 10px 0px rgba(0, 0, 0, 0.12)`,
        border: 0,
        zIndex: 1
    },
    gridInput: {
        boxShadow: `4px 4px 10px 0px rgba(0, 0, 0, 0.12)`,
        border: 0
    },
    bg: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
    },
    cloudStart: {
        position: "absolute",
        right: 0,
        width: "100%",
        height: "calc(100% - 150px)",
        zIndex: -1,
    },
    cloudMiddleStart: {
        position: "absolute",
        top: "150vh",
        right: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
    },
    category: {
        borderRadius: "8px",
        border: "1px solid #EAEDF1",
        background: "#F0EFFA",
        color: "#251FB6",
        display: "inline-block",
        padding: "7px 15px",
        margin: "5px 0!important"
    },
    img: {},
    description: {
        height: "72px",
        overflowY: "auto",
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    }
}));

const Root = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column'
}));


const StyledButton = styled(Button)(({theme}) => ({
    width: 'auto',
    maxWidth: '100%',
}));

const StyledCard = styled(Card)(({theme}) => ({
    width: "100%",
    height: "266px",
    borderRadius: "10px",
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down('sm')]: {
        height: 'auto',
    },
}));

const Recommendations: React.FC = ({}) => {
    const classes = useStyles({});

    const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>(''); // Add this line for search term state
    const apiService = useApiService();

    useEffect(() => {
        fetchRecommendations(1)
    }, []); // Add searchTerm to the dependency array

    const fetchRecommendations = useCallback(async (page = 1) => {
        setIsLoading(true)
        // Update the API request to include searchTerm in the query
        apiService.api.get(`/recommendation/list?per_page=50&page=${page}`)
            .then(response => {
                setRecommendations(response.data);
                setIsLoading(false)
            });
    }, [apiService]);

    return (
        <Root>
            <div className={classes.bg}>
                <div className="float-start w-100" style={{
                    height: "1000px",
                    background: `radial-gradient(circle at -11% 500px in hsl shorter hue, #0060ff8f 0%, rgba(255, 255, 255, 0) 400px)`
                }}></div>
                <img className={classes.cloudStart} src={right0BG}/>
            </div>

            <Container className="mb-4 mt-5 text-center" style={{height: "auto",}}>
                <Row className="justify-content-md-center">
                    <Col xl={5}>
                        <Typography variant="h3" style={{marginTop: "10px"}}>המלצות חמות</Typography>
                    </Col>
                </Row>
                {<Row xl={5} className="justify-content-md-center">
                    <Col xl={5}>
                        <Typography variant="body1" style={{marginTop: "10px"}}>גם המומחים הגדולים ביותר לקידום אתרים
                            משתמשים בכוח עזר חיוני ויעיל. כדי להקל עליכם בעניין ניהול הדיגיטל וה-SEO של המותג שלכם,
                            ריכזנו עבורכם כמה כלים טכנולוגיים מועילים וקלים לשימוש. אלה הם הפייבוריטים שלנו – מוזמנים
                            להתנסות בעצמכם!</Typography>
                    </Col>
                </Row>}
            </Container>

            <Container className="mb-4 mt-5" style={{height: "auto",}}>
                <Row className="justify-content-md-center">

                    {isLoading ? (
                        <SkeletonLoader amount={2} marginTop={false}/>
                    ) : (
                        recommendations.length > 0 ? recommendations.map((recommendation, index) => (
                                <Col lg={6}>
                                    <StyledCard key={recommendation.id} className="mb-3">
                                        <Card.Body className={"d-flex flex-column justify-content-between h-100"}
                                                   style={{padding: "24px 16px"}}>
                                            <Row>
                                                <Col xs={4} className="d-flex align-items-center">
                                                    <img className="rounded-4"
                                                         style={{width: "100%", aspectRatio: 1, objectFit: "contain"}}
                                                         src={recommendation.img}/>
                                                </Col>
                                                <Col xs={8}>
                                                    <Typography variant="body2" className={classes.category}>
                                                        {recommendation.category}
                                                    </Typography>
                                                    <Typography variant="h6" style={{fontWeight: 700}}
                                                                className="mb-1">{`${recommendation.title}`}</Typography>
                                                    <div className={classes.description}>
                                                        <Typography variant="body1">
                                                            {recommendation.description}
                                                        </Typography>
                                                    </div>

                                                    <StyledButton color="secondary" variant={"contained"}
                                                                  className={`simple-button blue mt-2`}
                                                                  style={{marginLeft: "10px"}}
                                                                  onClick={() => window.open(recommendation.url, '_blank')}>
                                                        {recommendation.callToAction}
                                                    </StyledButton>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </StyledCard>
                                </Col>
                            )) :
                            <Typography variant="h6" className="fw-bold">
                                לא מצאנו המלצות
                            </Typography>
                    )}
                </Row>
            </Container>
        </Root>
    );
};

export default Recommendations;