import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Typography} from "@mui/material";
import BottomBorder from "../../BottomBorder";
import {useApiService} from "../../../context/ApiServiceContext";
import SkeletonLoader from "../../SkeletonLoader";
import {styled} from "@mui/system";
import Skeleton from "@mui/material/Skeleton";
import Promo from "../../../models/Promo";
import CountdownTimer from "./CountdownTimer";
import PromoPackageComponent from "./PromoPackageComponent";


const StyledContainerFun = (lineColor: string) => styled('div')(({theme}) => ({
    background: `radial-gradient(circle at center bottom, ${lineColor}75 0%, #ffffffd6 64%)`,
    padding: "50px 60px 40px",
    borderRadius: "40px",
    border: `4px solid ${lineColor}2b`,
    boxShadow: "0px 13px 43px 0px rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down('sm')]: {
        padding: '20px', // Smaller font size for small screens
    },
    [theme.breakpoints.between('sm', 'md')]: {
        padding: '30px 30px 20px', // Medium font size for medium screens
    },
}));

const StyledCol = styled(Col)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        margin: '20px 0 10px',
    },
}))

interface PromosProps {
    className: string;
    lineColor?: string;
}

const Promos: React.FC<PromosProps> = ({
                                                                   className,
                                                                   lineColor = "#251FB6",
                                                               }) => {
    const apiService = useApiService();

    const [promo, setPromo] = useState<Promo | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const StyledContainer = StyledContainerFun(lineColor)

    useEffect(() => {
        setIsLoading(true)
        apiService.api.get(`/promo/active`)
            .then(response => {
                setPromo(response.data);
                setIsLoading(false)
            });
    }, []);

    return (
        <Container className={`${className} pb-5`} hidden={!isLoading && !promo}>
            <StyledContainer>
                {isLoading ? (
                    <div>
                        <Skeleton variant="rounded" width={150}
                                  height={80}/>
                        <SkeletonLoader amount={1}/>
                    </div>
                ) : (
                    <>
                        <Row>
                            <Col>
                                <Typography variant="h3" style={{fontWeight: 800}}>{promo?.name}</Typography>
                                <BottomBorder color={lineColor} width={69}></BottomBorder>
                            </Col>
                            <StyledCol>
                                <CountdownTimer targetDate={new Date(promo?.deadline ?? '')}/>
                            </StyledCol>
                        </Row>
                        <Row className="justify-content-center" style={{marginTop: "40px"}}>
                            {promo?.packages?.map(pkg => (
                                <Col key={pkg.id} lg={8} xl={6} className="mt-5 mt-lg-3">
                                    <PromoPackageComponent {...pkg} />
                                </Col>
                            ))}
                        </Row>
                    </>
                )}
            </StyledContainer>
        </Container>
    );
};

export default Promos;