import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Container, Form} from 'react-bootstrap';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {login} from "../../utils/userSlice";
import {AppDispatch} from "../../utils/store";
import {useDispatch} from "react-redux";

const AdminLogin = () => {
    const dispatch: AppDispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent) => {
        try {
            event.preventDefault();

            const success = await dispatch(await login(email, password))

            if (success) {
                navigate('/admin/links');
            }
        } catch (error) {
            toast.error('התחברות נכשלה. אנא בדוק את פרטי הכניסה שלך.');
        }
    };

    return (
        <Container className="d-flex align-items-center justify-content-center vh-100 gap-3">
            <div className="w-100" style={{maxWidth: '400px'}}>
                <Form onSubmit={handleSubmit} className='d-flex gap-3 flex-column'>
                    <Form.Group controlId="formEmail">
                        <Form.Label>אימייל</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="אימייל"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                        <Form.Label>סיסמא</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="סיסמא"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        התחבר
                    </Button>
                </Form>
            </div>
        </Container>
    );
};

export default AdminLogin;
