import React from 'react';
import {CloseButton, Modal} from 'react-bootstrap';
import mainThumb from "../../assets/images/main-thumb.png";
import styled from "@emotion/styled";

interface VideoModalProps {
    show: boolean;
    handleClose: () => void;
    url: string;
}

const StyledModal = styled(Modal)`
  .modal-content {
    overflow: hidden;
  }
`;

const VideoModal: React.FC<VideoModalProps> = ({show, handleClose, url}) => {
    return (
        <StyledModal show={show} onHide={handleClose} centered size="lg">
            <Modal.Body style={{padding: 0, aspectRatio: 16 / 9}}>
                <CloseButton onClick={handleClose}
                             style={{
                                 position: 'absolute',
                                 top: '10px',
                                 right: '10px',
                                 zIndex: 1000,
                             }}/>
                <video preload="auto" className="p-0" height="100%" width="100%" controls autoPlay
                       poster={mainThumb}>
                    <source src={url}
                            type="video/mp4"/>
                </video>
            </Modal.Body>
        </StyledModal>
    );
};

export default VideoModal;