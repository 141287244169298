import React from 'react';
import {Card, Col} from "react-bootstrap";
import {Button, Typography} from "@mui/material";
import {makeStyles} from '@material-ui/core';
import {useNavigate} from "react-router-dom";
import DemoArticle from "../../../models/DemoArticle";

const useStyles = makeStyles({
    buttonWithIcon: {
        '& .MuiButton-startIcon': {
            marginRight: '-6px',
            marginLeft: '2px',
        },
        color: 'black'
    },
    card: {
        width: '100%',
        height: '240px',
        borderRadius: '10px',
        border: '0',
        boxShadow: '0px 13px 11px -5px rgba(163, 163, 163, 0.47)',
        cursor: 'pointer'
    }
});

interface DemoArticleProps extends DemoArticle {
    onClick: () => void
}

const DemoArticleItem: React.FC<DemoArticleProps> = ({
                                                         onClick,
                                                         ...article
                                                     }) => {
    const classes = useStyles();
    const navigate = useNavigate()

    return (
        <div key={article.id}>
            <Card className={classes.card} style={{}}>
                <Card.Body className={"d-flex flex-column justify-content-between"} style={{padding: "24px 16px"}}>
                    <div>
                        <Col xs={12} className="mb-2">
                            <Typography variant="h6" className="fw-bold lh-1">{article.title}</Typography>
                        </Col>
                        <Col xs={12}>
                            <Typography variant="body2" style={{
                                height: "calc(6 * 1.33em)",
                                overflowY: "auto"
                            }}>{article.description}</Typography>
                        </Col>
                    </div>
                    <div>
                        <Button color="secondary" variant={"contained"}
                                className='simple-button medium long blue'
                                onClick={onClick}>
                            לצפייה במאמר
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
        ;
};

export default DemoArticleItem;