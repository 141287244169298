import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, Row, Table} from 'react-bootstrap';
import {toast} from "react-toastify";
import * as yup from 'yup';
import {useFormik} from 'formik';
import {AxiosResponse} from "axios";
import {useApiService} from "../../../context/ApiServiceContext";
import Category from "../../../models/Category";
import Promo from "../../../models/Promo";
import Package from "../../../models/Package";
import {useNavigate, useParams} from "react-router-dom";
import AdminPromoPackage from "./AdminPromoPackage";
import DatePicker from "react-datepicker";
import {PackageRequestBody} from "../AdminPackages";
import {formatISO} from "date-fns";
import ConfirmationModal from "../../../utils/ConfirmationModal";

interface PromoRequestBody {
    id?: string;
    name: string;
    info: {
        description?: string;
    };
    packageIds: String[]
    deadline: string;
}

interface EditablePromo {
    id?: string;
    name: string;
    info: {
        description?: string;
    };
    packages: Package[];
    deadline: string;
}


interface AdminPromoDetailsProps {
    // promoId?: string
}

const AdminPromoDetails: React.FC<AdminPromoDetailsProps> = ({}) => {
    const {promoId} = useParams();
    const apiService = useApiService();
    const [categories, setCategories] = useState<Category[]>([]);
    const [editablePromo, setEditablePromo] = useState<EditablePromo>({
        name: '',
        info: {
            description: '',
        },
        packages: [],
        deadline: '',
    });
    const [showModal, setShowModal] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const navigate = useNavigate()

    const fetchCategories = useCallback(async () => {
        try {
            const res = await apiService.api.get('/category/list?per_page=30');
            setCategories(res.data);
        } catch (error: any) {
            toast.error(`בעיה בעת משיכת הקטגוריות: ${error.message}`);
        }
    }, [apiService]);

    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    useEffect(() => {
        const getPromo = async () => {
            try {
                const res = await apiService.api.get<Promo>(`/promo/${promoId}`);
                formik.resetForm({
                    values: {
                        name: res.data.name,
                        deadline: res.data.deadline,
                        info: {
                            description: res.data.info.description ?? '',
                        },
                        packages: res.data.packages ?? []
                    }
                })
                setEditablePromo({...res.data});
            } catch (error: any) {
                toast.error(`בעיה בעת משיכת החבילות: ${error.message}`);
            }
        }

        if (promoId) {
            getPromo()
        }
    }, [])

    const handleDeleteConfirmation = useCallback(() => {

        if (selectedPackage) {
            const packageId = selectedPackage.id
            apiService.api.delete(`/package/${packageId}`)
                .then(() => {
                    toast.success('החבילה נמחקה בהצלחה')

                    setEditablePromo(prevPromo => ({
                        ...prevPromo,
                        packages: prevPromo.packages.filter(pkg => pkg.id !== packageId)
                    }));
                    setSelectedPackage(null)
                    setDeleteModalShow(false)
                })
                .catch(() => {
                    toast.error('בעיה בעת מחיקת החבילה')
                })
        }
        setSelectedPackage(null)
    }, [selectedPackage]);

    const handleUpdate = (promoId: string, updatedPromoData: PromoRequestBody) => {
        apiService.api
            .put(`/promo/${promoId}`, updatedPromoData)
            .then((response: AxiosResponse<Promo>) => {
                navigate("/admin/promos")
                toast.success('החבילה עודכנה בהצלחה');
            })
            .catch(() => {
                toast.error('תקלה בעת עדכון החבילה');
            });
    };

    const handleCreate = (newPromoData: PromoRequestBody) => {
        apiService.api
            .post('/promo/', newPromoData)
            .then((promoRes: AxiosResponse<Promo>) => {
                navigate("/admin/promos")
                toast.success('החבילה הוספת בהצלחה');
            })
            .catch(() => {
                toast.error('בעיה בעת הוספת החבילה');
            });
    };

    const openModal = (pack: Package | null) => {
        console.log(pack)
        setSelectedPackage(pack)
        setShowModal(true);
    };

    const openDeleteModal = (pkg: Package) => {
        setSelectedPackage(pkg);
        setDeleteModalShow(true);
    };

    // Promo validation schema
    const promoSchema = yup.object().shape({
        name: yup.string()
            .required('שם החבילה נדרש')
            .min(2, 'שם החבילה קצר מדי')
            .max(50, 'שם החבילה ארוך מדי'),
        active: yup.boolean(),
        info: yup.object({
            description: yup.string(),
        }),
        deadline: yup.date()
            .min(new Date(), 'המועד חייב להיות בעתיד')
            .required('נדרש תאריך סיום'),
    });

    function getMinDate() {
        const minDate = new Date();
        minDate.setDate(minDate.getDate() + 7)
        return minDate
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            deadline: '',
            info: {
                description: '',
            },
            packages: [] as Package[]
        }, // Add other fields
        validationSchema: promoSchema,
        onSubmit: values => {
            if (formik.isValid) {
                const promoData = {
                    name: values.name,
                    deadline: formatISO(new Date(values.deadline), {representation: 'complete'}),
                    info: {
                        description: values.info?.description,
                    },
                    packageIds: editablePromo!.packages.map(x => x.id)
                };

                if (editablePromo && editablePromo.id) {
                    handleUpdate(editablePromo.id, promoData);
                } else {
                    handleCreate(promoData);
                }
            }
        },
        validateOnBlur: true,
        validateOnMount: true,
    });

    function onPackageSubmit(updatedPackageData: Package) {
        // Check if the package with the given ID exists
        const packageExists = editablePromo.packages.some(pkg => pkg.id === updatedPackageData.id);

        // Update the packages array
        const updatedPackages = packageExists
            ? editablePromo.packages.map(pkg => pkg.id === updatedPackageData.id ? updatedPackageData : pkg)
            : [...editablePromo.packages, updatedPackageData];

        // Update the state
        setEditablePromo({
            ...editablePromo,
            packages: updatedPackages
        });

        // Reset the selected package
        setSelectedPackage(null);
    }

    return (
        <>
            <Row className="pt-md-5 pb-md-2">
                <Col md={5}>
                    <h1 className="mb-2">{editablePromo?.id ? 'ערוך מבצע' : 'הוסף מבצע חדש'}</h1>
                </Col>
            </Row>
            <Form noValidate onSubmit={formik.handleSubmit}>

                <Row className="py-md-2">
                    <Col md={5}>
                        <Form.Group className="mb-3">
                            <Form.Label>שם המבצע</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.name && !!formik.errors.name}
                                placeholder="הכנס את שם המבצע"
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.name}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label className="me-2">תאריך סיום</Form.Label>
                            <DatePicker
                                className={`form-control ${formik.touched.deadline && formik.errors.deadline ? 'is-invalid' : ''}`}
                                selected={formik.values.deadline ? new Date(formik.values.deadline) : null}
                                onChange={(date) => formik.setFieldValue('deadline', date)}
                                locale="he"
                                dateFormat="dd/MM/yyyy HH:mm"
                                minDate={new Date()}
                                required
                                showTimeSelect
                            />
                            {formik.touched.deadline && formik.errors.deadline ? (
                                <div className="invalid-feedback">{formik.errors.deadline}</div>
                            ) : null}
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="py-md-2">
                    <Col md={5}>
                        <h3 className="mb-2">חבילות</h3>
                    </Col>
                    <Col md={5} className="d-flex justify-content-end align-items-center">
                        <Button onClick={() => openModal(null)}>הוספת חבילה</Button>
                    </Col>
                </Row>
                <Row className="py-md-1">
                    <Col md={10}>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>שם החבילה</th>
                                <th>מספר לינקים</th>
                                <th>קטגוריה</th>
                                <th>תת-קטגוריות</th>
                                <th>סוג האתר</th>
                                <th>מחיר</th>
                                <th>פעולות</th>
                            </tr>
                            </thead>
                            <tbody>
                            {editablePromo?.packages.map((pkg) => (
                                <tr key={pkg.id}>
                                    <td>{pkg.name}</td>
                                    <td>{pkg.linksCount}</td>
                                    <td>{pkg.category.name}</td>
                                    <td>{pkg.subCategories.map(x => x.name).join(", ")}</td>
                                    <td>{pkg.siteType}</td>
                                    <td>{pkg.price}</td>
                                    <td>
                                        <Button className="me-2" onClick={() => openModal(pkg)}>עריכה</Button>
                                        <Button variant="danger" onClick={() => openDeleteModal(pkg)}>מחק</Button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <Button variant="secondary" onClick={() => navigate("/admin/promos")}>סגור</Button>
                <Button type="submit" variant="primary">{editablePromo?.id ? 'עדכן' : 'צור'}</Button>
            </Form>

            <AdminPromoPackage open={showModal} handleClose={() => {
                setShowModal(false)
                setSelectedPackage(null)
            }} selectedPackage={selectedPackage} onSubmitCallback={onPackageSubmit}/>

            <ConfirmationModal
                show={deleteModalShow}
                onClose={() => setDeleteModalShow(false)}
                onConfirm={handleDeleteConfirmation}
                title="אישור מחיקה"
                message="האם אתה בטוח שאתה רוצה למחוק את החבילה?"
            />
        </>
    );
};

export default AdminPromoDetails;
