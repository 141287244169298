import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, InputGroup, Modal, Row, Table} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {AxiosResponse} from "axios";
import {useApiService} from "../../context/ApiServiceContext";
import * as yup from 'yup';
import {useFormik} from "formik";
import DemoArticle from "../../models/DemoArticle";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {makeStyles} from "@material-ui/core";

interface DemoArticleRequestBody {
    title: string;
    description: string;
    text: string;
}

const modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
};

// Formats the react-quill editor understands
const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent'
];

const useStyles = makeStyles({
    quillRtl: {
        "& .ql-editor": {
            minHeight: "calc(1.5em * 10)",
            direction: "rtl",
            textAlign: "right"
        }
    }
})

const AdminDemoArticles: React.FC = () => {
    const classes = useStyles();
    const apiService = useApiService();
    const [demoArticles, setDemoArticles] = useState<DemoArticle[]>([]);
    const [selectedDemoArticle, setSelectedDemoArticle] = useState<DemoArticle | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [demoArticleFields, setDemoArticleFields] = useState<DemoArticleRequestBody>({
        title: '',
        description: '',
        text: '',
    });

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const demoArticlesPerPage = 20; // Adjust as needed
    /* End of Pagination */

    useEffect(() => {
        fetchDemoArticles(currentPage);
    }, [currentPage]);

    const fetchDemoArticles = useCallback(async (page = 1) => {
        try {
            const url = `/demo-article/list?page=${page}&per_page=${demoArticlesPerPage}`;
            const res = await apiService.api.get(url);
            setDemoArticles(prevDemoArticles => [...prevDemoArticles, ...res.data]);
            setHasMorePages(res.data.length === demoArticlesPerPage);
        } catch (error) {
            // Handle error
        }
    }, [apiService, demoArticlesPerPage]);


    const handleDelete = (demoArticleId: string) => {
        if (window.confirm('האם אתה בטוח שברצונך למחוק את המאמר לדוגמא?')) {
            apiService.api
                .delete(`/demo-article/${demoArticleId}`)
                .then(() => {
                    setDemoArticles(demoArticles.filter((demoArticle) => demoArticle.id !== demoArticleId));
                    toast.success('המאמר לדוגמא נמחקה בהצלחה');
                })
                .catch(() => {
                    toast.error('בעיה במחיקת המאמר לדוגמא');
                });
        }
    };

    const handleUpdate = (values: any) => {
        if (selectedDemoArticle) {
            const updatedDemoArticle: DemoArticleRequestBody = {
                ...values
            };

            apiService.api
                .put(`/demo-article/${selectedDemoArticle.id}`, updatedDemoArticle)
                .then((res: AxiosResponse<DemoArticle>) => {
                    setDemoArticles(
                        demoArticles.map((demoArticle) => (demoArticle.id === selectedDemoArticle.id ? res.data : demoArticle))
                    );
                    toast.success('המאמר לדוגמא עודכנה בהצלחה');
                })
                .catch(() => {
                    toast.error('בעיה בעדכון המאמר לדוגמא');
                });

            setShowModal(false);
            formik.resetForm()
        }
    };

    const handleCreate = (values: any) => {
        const newDemoArticle: DemoArticleRequestBody = values;

        apiService.api
            .post('/demo-article/', newDemoArticle)
            .then((res: AxiosResponse<DemoArticle>) => {
                setDemoArticles([res.data, ...demoArticles]);
                toast.success('המאמר לדוגמא נוספה בהצלחה');
            })
            .catch(() => {
                toast.error('בעיה בהוספת המאמר לדוגמא');
            });

        setShowModal(false);
        formik.resetForm()
    };

    const openModal = (demoArticle: DemoArticle | null) => {
        setSelectedDemoArticle(demoArticle);
        setDemoArticleFields({
            title: demoArticle ? demoArticle.title : '',
            description: demoArticle ? demoArticle.description : '',
            text: demoArticle ? demoArticle.text : '',
        });
        setShowModal(true);
    };

    const PaginationBar = () => {
        return (
            <div className="d-flex justify-content-center my-3">
                <Button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="me-2"
                >
                    הקודם
                </Button>
                <Button
                    disabled={!hasMorePages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    הבא
                </Button>
            </div>
        );
    };

    const demoArticleSchema = yup.object().shape({
        title: yup.string().required('נדרשת כותרת'),
        description: yup.string().required('נדרש תיאור'),
        text: yup.string().min(50, "המאמר חייב להכיל לפחות 100 תווים")
    });

    const formik = useFormik({
        initialValues: demoArticleFields,
        validationSchema: demoArticleSchema,
        onSubmit: values => {
            if (selectedDemoArticle) {
                handleUpdate(values);
            } else {
                handleCreate(values);
            }
        },
        validateOnBlur: true,
        validateOnMount: true,
        enableReinitialize: true,
    });

    return (
        <Row className="justify-content-center py-md-5">
            <Col md={4}>
                <h1 className="mb-2">מאמרים לדוגמא</h1>
            </Col>
            <Col md={8} className="d-flex justify-content-end align-items-center">
                {/*<Form onSubmit={handleSearch} className="me-4 w-50">*/}
                {/*    <InputGroup>*/}
                {/*        <Form.Control*/}
                {/*            placeholder="Search by Name"*/}
                {/*            onChange={handleSearchChange}*/}
                {/*            value={searchName}*/}
                {/*        />*/}
                {/*        <Button variant="outline-secondary" onClick={handleSearch}>*/}
                {/*            חיפוש*/}
                {/*        </Button>*/}
                {/*    </InputGroup>*/}
                {/*</Form>*/}
                <Button onClick={() => openModal(null)}>הוסף מאמר לדוגמא</Button>
            </Col>
            <Col md={12}>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>כותרת</th>
                        <th>תיאור</th>
                        <th>נוצר בתאריך</th>
                        <th>עודכן בתאריך</th>
                        <th>פעולות</th>
                    </tr>
                    </thead>
                    <tbody>
                    {demoArticles.map((demoArticle, index) => (
                        <tr key={demoArticle.id}>
                            <td>{index + 1}</td>
                            <td>{demoArticle.title}</td>
                            <td>{demoArticle.description}</td>
                            <td>{new Date(demoArticle.createdAt).toLocaleDateString()}</td>
                            <td>
                                {demoArticle.updatedAt &&
                                    new Date(demoArticle.updatedAt).toLocaleDateString()}
                            </td>
                            <td>
                                <Button className="me-2" onClick={() => openModal(demoArticle)}>
                                    ערוך
                                </Button>
                                <Button variant="danger" onClick={() => handleDelete(demoArticle.id)}>
                                    מחק
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <PaginationBar/>
            </Col>

            <Modal show={showModal} onHide={() => {
                setShowModal(false);
                formik.resetForm();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedDemoArticle ? 'עדכן' : 'הוסף'} מאמר לדוגמא</Modal.Title>
                </Modal.Header>
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <Form.Group className="mt-2">
                            <Form.Label>כותרת</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                placeholder="הכנס כותרת"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.title && !!formik.errors.title}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.title}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>תיאור</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                placeholder="הכנס תיאור"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.description && !!formik.errors.description}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.description}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>מאמר</Form.Label>
                            <ReactQuill
                                className={classes.quillRtl}
                                placeholder="הכנס את המאמר"
                                value={formik.values.text}
                                onChange={value => formik.setFieldValue('text', value)}
                                modules={modules}
                                formats={formats}
                            />
                            {formik.touched.text && formik.errors.text && (
                                <div className="invalid-feedback d-block">
                                    {formik.errors.text}
                                </div>
                            )}
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            בטל
                        </Button>
                        <Button variant="primary" type="submit">
                            שמור
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Row>
    );
};


export default AdminDemoArticles;
