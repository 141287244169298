import devConfig from './config.dev';
import prodConfig from './config.prod';
import {Config} from "../models/Config";

let config: Config = devConfig; // Default to development config
if (process.env.NODE_ENV === 'production') {
    config = prodConfig;
}
console.log(process.env.NODE_ENV)

export default config;