import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, Modal, Row, Table} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {AxiosResponse} from "axios";
import {useApiService} from "../../context/ApiServiceContext";
import * as yup from 'yup';
import {useFormik} from "formik";
import FAQ from "../../models/FAQ";
import ReactQuill from "react-quill";
import {makeStyles} from "@material-ui/core";

interface FAQRequestBody {
    title: string;
    description: string;
}

const useStyles = makeStyles({
    quillRtl: {
        "& .ql-editor": {
            minHeight: "calc(1.5em * 10)",
            maxHeight: "calc(1.5em * 20)",
            direction: "rtl",
            textAlign: "right"
        }
    }
})

const modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
};

// Formats the react-quill editor understands
const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent'
];

const AdminFAQ: React.FC = () => {
    const classes = useStyles();
    const apiService = useApiService();
    const [faqs, setFAQ] = useState<FAQ[]>([]);
    const [selectedFAQ, setSelectedFAQ] = useState<FAQ | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [faqFields, setFAQFields] = useState<FAQRequestBody>({
        title: '',
        description: '',
    });

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const faqsPerPage = 20; // Adjust as needed
    /* End of Pagination */

    useEffect(() => {
        fetchFAQ(currentPage);
    }, [currentPage]);

    const fetchFAQ = useCallback(async (page = 1) => {
        try {
            const url = `/faq/list?page=${page}&per_page=${faqsPerPage}`;
            const res = await apiService.api.get(url);
            setFAQ(prevFAQ => [...prevFAQ, ...res.data]);
            setHasMorePages(res.data.length === faqsPerPage);
        } catch (error) {
            // Handle error
        }
    }, [apiService, faqsPerPage]);


    const handleDelete = (faqId: string) => {
        if (window.confirm('האם אתה בטוח שברצונך למחוק את השאלה?')) {
            apiService.api
                .delete(`/faq/${faqId}`)
                .then(() => {
                    setFAQ(faqs.filter((faq) => faq.id !== faqId));
                    toast.success('השאלה נמחקה בהצלחה');
                })
                .catch(() => {
                    toast.error('בעיה במחיקת השאלה');
                });
        }
    };

    const handleUpdate = (values: any) => {
        if (selectedFAQ) {
            const updatedFAQ: FAQRequestBody = {
                ...values
            };

            apiService.api
                .put(`/faq/${selectedFAQ.id}`, updatedFAQ)
                .then((res: AxiosResponse<FAQ>) => {
                    setFAQ(
                        faqs.map((faq) => (faq.id === selectedFAQ.id ? res.data : faq))
                    );
                    toast.success('השאלה עודכנה בהצלחה');
                })
                .catch(() => {
                    toast.error('בעיה בעדכון השאלה');
                });

            setShowModal(false);
            formik.resetForm()
        }
    };

    const handleCreate = (values: any) => {
        const newFAQ: FAQRequestBody = values;

        apiService.api
            .post('/faq/', newFAQ)
            .then((res: AxiosResponse<FAQ>) => {
                setFAQ([res.data, ...faqs]);
                toast.success('השאלה נוספה בהצלחה');
            })
            .catch(() => {
                toast.error('בעיה בהוספת השאלה');
            });

        setShowModal(false);
        formik.resetForm()
    };

    const openModal = (faq: FAQ | null) => {
        setSelectedFAQ(faq);
        setFAQFields({
            title: faq ? faq.title : '',
            description: faq ? faq.description : '',
        });
        setShowModal(true);
    };

    const PaginationBar = () => {
        return (
            <div className="d-flex justify-content-center my-3">
                <Button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="me-2"
                >
                    הקודם
                </Button>
                <Button
                    disabled={!hasMorePages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    הבא
                </Button>
            </div>
        );
    };

    const faqSchema = yup.object().shape({
        title: yup.string().required('נדרשת שאלה'),
        description: yup.string().required('נדרשת תשובה'),
    });

    const formik = useFormik({
        initialValues: faqFields,
        validationSchema: faqSchema,
        onSubmit: values => {
            if (selectedFAQ) {
                handleUpdate(values);
            } else {
                handleCreate(values);
            }
        },
        validateOnBlur: true,
        validateOnMount: true,
        enableReinitialize: true,
    });

    return (
        <Row className="justify-content-center py-md-5">
            <Col md={4}>
                <h1 className="mb-2">שאלות תשובות</h1>
            </Col>
            <Col md={8} className="d-flex justify-content-end align-items-center">
                <Button onClick={() => openModal(null)}>הוסף שאלה-תשובה</Button>
            </Col>
            <Col md={12}>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>שאלה</th>
                        {/*<th>תיאור</th>*/}
                        <th>נוצר בתאריך</th>
                        <th>עודכן בתאריך</th>
                        <th>פעולות</th>
                    </tr>
                    </thead>
                    <tbody>
                    {faqs.map((faq, index) => (
                        <tr key={faq.id}>
                            <td>{index + 1}</td>
                            <td>{faq.title}</td>
                            {/*<td>{faq.description}</td>*/}
                            <td>{new Date(faq.createdAt).toLocaleDateString()}</td>
                            <td>
                                {faq.updatedAt &&
                                    new Date(faq.updatedAt).toLocaleDateString()}
                            </td>
                            <td>
                                <Button className="me-2" onClick={() => openModal(faq)}>
                                    ערוך
                                </Button>
                                <Button variant="danger" onClick={() => handleDelete(faq.id)}>
                                    מחק
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <PaginationBar/>
            </Col>

            <Modal show={showModal} onHide={() => {
                setShowModal(false);
                formik.resetForm();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedFAQ ? 'עדכון' : 'הוספת'} המלצה</Modal.Title>
                </Modal.Header>
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>שאלה</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                placeholder="כותרת ההמלצה"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.title && !!formik.errors.title}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.title}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>תשובה</Form.Label>
                            <ReactQuill
                                className={classes.quillRtl}
                                placeholder="הכנס תשובה"
                                value={formik.values.description}
                                onChange={value => formik.setFieldValue('description', value)}
                                modules={modules}
                                formats={formats}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.description}
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            בטל
                        </Button>
                        <Button variant="primary" type="submit">
                            שמור
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Row>
    );
};

export default AdminFAQ;
