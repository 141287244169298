import React, {useState, useEffect, useCallback} from 'react';
import Papa from 'papaparse';
import {Button, Col, Row, Table} from 'react-bootstrap';
import Link, {SiteType} from "../../models/Link";
import {LinkRequestBody} from "./AdminLinks";
import {makeStyles} from "@material-ui/core";
import {useApiService} from "../../context/ApiServiceContext";
import {trimToHostname} from "../../utils/utils";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import Category from "../../models/Category";
import {CircularProgress} from "@mui/material";

export interface LinkWithFile {
    website: string;
    file: File;
}

const useStyles = makeStyles({
    changedCell: {
        backgroundColor: 'lightyellow!important',
    },
    validationError: {
        backgroundColor: 'lightpink!important',
    },
    newLinkRow: {
        backgroundColor: 'lightgreen!important',
    },
    normalCell: {
        backgroundColor: 'inherit',
    },
});

const AdminImportLinkThumbnails: React.FC = () => {
    const classes = useStyles();
    const apiService = useApiService();
    const navigate = useNavigate();
    const [links, setLinks] = useState<Link[]>([]);
    const [linksWithFiles, setLinksWithFiles] = useState<LinkWithFile[]>([]);
    const [validationError, setValidationError] = useState<boolean>(false);
    const [imagePreviews, setImagePreviews] = useState<Record<string, string>>({});
    const [isFileUploading, setFileUploading] = useState<boolean>(false);

    const fetchLinksFromDatabase = async (websites: string[]) => {
        const response = await apiService.api.get(`/link/list?per_page=1000&websites=${encodeURIComponent(websites.join(","))}`)
        const linksWithHostname: Link[] = response.data.map((link: Link) => ({
            ...link,
            website: trimToHostname(link.website)
        }))

        return linksWithHostname
    };

    const updateLinks = async (links: LinkWithFile[]) => {
        const res = await uploadFiles(links.map(x => x.file))

        const updatedLinks = res?.urls?.map(url => {
            const filename = getFileNameFromUrl(url)
            const filenameNoExt = removeExtension(filename)
            const trimHostname = trimToHostname(filenameNoExt)
            return {
                id: findLinkByWebsite(trimHostname)!!.id,
                thumbnail: url
            }
        }) ?? []

        console.log("updatedLinks", updatedLinks)

        try {
            const response = await apiService.api.post(`/link/bulk`, {
                update: updatedLinks,
                insert: []
            })

            toast.success(`עודכנו בהצלחה ${response.data['updated']}`)
            navigate("/admin/links")
        } catch (e) {
            toast.error("שגיאה בהכנסת הנתונים")
        }
    };

    const websitesArray = useCallback((links: LinkWithFile[]) => links.map(x => x.website), [])

    // Function to check if a field has changed using a path
    const getClass = (links: LinkWithFile[], link: LinkWithFile) => {
        if (!websitesArray(links).includes(link.website)) {
            return classes.validationError
        } else {
            return classes.newLinkRow
        }
    };

    const findLinkByWebsite = (website: string) => {
        return links.find(link => link.website === website);
    };


    const uploadFiles = (files: File[]): Promise<{ urls: string[] } | undefined> => {
        if (files && files.length > 0) {
            setFileUploading(true)

            const formData = new FormData();
            files.forEach((file, index) => {
                formData.append(`file${index}`, file);
            });

            return apiService.api.post<{ urls: string[] }>(`/link/thumbnail/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(res => res.data)
                .finally(() => {
                    setFileUploading(false)
                });
        }
        return Promise.resolve(undefined)
    };

    const handleFileChange = async (files: File[]) => {
        if (files && files.length > 0) {
            const filesNoExtensionMap = files.reduce((prev, current) => {
                const hostname = trimToHostname(removeExtension(current.name));
                prev[hostname] = current;
                return prev;
            }, {} as Record<string, File>);
            const websites = Object.keys(filesNoExtensionMap)
            const linksFromDB = await fetchLinksFromDatabase(websites)
            setLinks(linksFromDB)

            const newImagePreviews: Record<string, string> = {...imagePreviews};

            Object.keys(filesNoExtensionMap).forEach(key => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    newImagePreviews[key] = reader.result as string;
                    setImagePreviews(newImagePreviews);
                };
                reader.readAsDataURL(filesNoExtensionMap[key]);
            });

            const links = linksFromDB.map<LinkWithFile>(x => ({
                website: x.website,
                file: filesNoExtensionMap[x.website]
            }))

            setLinksWithFiles(links)
        }
    };

    const removeExtension = (filename: string): string => {
        // Split the filename by '.' and remove the last part (extension)
        const parts = filename.split('.');
        parts.pop(); // Remove the last element (the extension)
        return parts.join('.'); // Rejoin the remaining parts
    };

    const getFileNameFromUrl = (url: string): string => {
        const parts = url.split('/');
        return parts.pop() ?? "";
    };

    return (
        <>
            <Row className="justify-content-center py-md-5">
                <Col md={4}>
                    <h1 className="mb-2">עדכון תמונות מרובה</h1>
                    <span className="mb-2">שימו לב, שם התמונה חייב להיות כתובת האתר + סיומת jpg/jpeg/png. ברשימה יוצגו אתרים שנמצאו במערכת בלבד</span>
                </Col>
                <Col md={8} className="d-flex justify-content-end align-items-center">
                    <Button disabled={isFileUploading || linksWithFiles.length === 0} className="me-2"
                            onClick={() => updateLinks(linksWithFiles)}>עדכן את הקישורים מהרשימה</Button>
                    <input
                        type="file"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleFileChange(e.target.files ? Array.from(e.target.files) : [])}
                        accept=".png,.jpg,.jpeg,.webp"
                        multiple
                        disabled={isFileUploading}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <CircularProgress className="ms-3" color="secondary"
                                      size={40}
                                      hidden={!isFileUploading}/>
                </Col>
            </Row>
            <Row className="justify-content-center py-md-5">
                {linksWithFiles.map(link => (
                    <Col md={3} key={link.website} className={`${getClass(linksWithFiles, link)} me-2 ms-2 mb-2`}>
                        <h4 className="mt-2 mb-2">{link.website}</h4>
                        {imagePreviews[link.website] && <img src={imagePreviews[link.website]} alt={link.website}
                                                             className="object-fit-contain w-100"
                                                             style={{aspectRatio: 1}}/>}
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default AdminImportLinkThumbnails;
