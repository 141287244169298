import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import './index.css';

import {ToastContainer} from "react-toastify";
import {Provider} from "react-redux";
import store from "./utils/store";

// document.documentElement.setAttribute('data-bs-theme', 'dark');
document.documentElement.setAttribute('dir', 'rtl');

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
    <>
        <Provider store={store}>
            <App/>
        </Provider>
        <ToastContainer position='bottom-left' rtl={true}/>
    </>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
