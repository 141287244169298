import React from "react";
import arrowLeft from "../assets/images/chevron-left.png";
import arrowRight from "../assets/images/chevron-right.png";
import {styled} from "@mui/material/styles";

// Define the type for arrow props
type ArrowProps = {
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
    currentSlide?: number;
    slideCount?: number;
    src: string;
};

export const Arrow = (props: ArrowProps) => {
    const {className, style, onClick, src} = props;
    return (
        <img src={src}
             className={className}
             style={{...style}}
             onClick={onClick}
        />
    );
}


// Define the type for arrow props
type ReadyArrowProps = {
    onClick?: () => void;
    currentSlide?: number;
    slideCount?: number;
};

export const ArrowLeft = (props: ReadyArrowProps) => {

    const {onClick} = props;
    const StyledArrow = styled(Arrow)(({theme}) => ({
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            left: "-5px!important",
            zIndex: 1,
            width: 'auto'
        }
    }));

    return <StyledArrow src={arrowLeft} className={"slick-arrow slick-next"}
                        onClick={onClick}/>
}
export const ArrowRight = (props: ReadyArrowProps) => {

    const {onClick} = props;
    const StyledArrow = styled(Arrow)(({theme}) => ({
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            right: "-5px!important",
            zIndex: 1,
        }
    }));

    return <StyledArrow src={arrowRight} className={"slick-arrow slick-prev"}
                        onClick={onClick}/>
}