import React, {useEffect, useState} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import {useApiService} from "../../context/ApiServiceContext";
import FAQ from '../../models/FAQ';
import SkeletonLoader from '../SkeletonLoader';

export const FAQAccordion: React.FC = () => {
    const [faqs, setFaqs] = useState<FAQ[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const apiService = useApiService();

    useEffect(() => {
        apiService.api.get('/faq/list?per_page=50')
            .then(response => {
                setFaqs(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Fetching FAQs failed", error);
                setIsLoading(false);
            });
    }, [apiService]);

    if (isLoading) {
        return <SkeletonLoader amount={1} marginTop/>;
    }

    return (
        <div className="faq-accordion">
            <Accordion defaultActiveKey="0">
                {faqs.map((faq, index) => (
                    <Accordion.Item eventKey={String(index)} key={index}>
                        <Accordion.Header>{faq.title}</Accordion.Header>
                        <Accordion.Body><p style={{
                            whiteSpace: "pre-line"
                        }} dangerouslySetInnerHTML={{__html: faq.description ?? ''}}></p></Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
};

export default FAQAccordion;
