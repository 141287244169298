// LinkGrid.tsx
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import {Col, Container, Form, InputGroup, Row} from 'react-bootstrap';
import {AppDispatch} from "../../utils/store";
import {Link, SiteType} from "../../models/Link";
import Category from "../../models/Category";
import {Typography} from "@mui/material";
import LinkItem from "../LinkItem";
import BottomBorder from "../BottomBorder";
import magnify from "../../assets/images/search.svg"
import {useApiService} from "../../context/ApiServiceContext";
import SkeletonLoader from "../SkeletonLoader";
import Skeleton from "@mui/material/Skeleton";
import RecommendedLinks from "../RecommendedLinks";
import {useLocation} from "react-router-dom";
import {selectIsLoggedIn} from "../../utils/userSlice";
import LinkPageAggr from "../../models/LinkPageAggr";
import {toast} from "react-toastify";
import {debounce} from "../../utils/utils";
import AnalyticsService, {AnalyticsEventName} from "../../services/AnalyticsService";

const useStyles = makeStyles({
    card: {
        border: '1px solid white',
        borderRadius: 10,
        margin: 10,
    },
    formControl: {
        minWidth: 120,
        marginBottom: 20,
    },
    gridInputImage: {
        boxShadow: `8px 4px 10px 0px rgba(0, 0, 0, 0.12)`,
        border: 0,
        zIndex: 1
    },
    gridInput: {
        boxShadow: `4px 4px 10px 0px rgba(0, 0, 0, 0.12)`,
        border: 0
    },
    bg: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "calc(100% + 92px)",
        zIndex: -1,
        maxHeight: "calc(100% + 92px)",
        overflow: "hidden"
    },
    doubleGradient: {
        position: "relative",
        "&:after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "radial-gradient(circle at 120% 40%, #0060ff 0%, rgba(255, 255, 255, 0) 20%)",
            zIndex: -1
        }
    }
});

interface LinkBaseProps {
    header: {
        title: string;
        subtitle: string;
    };
    lineColor: string;
    subtitle: string;
    subtitleImage: string;
    linkBG: string;
    siteType: SiteType;
    allowProvideContent?: boolean;
    hideCategories?: boolean;
    fetchAll?: boolean;
}

const LinkBase: React.FC<LinkBaseProps> = ({
                                               header,
                                               lineColor,
                                               subtitle,
                                               subtitleImage,
                                               linkBG,
                                               siteType,
                                               allowProvideContent = false,
                                               hideCategories = false,
                                               fetchAll = false
                                           }) => {
    const classes = useStyles({});
    const apiService = useApiService();
    const location = useLocation()

    const dispatch: AppDispatch = useDispatch();
    const [linksPage, setLinksPage] = useState<LinkPageAggr[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedCategory, setSelectedCategory] = useState<string | null>(getCategoryFromSearchParams());
    const [scrollToAnchor, setScrollToAnchor] = useState<string | null>(null);
    const [categories, setCategories] = useState<Category[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
    const hasScrolledRef = useRef(false);

    const isLoggedIn = useSelector(selectIsLoggedIn);

    const analyticsService = AnalyticsService.getInstance();

    function getCategoryFromSearchParams() {
        const queryParams = new URLSearchParams(location.search)
        return queryParams.get("category")
    }

    const shuffleArray = <T,>(a: T[]) => a.map((v) => ({v, sort: Math.random()})).sort((a, b) => a.sort - b.sort).map(({v}) => v);

    useEffect(() => {
        if (!isLoading && scrollToAnchor && !hasScrolledRef.current) {
            const anchorElement = document.getElementById(scrollToAnchor);
            if (anchorElement) {
                const appBarHeight = 100;
                const yOffset = -appBarHeight; // Negative offset to account for the AppBar
                const y = anchorElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                hasScrolledRef.current = true
            }
            setScrollToAnchor(null)
        }
    }, [isLoading, scrollToAnchor]);

    function flattenLinks(data: LinkPageAggr[]) {
        const uniqueObjectsById = new Set();
        const uniqueObjects: Link[] = [];

        data.forEach((category: LinkPageAggr) => {
            const links = category.links
            links.forEach((obj: Link) => {
                if (!uniqueObjectsById.has(obj.id)) {
                    uniqueObjectsById.add(obj.id);
                    uniqueObjects.push(obj);
                }
            })
        });

        return [{
            links: uniqueObjects,
        }]
    }

    useEffect(() => {
        setIsLoading(true)
        let url;
        if (fetchAll) {
            url = `/link/list?siteType=${siteType}&per_page=1000`
                + `${debouncedSearchTerm ? `&website=${debouncedSearchTerm}` : ''}`
        } else {
            url = `/link/list/page?siteType=${siteType}`
                + `${selectedCategory ? `&categoryIds=${selectedCategory}` : ''}`
                + `${debouncedSearchTerm ? `&website=${debouncedSearchTerm}` : ''}`
        }
        Promise.all([
            apiService.api.get(url),
            categories.length == 0 ? apiService.api.get(`/category/list?per_page=50`) : undefined
        ])
            .then(response => {
                let data = response[0].data
                if (fetchAll) {
                    data = [{
                        links: shuffleArray(data),
                    }]
                }

                if (!hideCategories) {
                    setLinksPage(data);
                } else {
                    setLinksPage(flattenLinks(data))
                }

                if (getCategoryFromSearchParams() && !hasScrolledRef.current) {
                    setScrollToAnchor(selectedCategory)
                }

                analyticsService.sendCustomEvent(AnalyticsEventName.Search, {
                    'page': siteType,
                    'selectedCategory': categories.find(category => selectedCategory == category.id)?.name,
                    'searchTerm':debouncedSearchTerm,
                })

                return response.length > 1 && response[1]?.data
            })
            .then(data => {
                if (data) {
                    setCategories(data);
                }
            })
            .catch(() => {
                toast.error("אופס, נתקלנו בבעיה, נסו שוב מאוחר יותר")
            })
            .finally(() => setIsLoading(false));
    }, [selectedCategory, debouncedSearchTerm])

    useEffect(() => {
        const {debouncedFunc, timerId} = debounce(() => setDebouncedSearchTerm(searchTerm), 500);
        debouncedFunc();
        return () => {
            const timer = timerId();
            if (timer) clearTimeout(timer);
        };
    }, [searchTerm]);

    const recommendedLinks = useMemo(() => {
        return <RecommendedLinks className="mt-5 mb-5" lineColor={lineColor} linkBG={linkBG} siteType={siteType}
                                 allowProvideContent={allowProvideContent}/>
    }, [])


    return (
        <div className="position-relative">

            <div className={classes.bg}>
                <div className="float-start w-100" style={{
                    height: "1000px",
                    background: `radial-gradient(circle at -5% 300px in hsl shorter hue, ${lineColor}8f 0%, rgba(255, 255, 255, 0) 400px)`
                }}></div>
                <div className="float-start w-100" style={{
                    height: "800px",
                    background: `radial-gradient(circle at 115% 400px in hsl shorter hue, ${lineColor}8f 0%, rgba(255, 255, 255, 0) 400px)`
                }}></div>
                <div className={`${classes.doubleGradient} float-start w-100`} style={{
                    height: "1200px",
                    background: `radial-gradient(circle at 105% 600px in hsl shorter hue, #cccbff 0%, rgba(255, 255, 255, 0) 300px)`
                }}></div>
                <div className="float-start w-100" style={{
                    height: "1000px",
                    background: `radial-gradient(circle at 90% 500px in hsl shorter hue, #cccbff 0%, rgba(255, 255, 255, 0) 350px)`
                }}></div>
            </div>
            <div className={classes.bg}>
                <div className={`float-start w-100`} style={{
                    height: "2300px",
                    background: `radial-gradient(circle at -15% 78% in hsl shorter hue, #cccbff 0%, rgba(255, 255, 255, 0) 22%)`
                }}></div>
                <div className="float-start w-100" style={{
                    height: "800px",
                    background: `radial-gradient(circle at -10% 50% in hsl shorter hue, ${lineColor}8f 0%, rgba(255, 255, 255, 0) 25%)`
                }}></div>
            </div>
            <Container className="pb-4 pt-5 text-center" style={{height: "auto",}}>
                <Row className="justify-content-md-center">
                    <Col sm={12} className="pe-md-0">
                        <img src={subtitleImage} alt="Subtitle imagery" style={{paddingLeft: 0}}/>
                    </Col>
                    <Col xl={5}>
                        <Typography variant="h3" style={{marginTop: "10px"}}>{header.title}</Typography>
                    </Col>
                </Row>
                <Row xl={5} className="justify-content-md-center">
                    <Col xl={5}>
                        <Typography variant="body1" style={{marginTop: "10px"}}>{header.subtitle}</Typography>
                    </Col>
                </Row>
            </Container>

            {recommendedLinks}

            <Container className="mb-5" style={{height: "auto"}}>
                <Row className="mb-3">
                    <Col xs={"auto"} className="pe-md-0">
                        <img src={subtitleImage} alt="Subtitle imagery" style={{paddingLeft: 0}}/>
                    </Col>
                    <Col xs={"auto"} className="align-self-center">
                        <Typography variant="h4">{subtitle}</Typography>
                        <BottomBorder width={69} color={lineColor} addMargin={false}></BottomBorder>
                    </Col>
                </Row>

                {
                    // isLoggedIn && - allow everyone to filter for now
                    <Row md={10}>
                    <Col md={7} className="mb-2 mb-md-0">
                        <InputGroup>
                            <InputGroup.Text className={classes.gridInputImage}>
                                <img src={magnify}/>
                            </InputGroup.Text>
                            <Form.Control
                                className={classes.gridInput}
                                type="text"
                                placeholder="חפש לפי"
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value ?? "")}
                            />
                        </InputGroup>
                    </Col>

                    {/*<Col md={2}>*/}
                    {/*    <Form.Control as="select"*/}
                    {/*                  className={classes.gridInput + ' mb-2 mb-md-0'}*/}
                    {/*                  value={selectedCategory ?? ""}*/}
                    {/*                  onChange={e => setSelectedCategory(e.target.value as string)}>*/}
                    {/*        <option value="">המומלצים ביותר</option>*/}
                    {/*        {categories.map(category => (*/}
                    {/*            <option key={category.id} value={category.id}>{category.name}</option>*/}
                    {/*        ))}*/}
                    {/*    </Form.Control>*/}
                    {/*</Col>*/}

                    <Col md={2}>
                        <Form.Control as="select"
                                      className={classes.gridInput + ' mb-2 mb-md-0'}
                                      value={selectedCategory ?? ""}
                                      onChange={e => setSelectedCategory(e.target.value as string)}>
                            <option value="">כל הקטגוריות</option>
                            {categories.map(category => (
                                <option key={category.id} value={category.id}>{category.name}</option>
                            ))}
                        </Form.Control>
                    </Col>
                </Row>}
            </Container>


            {isLoading ? (
                    <Container style={{marginBottom: '70px'}} className={'px-5 px-md-0'}>
                        <Row style={{marginBottom: '20px'}}>
                            <Skeleton variant="rounded" width={150}
                                      height={80}/>
                        </Row>
                        <Row>
                            <SkeletonLoader amount={2}/>
                        </Row>
                        <Row style={{marginBottom: '20px', marginTop: '60px'}}>
                            <Skeleton variant="rounded" width={150}
                                      height={80}/>
                        </Row>
                        <Row>
                            <SkeletonLoader amount={1}/>
                        </Row>
                    </Container>
                )
                :
                (linksPage.length > 0 ? (!hideCategories ?
                        linksPage.map(category => {
                            const categoryLinks = category.links // filteredLinks.filter(link => link.category.id === category.id);
                            return (
                                categoryLinks.length > 0
                                && <React.Fragment key={category.id}>
                                    <Container id={category.id} style={{marginBottom: '70px'}}
                                               className={'px-5 px-md-0'}>
                                        <Row style={{marginBottom: '20px'}}>
                                            <div className="d-flex align-items-center">
                                                {category.thumbnail &&
                                                    <img src={category.thumbnail} className="me-2"
                                                         style={{height: "40px"}}/>}
                                                <Typography className="d-inline-block lh-1" variant='h2'
                                                            style={{fontSize: "36px"}}>{category.name}</Typography>
                                            </div>
                                            <BottomBorder width={69} color={lineColor}
                                                          style={{marginTop: "6px"}}></BottomBorder>
                                        </Row>
                                        <Row>
                                            {categoryLinks.map((link, idx) => (
                                                <Col key={idx} md={6} lg={4} xxl={3} className={'mb-4'}>
                                                    <LinkItem
                                                        {...link}
                                                        backgroundImage={linkBG}
                                                        allowProvideContent={allowProvideContent}
                                                    />
                                                </Col>
                                            ))}
                                        </Row>
                                    </Container>
                                </React.Fragment>
                            );
                        })
                        :
                        <React.Fragment>
                            <Container style={{marginBottom: '70px'}} className={'px-5 px-md-0'}>
                                <Row>
                                    {linksPage.map(x => x.links.map((link, idx) => {
                                        return (
                                            <Col key={idx} md={6} lg={4} xxl={3} className={'mb-4'}>
                                                <LinkItem
                                                    {...link}
                                                    backgroundImage={linkBG}
                                                    allowProvideContent={allowProvideContent}
                                                />
                                            </Col>
                                        );
                                    }))}
                                </Row>
                            </Container>
                        </React.Fragment>
                ) : (

                    <React.Fragment>
                        <Container style={{marginBottom: '70px'}} className={'px-5 px-md-0'}>
                            <Row>
                                <Col>
                                    <Typography fontSize={20}>
                                        לא מצאנו את מה שביקשת, נסה קטגוריה או מילת חיפוש אחרת
                                    </Typography>
                                </Col>
                            </Row>
                        </Container>
                    </React.Fragment>))}

        </div>
    );
};

export default LinkBase;