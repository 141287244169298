import React, {useEffect} from 'react';
import {CircularProgress} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import {useApiService} from "../context/ApiServiceContext";
import Spinner from "../views/Spinner";
import AnalyticsService from "../services/AnalyticsService";

const OrderProcessing = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const apiService = useApiService();
    const searchParams = new URLSearchParams(location.search);
    const docUrl = searchParams.get('doc_url');
    const orderId = searchParams.get('orderId');
    const cartValue = searchParams.get('cartValue');
    const cp = searchParams.get('cp');

    const analyticsService = AnalyticsService.getInstance();

    useEffect(() => {
        apiService.api.post(`/cart/checkout/complete`, {
            docUrl,
            id: orderId,
            cp: Number(cp)
        }).then(response => {
            // Redirect to profile/orders upon successful order completion
            navigate('/?showOrders=open');
        }).catch(error => {
            // Handle error here
        });

        (window as any)?.dataLayer?.push({
            'event': 'cart-checkout-complete',
            'transaction_id': orderId,
            'value': cartValue,
            'currency': 'ILS'
        });
    }, [docUrl, orderId, cp, navigate]);

    return (
        <Container className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center">
                <Spinner className="mb-2"/>
                <p>מעבד את ההזמנה שלך, זה ייקח כמה רגעים...</p>
            </div>
        </Container>
    );
};

export default OrderProcessing;
