import React from 'react';
import LinkBase from "./LinkBase";
import regoArrow from  "../../assets/images/rego-arrow.png"
import bostBG from "../../assets/images/link/boost-bg.png";
import {SiteType} from "../../models/Link";

const LinkBoost: React.FC = () => {
    return <LinkBase
        header={{
            title: "קישורי ReGo Boost ",
            subtitle: "עם השנים טיפחנו ותחזקנו עשרות אתרי נישה ומגוון מגזינים ברחבי האינטרנט, ועכשיו - הם מחכים רק לכם. הקישורים המעולים מבית ReGo יעזרו לכם להגדיל את התנועה האורגנית מגוגל ובינינו, לפעמים בוסט קטן זה כל מה שצריך.",
        }}
        lineColor="#837EFF"
        subtitle="קישורי ReGo Boost"
        subtitleImage={regoArrow}
        linkBG={bostBG}
        siteType={SiteType.BOOST}
    />;
};

export default LinkBoost;