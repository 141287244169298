import React, {useState} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {addProductToCart, clearCartAction, finishPurchase} from "../utils/cartSlice";
import {AppDispatch} from "../utils/store";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, Typography} from "@mui/material";
import {ShoppingCart as ShoppingCartIcon} from "@material-ui/icons";
import {makeStyles} from '@material-ui/core';
import Link from "../models/Link";
import {getRandomNumber, prettifyNumber, trimToHostname} from "../utils/utils";
import {selectIsLoggedIn} from "../utils/userSlice";
import {Modals, showModal} from "../utils/modalSlice";

const useStyles = makeStyles((theme) => ({
    buttonWithIcon: {
        '& .MuiButton-startIcon': {
            marginRight: '-6px',
            marginLeft: '2px',
        },
        color: 'black',
    },
    card: {
        width: '100%',
        height: '370px',
        borderRadius: '10px',
        border: '0',
        boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.12)',
        // cursor: 'pointer',
    },
    btnCustom: {

        [theme.breakpoints.down('sm')]: {
            height: "40px",
            marginBottom: "8px!important"
        }
    }
}));

interface LinkProps extends Link {
    backgroundImage?: string;
    allowProvideContent?: boolean;
}

const LinkItem: React.FC<LinkProps> = ({
                                           backgroundImage,
                                           allowProvideContent = false,
                                           ...link
                                       }) => {
    const classes = useStyles();
    const dispatch: AppDispatch = useDispatch();
    const [adjustedPrice, setAdjustedPrice] = useState(link.price);
    const [selectedContentValue, setSelectedContentValue] = useState<number>(500);
    const [radioSelectedContentValue, setRadioSelectedContentValue] = useState<string>('0');

    const isLoggedIn = useSelector(selectIsLoggedIn);

    function getProductForCart() {
        const productToAdd: Link & { wordCount?: number; usersContent?: boolean; } = {...link};

        if (radioSelectedContentValue !== '1') {
            productToAdd.price = adjustedPrice;
        }

        if (allowProvideContent && radioSelectedContentValue !== '1') {
            productToAdd.wordCount = selectedContentValue;
        }

        productToAdd.usersContent = allowProvideContent && radioSelectedContentValue == '1'

        return productToAdd;
    }

    const handleAddProductToCart = () => {
        if (isLoggedIn) {
            dispatch(addProductToCart(getProductForCart()))
        } else {
            dispatch(showModal(Modals.LOGIN))
        }
    }

    const handleBuyNow = async () => {
        if (isLoggedIn) {
            dispatch(clearCartAction())
            await dispatch(addProductToCart(getProductForCart(), false))
            dispatch(finishPurchase(null))
        } else {
            dispatch(showModal(Modals.LOGIN))
        }
    }

    const handlePriceAdjustment = (radioValue: string, wordCount: number) => {
        if (wordCount > 500 && radioValue !== "1") {
            const extraWords = wordCount - 500;
            const steps = extraWords / 100;
            setAdjustedPrice(link.price + steps * 30);
        } else {
            setAdjustedPrice(link.price);
        }
    }

    const handlePriceChange = (radioValue: string, wordCount: number) => {
        setSelectedContentValue(wordCount);
        if (allowProvideContent) {
            handlePriceAdjustment(radioValue, wordCount);
        }
    };

    function setUserContentRadio(value: string) {
        setRadioSelectedContentValue(value)
        handlePriceChange(value, selectedContentValue)
    }


    const radioName = `radioOptions-${link.id}-${getRandomNumber(100, 999)}`

    return (
        <div key={link.id}>
            <Card className={classes.card} style={{
                background: `url(${backgroundImage}) no-repeat left bottom`,
                backgroundSize: 'contain',
                backgroundColor: 'white'
            }}>
                <Card.Body className={"d-flex flex-column justify-content-between"} style={{padding: "24px 16px"}}>
                    <div>
                        <Row>
                            <Col xs={6}>
                                <img className="object-fit-contain" style={{
                                    objectPosition: "right",
                                    visibility: link.thumbnail ? 'visible' : 'hidden'
                                }}
                                     src={link.thumbnail} height='50px' width="100%"/>
                            </Col>
                            <Col xs={6}>
                                {link.exampleArticleUrl &&
                                    <Button variant={"contained"}
                                            className='simple-button small white'
                                            style={{marginLeft: "10px"}}
                                            onClick={() => window.open(link.exampleArticleUrl, '_blank')}>
                                        צפייה בדוגמה
                                    </Button>}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <a style={{
                                    fontSize: "12px",
                                    color: "#45A0F8",
                                    textDecorationLine: "underline",
                                    whiteSpace: "nowrap"
                                }} href={link.website} target="_blank">{`${trimToHostname(link.website)}`}</a>
                                {/*<Typography className='mb-1' style={{}}>*/}
                                {/*    תנועה אורגנית {link.metadata?.traffic ?? 0}*/}
                                {/*</Typography>*/}
                            </Col>
                            <Col xs={6} style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "left"
                            }}>
                                <Typography style={{
                                    fontWeight: 600,
                                }}>
                                    {`DR ${link.metadata?.dr ?? 0} | RD ${prettifyNumber(link.metadata?.rd ?? 0)}`}
                                </Typography>
                            </Col>
                        </Row>
                        {allowProvideContent ? <div>
                                <Row className="mb-1" style={{marginTop: "30px"}}>
                                    <Col xs={"auto"}>
                                        <Form.Check
                                            type="radio"
                                            name={radioName}
                                            value="0"
                                            onChange={(e) => setUserContentRadio(e.target.value)}
                                            label="תכתבו לי"
                                            defaultChecked
                                        />
                                    </Col>
                                    <Col xs={"auto"}>
                                        <Form.Check
                                            type="radio"
                                            name={radioName}
                                            value="1"
                                            onChange={(e) => setUserContentRadio(e.target.value)}
                                            label="יש לי תוכן"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col xs={5}>
                                        <Form.Select
                                            aria-label="מספר מילים"
                                            onChange={(e) => handlePriceChange(
                                                "0",
                                                parseInt(e.target.value, 10)
                                            )}
                                            disabled={radioSelectedContentValue === "1"}
                                        >
                                            {Array.from({length: 6}, (_, i) => 500 + i * 100).map(wordCount => (
                                                <option key={wordCount}
                                                        value={wordCount}>{wordCount.toLocaleString('en-US')}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </div> :

                            <Row className="mb-1" style={{marginTop: "10px"}}>
                                <Col xs={"auto"}>
                                    <Typography variant="body1">
                                        {link.info.description}
                                    </Typography>
                                    {(link.info.bullets?.length ?? 0) > 0 && <ul>
                                        {link.info.bullets?.map((b, index) =>
                                            <li key={`bullet-${index}`}>{b}</li>
                                        )}
                                    </ul>}
                                </Col>
                            </Row>}
                    </div>
                    <div>
                        <Box className="mb-2">
                            <Typography variant="h5">{adjustedPrice.toLocaleString('en-US')}₪</Typography>
                        </Box>

                        <Button color="secondary" variant={"contained"}
                                className={`simple-button blue ${classes.btnCustom}`}
                                style={{marginLeft: "10px"}}
                                onClick={handleBuyNow}>
                            קנה עכשיו
                        </Button>
                        <Button color="primary" variant={"outlined"}
                                className={`${classes.buttonWithIcon} simple-button white ${classes.btnCustom}`}
                                startIcon={<ShoppingCartIcon/>}
                                onClick={handleAddProductToCart}>
                            הוסף לסל
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
};

export default LinkItem;