import { useEffect, RefObject } from 'react';

const useInfiniteScroll = (ref: RefObject<HTMLDivElement>, callback: () => void, deps: any[]) => {
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                callback();
            }
        }, { threshold: 1.0 });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref, callback, ...deps]);
};

export default useInfiniteScroll;
