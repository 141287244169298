import React, {useState} from 'react';
import {Form, Modal} from 'react-bootstrap';
import {login} from "../../utils/userSlice";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../utils/store";
import {Modals, showModal} from "../../utils/modalSlice";
import {Button} from "@mui/material";

interface LoginModalProps {
    show: boolean;
    handleClose: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({show, handleClose}) => {
    const dispatch: AppDispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const success = await dispatch(await login(email, password))

        if (success) {
            handleClose()
        }
    };

    const handleShowRegister = () => {
        dispatch(showModal(Modals.REGISTER))
    };

    const handleForgotPassword = () => {
        dispatch(showModal(Modals.FORGOT_PASSWORD))
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>התחברות</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="formEmail">
                    <Form.Label>אימייל</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="אימייל"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="formPassword">
                    <Form.Label>סיסמא</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="סיסמא"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <Button className="text-black" onClick={handleForgotPassword}>שכחתי סיסמא</Button>
                <div className="m-0">
                    <Button variant="text" className="text blue w-auto p-3"
                            onClick={handleShowRegister}>
                        אין לך חשבון? הרשם כאן
                    </Button>
                    <Button className="simple-button medium blue w-auto p-3"
                            onClick={handleSubmit}>
                        התחבר
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default LoginModal;