import React, {useState} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import {addProductToCart} from "../../utils/cartSlice";
import {AppDispatch} from "../../utils/store";
import {useDispatch, useSelector} from "react-redux";
import {Button, Typography} from "@mui/material";
import {makeStyles} from '@material-ui/core';
import Link from "../../models/Link";
import {prettifyNumber, trimToHostname} from "../../utils/utils";
import {selectIsLoggedIn} from "../../utils/userSlice";
import {Modals, showModal} from "../../utils/modalSlice";
import {styled} from "@mui/material/styles";
import Slider from "react-slick";
import {useNavigate} from "react-router-dom";
import category from "../../models/Category";

const useStyles = makeStyles({
    buttonWithIcon: {
        '& .MuiButton-startIcon': {
            marginRight: '-6px',
            marginLeft: '2px',
        },
        color: 'black'
    },
    card: {
        width: '100%',
        height: '390px',
        borderRadius: '10px',
        border: '0',
        boxShadow: '0px 13px 11px -5px rgba(163, 163, 163, 0.47)',
        cursor: 'pointer'
    }
});


const ThumbnailImg = styled('img')(({theme}) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    width: '100%',
    height: '150px',
    objectFit: 'contain',
    objectPosition: 'top center'
}));

interface LinkProps extends Link {
    backgroundImage?: string;
    allowProvideContent?: boolean;
}

const LinkItem: React.FC<LinkProps> = ({
                                           backgroundImage,
                                           allowProvideContent = false,
                                           ...link
                                       }) => {
    const classes = useStyles();
    const navigate = useNavigate()

    function handleWatchCategory() {
        navigate(`${link.siteType}?category=${link.category.id}`, {replace: true})
        window.scrollTo({top: 0, left: 0});
    }

    return (
        <div key={link.id}>
            <Card className={classes.card} style={{
                background: `url(${backgroundImage}) no-repeat left bottom`,
                backgroundSize: 'contain',
                backgroundColor: 'white',
                overflow: 'hidden'
            }}>
                <Card.Body className={"d-flex flex-column justify-content-between"} style={{padding: "24px 16px"}}>
                    <div>
                        <ThumbnailImg src={link.mainImg} hidden={!link.mainImg}/>
                        <Row style={{
                            marginTop: '130px'
                        }}>
                            <Col xs={8}>
                                <Typography variant="h6" className="fw-bold lh-1">{link.info?.title}</Typography>
                                <a style={{
                                    fontSize: "12px",
                                    textDecoration: "none",
                                    color: "black"
                                }} href={link.website} target="_blank">{`${trimToHostname(link.website)}`}</a>

                                {/*<Typography variant="subtitle2" className='mb-1 lh-1'>*/}
                                {/*    תנועה אורגנית {link.metadata?.traffic ?? 0}*/}
                                {/*</Typography>*/}
                            </Col>
                            <Col xs={4} className="text-end ps-0">
                                <span style={{
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    marginRight: '8px'
                                }}>
                                    {`DR ${link.metadata?.dr ?? 0}`}
                                </span>
                                <span style={{
                                    fontWeight: 500,
                                    fontSize: '14px'
                                }}>
                                    {`RD ${prettifyNumber(link.metadata?.rd ?? 0)}`}
                                </span>
                            </Col>
                        </Row>
                        {(link.info.bullets?.length ?? 0) > 0 && <ul className="mt-1">
                            {link.info.bullets?.map((b, index) =>
                                <li key={`bullet-${index}`}>{b}</li>
                            )}
                        </ul>}
                    </div>
                    <div>
                        <Button color="secondary" variant={"contained"}
                                className='simple-button medium long blue'
                                onClick={handleWatchCategory}>
                            לצפייה בקטגוריה
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
        ;
};

export default LinkItem;