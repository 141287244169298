import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, Modal, Row, Table} from 'react-bootstrap';
import {toast} from "react-toastify";
import ConfirmationModal from "../../utils/ConfirmationModal";
import * as yup from 'yup';
import {useFormik} from 'formik';
import {useApiService} from "../../context/ApiServiceContext";
import Category from "../../models/Category";
import {CircularProgress} from "@mui/material";


const AdminCategories: React.FC = () => {
    const apiService = useApiService();
    const [categories, setCategories] = useState<Category[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [categoryName, setCategoryName] = useState('');
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [isFileUploading, setFileUploading] = useState<boolean>(false);

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const linksPerPage = 20; // Adjust as needed
    /* End of Pagination */

    const fetchCategories = useCallback(async (page = 1) => {
        try {
            const res = await apiService.api.get(`/category/list?page=${page}&per_page=${linksPerPage}`);
            if (Array.isArray(res.data)) {
                setCategories(res.data);
                setHasMorePages(res.data.length === linksPerPage);
            }
        } catch (error: any) {
            toast.error(`Error fetching categories: ${error.message}`);
        }
    }, [linksPerPage]);

    useEffect(() => {
        fetchCategories(currentPage);
    }, [currentPage, fetchCategories]);

    const apiCall = useCallback(async (apiFunc: () => Promise<any>, successMessage: string, errorMessage: string) => {
        try {
            const res = await apiFunc();
            toast.success(successMessage);
            return res.data;
        } catch (error) {
            toast.error(`${errorMessage}`);
        }
    }, []);

    const handleDeleteConfirmation = useCallback(() => {
        if (selectedCategory) {
            const categoryId = selectedCategory.id
            apiCall(
                () => apiService.api.delete(`/category/${categoryId}`),
                'הקטגוריה נמחקה בהצלחה',
                'תקלה בעת מחיקת קטגוריה'
            ).then(() => {
                setCategories(prevCategories => prevCategories.filter(category => category.id !== categoryId));
                setSelectedCategory(null)
                setDeleteModalShow(false)
            });
        }
        setSelectedCategory(null)
    }, [selectedCategory, apiCall]);

    const handleUpdate = useCallback((updatedCategoryName: string, thumbnail?: string) => {
        if (selectedCategory) {
            apiCall(
                () => apiService.api.put(`/category/${selectedCategory.id}`, {
                    name: updatedCategoryName,
                    thumbnail
                }),
                'הקטגוריה עודכנה בהצלחה',
                'תקלה בעת עדכון קטגוריה'
            ).then(updatedCategory => {
                setCategories(prevCategories =>
                    prevCategories.map(category =>
                        category.id === updatedCategory.id ? updatedCategory : category
                    )
                );
            });
            setShowModal(false);
        }
    }, [selectedCategory, apiCall]);

    const handleCreate = useCallback((updatedCategoryName: string, thumbnail?: string) => {
        apiCall(
            () => apiService.api.post('/category/', {
                name: updatedCategoryName,
                thumbnail
            }),
            'הקטגוריה נוספה בהצלחה',
            'תקלה בעת הוספת קטגוריה'
        ).then(newCategory => {
            setCategories(prevCategories => [...prevCategories, newCategory]);
        });
        setShowModal(false);
    }, [categoryName, apiCall]);

    const openModal = (category: Category | null) => {
        setSelectedCategory(category);
        // setCategoryName(category ? category.name : '');
        formik.resetForm({
            values: {
                categoryName: category ? category.name : '',
                thumbnail: category ? category.thumbnail || '' : '',
            }
        });

        setShowModal(true);
    };

    const openDeleteModal = (category: Category) => {
        setSelectedCategory(category);
        setDeleteModalShow(true);
    };

    const categorySchema = yup.object().shape({
        categoryName: yup.string()
            .required('שם הקטגוריה נדרש') // Your required message
            .min(2, 'שם הקטגוריה קצר מדי') // Minimum length validation message
            .max(50, 'שם הקטגוריה ארוך מדי'), // Maximum length validation message
        thumbnail: yup.string().url('כתובת תמונה לא חוקית'),
    });

    const formik = useFormik({
        initialValues: {
            categoryName: '',
            thumbnail: ''
        },
        validationSchema: categorySchema,
        onSubmit: values => {
            if (formik.isValid) {
                // Handle form submission
                selectedCategory ? handleUpdate(values.categoryName, values.thumbnail) : handleCreate(values.categoryName, values.thumbnail);
            }
        },
        validateOnBlur: true, // Validation on blur instead of on change
        validateOnMount: true,
    });

    const handleFileChange = (files: File[]) => {
        if (files && files.length > 0) {
            setFileUploading(true)

            const formData = new FormData();
            files.forEach((file, index) => {
                formData.append(`file${index}`, file);
            });

            apiService.api.post(`/category/thumbnail/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    formik.setFieldValue('thumbnail', response.data.urls[0]);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setFileUploading(false)
                });
        }
    };

    const PaginationBar = () => {
        return (
            <div className="d-flex justify-content-center my-3">
                <Button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="me-2"
                >
                    הקודם
                </Button>
                <Button
                    disabled={!hasMorePages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    הבא
                </Button>
            </div>
        );
    };

    return (
        <>
            <Row className="justify-content-center py-md-5">
                <Col md={5}>
                    <h1 className="mb-2">קטגוריות</h1>
                </Col>
                <Col md={5} className="d-flex justify-content-end align-items-center">
                    <Button onClick={() => openModal(null)}>הוספת קטגוריה</Button>
                </Col>
                <Col md={10}>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>תמונה</th>
                            <th>שם</th>
                            <th>תאריך יצירה</th>
                            <th>פעולות</th>
                        </tr>
                        </thead>
                        <tbody>
                        {categories.map((category) => (
                            <tr key={category.id}>
                                <td className="text-center"><img src={category.thumbnail} height={38}/></td>
                                <td>{category.name}</td>
                                <td>{new Date(category.createdAt).toLocaleString('he-IL', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}</td>
                                <td>
                                    <Button className="me-2" onClick={() => openModal(category)}>
                                        עריכה
                                    </Button>
                                    <Button variant="danger" onClick={() => openDeleteModal(category)}>
                                        מחיקה
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <PaginationBar/>

                </Col>
            </Row>


            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedCategory ? 'עריכת קטגוריה' : 'הוספת קטגוריה'}
                    </Modal.Title>
                </Modal.Header>
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Control
                                type="text"
                                name="categoryName"
                                value={formik.values.categoryName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.categoryName && !!formik.errors.categoryName}
                                placeholder="הכנס את שם הקטגוריה"
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.categoryName}
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group>
                            <Form.Label>תמונה
                                {formik.values.thumbnail && formik.values.thumbnail.trim() !== "" &&
                                    <>
                                        {" ("}
                                        <a href={formik.values.thumbnail} target="_blank">פתח בחלון חדש</a>
                                        {")"}
                                    </>
                                }
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="thumbnail"
                                placeholder="הכנס תמונה ממוזערת"
                                value={formik.values.thumbnail}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.thumbnail && !!formik.errors.thumbnail}
                                disabled
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.thumbnail}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId={`file`}>
                            <Form.Label>
                                <div>
                                    <span>העלאת קובץ לתמונה</span>
                                    <CircularProgress className="ms-3" color="secondary"
                                                      size={13}
                                                      hidden={!isFileUploading}/>
                                </div>
                            </Form.Label>
                            <Form.Control
                                type="file"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleFileChange(e.target.files ? Array.from(e.target.files) : [])}
                                accept=".png,.jpg,.jpeg,.webp"
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            סגור
                        </Button>
                        <Button type="submit" variant="primary" disabled={isFileUploading}>
                            שמור
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <ConfirmationModal
                show={deleteModalShow}
                onClose={() => setDeleteModalShow(false)}
                onConfirm={handleDeleteConfirmation}
                title="אישור מחיקה"
                message="האם אתה בטוח שאתה רוצה למחוק את הקטגוריה?"
            />
        </>
    );
};

export default AdminCategories;
