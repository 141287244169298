import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, InputGroup, Modal, Row, Table} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {AxiosResponse} from "axios";
import {useApiService} from "../../context/ApiServiceContext";
import * as yup from 'yup';
import {useFormik} from "formik";
import Testimonial from "../../models/Testimonial";

interface TestimonialRequestBody {
    name: string;
    company: string;
    title: string;
    description: string;
    thumbnailUrl: string;
}

const AdminTestimonials: React.FC = () => {
    const apiService = useApiService();
    const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
    const [selectedTestimonial, setSelectedTestimonial] = useState<Testimonial | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [testimonialFields, setTestimonialFields] = useState<TestimonialRequestBody>({
        name: '',
        company: '',
        title: '',
        description: '',
        thumbnailUrl: '',
    });
    const [isFileUploading, setFileUploading] = useState<boolean>(false);
    const [searchName, setSearchName] = useState('');

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const testimonialsPerPage = 20; // Adjust as needed
    /* End of Pagination */

    useEffect(() => {
        fetchTestimonials(currentPage, searchName);
    }, [currentPage]);

    const fetchTestimonials = useCallback(async (page = 1, name: string) => {
        try {
            const url = `/testimonial/list?page=${page}&per_page=${testimonialsPerPage}${name.trim() !== '' ? `&name=${name}` : ''}`;
            const res = await apiService.api.get(url);
            setTestimonials(prevTestimonials => [...prevTestimonials, ...res.data]);
            setHasMorePages(res.data.length === testimonialsPerPage);
        } catch (error) {
            // Handle error
        }
    }, [apiService, testimonialsPerPage]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchName(e.target.value);
    };

    const handleSearch = (e?: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        setTestimonials([]);
        fetchTestimonials(1, searchName);
    };

    const handleDelete = (testimonialId: string) => {
        if (window.confirm('האם אתה בטוח שברצונך למחוק את ההמלצה?')) {
            apiService.api
                .delete(`/testimonial/${testimonialId}`)
                .then(() => {
                    setTestimonials(testimonials.filter((testimonial) => testimonial.id !== testimonialId));
                    toast.success('ההמלצה נמחקה בהצלחה');
                })
                .catch(() => {
                    toast.error('בעיה במחיקת ההמלצה');
                });
        }
    };

    const handleUpdate = (values: any) => {
        if (selectedTestimonial) {
            const updatedTestimonial: TestimonialRequestBody = {
                ...values
            };

            apiService.api
                .put(`/testimonial/${selectedTestimonial.id}`, updatedTestimonial)
                .then((res: AxiosResponse<Testimonial>) => {
                    setTestimonials(
                        testimonials.map((testimonial) => (testimonial.id === selectedTestimonial.id ? res.data : testimonial))
                    );
                    toast.success('ההמלצה עודכנה בהצלחה');
                })
                .catch(() => {
                    toast.error('בעיה בעדכון ההמלצה');
                });

            setShowModal(false);
            formik.resetForm()
        }
    };

    const handleCreate = (values: any) => {
        const newTestimonial: TestimonialRequestBody = values;

        apiService.api
            .post('/testimonial/', newTestimonial)
            .then((res: AxiosResponse<Testimonial>) => {
                setTestimonials([res.data, ...testimonials]);
                toast.success('ההמלצה נוספה בהצלחה');
            })
            .catch(() => {
                toast.error('בעיה בהוספת ההמלצה');
            });

        setShowModal(false);
        formik.resetForm()
    };

    const openModal = (testimonial: Testimonial | null) => {
        setSelectedTestimonial(testimonial);
        setTestimonialFields({
            name: testimonial ? testimonial.name : '',
            company: testimonial ? testimonial.company : '',
            title: testimonial ? testimonial.title : '',
            description: testimonial ? testimonial.description : '',
            thumbnailUrl: testimonial ? testimonial.thumbnailUrl : '',
        });
        setShowModal(true);
    };

    const handleFileChange = (file: File | null) => {
        if (file) {
            setFileUploading(true);

            const formData = new FormData();
            formData.append('file', file);

            apiService.api.post(`/testimonial/thumbnail/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    formik.setFieldValue('thumbnailUrl', response.data.url);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setFileUploading(false);
                });
        }
    };

    const PaginationBar = () => {
        return (
            <div className="d-flex justify-content-center my-3">
                <Button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="me-2"
                >
                    הקודם
                </Button>
                <Button
                    disabled={!hasMorePages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    הבא
                </Button>
            </div>
        );
    };

    const testimonialSchema = yup.object().shape({
        name: yup.string().required('נדרש שם'),
        company: yup.string().required('נדרשת חברה'),
        title: yup.string().required('נדרשת כותרת'),
        description: yup.string().required('נדרש תיאור'),
        thumbnailUrl: yup.string().url('כתובת URL לתמונה לא חוקית'),
    });

    const formik = useFormik({
        initialValues: testimonialFields,
        validationSchema: testimonialSchema,
        onSubmit: values => {
            if (selectedTestimonial) {
                handleUpdate(values);
            } else {
                handleCreate(values);
            }
        },
        validateOnBlur: true,
        validateOnMount: true,
        enableReinitialize: true,
    });

    return (
        <Row className="justify-content-center py-md-5">
            <Col md={4}>
                <h1 className="mb-2">לקוחות מספרים</h1>
            </Col>
            <Col md={8} className="d-flex justify-content-end align-items-center">
                {/*<Form onSubmit={handleSearch} className="me-4 w-50">*/}
                {/*    <InputGroup>*/}
                {/*        <Form.Control*/}
                {/*            placeholder="Search by Name"*/}
                {/*            onChange={handleSearchChange}*/}
                {/*            value={searchName}*/}
                {/*        />*/}
                {/*        <Button variant="outline-secondary" onClick={handleSearch}>*/}
                {/*            חיפוש*/}
                {/*        </Button>*/}
                {/*    </InputGroup>*/}
                {/*</Form>*/}
                <Button onClick={() => openModal(null)}>הוסף המלצה</Button>
            </Col>
            <Col md={12}>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>שם</th>
                        <th>חברה</th>
                        <th>כותרת</th>
                        <th>תיאור</th>
                        <th>תמונה ממוזערת</th>
                        <th>נוצר בתאריך</th>
                        <th>עודכן בתאריך</th>
                        <th>פעולות</th>
                    </tr>
                    </thead>
                    <tbody>
                    {testimonials.map((testimonial, index) => (
                        <tr key={testimonial.id}>
                            <td>{index + 1}</td>
                            <td>{testimonial.name}</td>
                            <td>{testimonial.company}</td>
                            <td>{testimonial.title}</td>
                            <td>{testimonial.description}</td>
                            <td>
                                {testimonial.thumbnailUrl && (
                                    <img src={testimonial.thumbnailUrl} alt="Thumbnail" width={50}/>
                                )}
                            </td>
                            <td>{new Date(testimonial.createdAt).toLocaleDateString()}</td>
                            <td>
                                {testimonial.updatedAt &&
                                    new Date(testimonial.updatedAt).toLocaleDateString()}
                            </td>
                            <td>
                                <Button className="me-2" onClick={() => openModal(testimonial)}>
                                    ערוך
                                </Button>
                                <Button variant="danger" onClick={() => handleDelete(testimonial.id)}>
                                    מחק
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <PaginationBar/>
            </Col>

            <Modal show={showModal} onHide={() => {
                setShowModal(false);
                formik.resetForm();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedTestimonial ? 'עדכון' : 'הוספת'} המלצה</Modal.Title>
                </Modal.Header>
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>שם</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="הכנס שם"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.name && !!formik.errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.name}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>חברה</Form.Label>
                            <Form.Control
                                type="text"
                                name="company"
                                placeholder="הכנס חברה"
                                value={formik.values.company}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.company && !!formik.errors.company}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.company}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>כותרת</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                placeholder="כותרת ההמלצה"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.title && !!formik.errors.title}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.title}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>תיאור</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                placeholder="הכנס תיאור"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.description && !!formik.errors.description}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.description}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>כתובת URL לתמונה</Form.Label>
                            <Form.Control
                                type="text"
                                name="thumbnailUrl"
                                placeholder="הכנס כתובת URL לתמונה"
                                value={formik.values.thumbnailUrl}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.thumbnailUrl && !!formik.errors.thumbnailUrl}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.thumbnailUrl}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-3 mb-3" controlId={`file`}>
                            <Form.Label>העלה תמונה ממוזערת</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleFileChange(e.target.files ? e.target.files[0] : null)}
                                accept=".png,.jpg,.jpeg,.webp"
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            בטל
                        </Button>
                        <Button variant="primary" type="submit">
                            שמור
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Row>
    );
};

export default AdminTestimonials;
