// ShoppingCart.tsx
import React, {useEffect, useState} from 'react';
import {Form, Modal} from 'react-bootstrap';
import {AppDispatch, RootState} from "../utils/store";
import {useDispatch, useSelector} from "react-redux";
import SkeletonLoader from "./SkeletonLoader";
import {isoToFormattedDate} from "../utils/utils";
import {logout, setUser} from "../utils/userSlice";
import {hideModal, Modals, showModal} from "../utils/modalSlice";
import {Button} from "@mui/material";
import {toast} from "react-toastify";
import {useApiService} from "../context/ApiServiceContext";

interface Props {
    handleClose: () => void;
    open: boolean;
}


const ProfileModal: React.FC<Props> = ({handleClose, open}) => {

    const apiService = useApiService();
    const dispatch: AppDispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const user = useSelector((state: RootState) => state.user.user); // Assuming userSlice is attached as 'user' in your rootReducer
    const [validationStates, setValidationStates] = useState<{ [key: string]: boolean }>({});

    // State for the local copy of the user
    const [localUser, setLocalUser] = useState({
        name: '',
        phone: '',
        email: '',
        createdAt: '',
    });

    useEffect(() => {
        if (!open || user) return;

        setIsLoading(true);
        apiService.api.get('/user')
            .then(response => {
                dispatch(setUser(response.data));
                setIsLoading(false);
            });
    }, [open]);

    useEffect(() => {
        if (user) {
            setLocalUser({
                name: user.name ?? '',
                phone: user.phone ?? '',
                email: user.email ?? '',
                createdAt: user.createdAt ?? '',
            });
        }
    }, [user]);

    const handleInputChange = (field: string, value: string) => {
        if (localUser) {
            setLocalUser({
                ...localUser,
                [field]: value
            });
        }

        let isValid = false;
        if (field === "phone") {
            const pattern = /^0[2-9]\d{7,8}$/;
            isValid = pattern.test(value);
        } else {
            isValid = value.length >= 2
        }
        setValidationStates({
            ...validationStates,
            [field]: isValid
        });
    };

    const handleLogout = () => {
        dispatch(hideModal(Modals.PROFILE))
        dispatch(logout())
    }

    const handleUserUpdate = () => {
        apiService.api.put('/user', {
            name: localUser.name,
            phone: localUser.phone
        })
            .then(response => {
                dispatch(setUser(response.data));
                toast.success("פרטי המשתמש עודכנו בהצלחה")
            });
    }

    return (
        <>
            {isLoading ? (
                <SkeletonLoader/>
            ) : (<Modal show={open} onHide={handleClose} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title>פרטי משתמש</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>שם</Form.Label>
                            <Form.Control type="text" placeholder="הכנס שם" defaultValue={localUser?.name}
                                          required
                                          onChange={(e) => handleInputChange('name', e.target.value)}
                                          isInvalid={validationStates['name'] === false}/>
                            <Form.Control.Feedback type="invalid">
                                חובה למלא שם
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>טלפון</Form.Label>
                            <Form.Control type="tel" placeholder="הכנס מס' טלפון" style={{textAlign: "right"}}
                                          defaultValue={localUser?.phone}
                                          onChange={(e) => handleInputChange('phone', e.target.value)}
                                          isInvalid={validationStates['phone'] === false}/>
                            <Form.Control.Feedback type="invalid">
                                יש למלא טלפון תקין ללא רווחים. לדוגמא: 0547888566
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>אימייל</Form.Label>
                            <Form.Control type="email" placeholder="הכנס אימייל" style={{textAlign: "right"}}
                                          defaultValue={localUser?.email} disabled/>
                        </Form.Group>

                        <Form.Group className="mt-2 mb-3">
                            <Form.Label>זמן יצירה</Form.Label>
                            <Form.Control type="text" placeholder="זמן יצירה"
                                          value={isoToFormattedDate(localUser?.createdAt ?? '')} readOnly/>
                        </Form.Group>

                        <Button className="simple-button medium blue mb-2 me-2 w-auto p-3"
                                onClick={() => dispatch(showModal(Modals.ORDER_LIST))}>היסטוריית רכישות</Button>
                        <Button className="simple-button medium outlined-blue mb-2 w-auto p-3"
                                onClick={() => dispatch(showModal(Modals.OPEN_ORDER_LIST))}>הזמנות פתוחות</Button>
                    </Form>

                    <hr/>

                    <Button className="mb-2 me-2 text-black" onClick={handleLogout}>התנתק</Button>
                    <Button className="mb-2 me-2 text-black" onClick={handleUserUpdate}>עדכן פרטי משתמש</Button>
                </Modal.Body>
            </Modal>)}
        </>
    );
};

export default ProfileModal;
