// AppLayout.tsx
import React, {useEffect, useRef, useState} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import NavBar from "./NavBar";
import LinkRegoBoost from "./links/LinkBoost";
import LinkPremium from "./links/LinkPremium";
import LinkSuperRego from "./links/LinkSuperRego";
import OrderListModal from "./modals/OrderListModal";
import OrderDetailsModal from "./modals/OrderDetailsModal";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../utils/store";
import {hideAllModals, hideModal, Modals, showModal} from "../utils/modalSlice";
import Main from "./Main";
import {Modal} from "react-bootstrap";
import Videos from "./Videos";
import Legal from "./Legal";
import {Button, Typography} from "@mui/material";
import {setPaymentLink} from "../utils/cartSlice";
import Recommendations from "./Recommendations";
import LegalDocument from "./LegalDocument";
import Spinner from "../views/Spinner";
import Footer from "./Footer";
import DynamicPages from "./DynamicPages";
import HelperVideoModal from "./modals/HelperVideoModal";

const AppLayout: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const nav = useRef<HTMLDivElement>(null);

    const [orderId, setOrderId] = useState<string | null>(null);

    const isOrderListModalVisible = useSelector((state: RootState) => state.modals[Modals.ORDER_LIST]);
    const isOpenOrdersListModalVisible = useSelector((state: RootState) => state.modals[Modals.OPEN_ORDER_LIST]);
    const isOrderDetailsModalVisible = useSelector((state: RootState) => state.modals[Modals.ORDER_DETAILS]);
    const isNavigatingToPaymentModalVisible = useSelector((state: RootState) => state.modals[Modals.MOVING_TO_PAYMENT]);
    const isHelperVideoModalVisible = useSelector((state: RootState) => state.modals[Modals.HELPER_VIDEO]);

    const modalStates = useSelector((state: RootState) => state.modals);

    const paymentLink = useSelector((state: RootState) => state.cart.paymentLink);

    const ROUTER_PAGES = [
        {path: "", element: <Main/>},
        {path: "boost", element: <LinkRegoBoost/>},
        {path: "super", element: <LinkSuperRego/>},
        {path: "premium", element: <LinkPremium/>},
        {path: "videos", element: <Videos/>},
        {path: "legal", element: <Legal/>},
        {path: "legal/:legalPath", element: <LegalDocument/>},
        {path: "page/:pagePath", element: <DynamicPages/>},
        {path: "recommendations", element: <Recommendations/>},
        {path: "*", element: <Navigate to="/" replace/>}
    ]

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const showOrders = queryParams.get('showOrders');
        const showOrder = queryParams.get('showOrder');

        if (showOrders) {
            setOrderId(null);
            dispatch(showModal(showOrders === "open" ? Modals.OPEN_ORDER_LIST : Modals.ORDER_LIST));
        } else if (showOrder) {
            setOrderId(showOrder);
            dispatch(showModal(Modals.ORDER_DETAILS));
        } else {
            dispatch(hideAllModals());
        }
    }, [location.search, dispatch]);

    useEffect(() => {
        const modals = Object.values(modalStates)
        if (modals.length > 0 && modals.every(modalState => modalState === false)) {
            const currentPath = location.pathname;
            navigate(`${currentPath}`, {replace: true});
        }
    }, [modalStates, navigate]);

    useEffect(() => {
        if (isOrderDetailsModalVisible === false) {
            setOrderId(null)
        }
    }, [isOrderDetailsModalVisible]);

    const handleCloseModal = (fun: (show: boolean) => void) => () => {
        fun(false);
        // Additional logic for closing the modal can go here
    };

    return (
        <>
            <NavBar/>
            <main style={{minHeight: "calc(100vh - 92px)"}} role="main" id="main">
                <Routes>
                    {ROUTER_PAGES.map((route, index) =>
                        <Route key={index} {...route}/>
                    )}
                </Routes>
                {/* Conditionally render the OrderListModal */}
                {isOrderListModalVisible && (
                    <OrderListModal open={isOrderListModalVisible} openOrdersOnly={false}
                                    handleClose={() => dispatch(hideModal(Modals.ORDER_LIST))}/>
                )}
                {isOpenOrdersListModalVisible && (
                    <OrderListModal open={isOpenOrdersListModalVisible} openOrdersOnly={true}
                                    handleClose={() => dispatch(hideModal(Modals.OPEN_ORDER_LIST))}/>
                )}
                {isOrderDetailsModalVisible && (
                    <OrderDetailsModal orderId={orderId} open={isOrderDetailsModalVisible}
                                       handleClose={() => dispatch(hideModal(Modals.ORDER_DETAILS))}/>
                )}
                {isNavigatingToPaymentModalVisible && (
                    <Modal show={isNavigatingToPaymentModalVisible} onHide={() => {
                        dispatch(setPaymentLink(undefined))
                        dispatch(hideModal(Modals.MOVING_TO_PAYMENT))
                    }} centered>
                        <Modal.Header closeButton style={{border: 0}}>
                        </Modal.Header>
                        <Modal.Body className="fs-5 pt-0 px-4 pb-4">
                            <Typography variant="h5" className="mb-2">אז איך זה עובד?</Typography>
                            <Typography variant="subtitle1" className="mb-3">
                                <ul style={{listStyleType: "decimal"}}>
                                    <li>בוחרים קישור או חבילה</li>
                                    <li>משלמים</li>
                                    <li>ממלאים את הפרטים ב"הזמנות שלי" ( האנקור, אתר מקשר, URL לאנקור)</li>
                                    <li>לוחצים "שלח הזמנה"</li>
                                    <li>והקישור בדרך אליכם!</li>
                                </ul>
                            </Typography>
                            <Typography variant="h5" className="mb-1">אין לכם זמן למלא עכשיו את הפרטים?</Typography>
                            <Typography variant="subtitle1" className="mb-3">
                                <div>אין בעיה! הקישורים והחבילות יחכו לכם ב"הזמנות פתוחות"</div>
                                <Typography sx={{
                                    color: "#0d6efd",
                                    textDecoration: "underline",
                                    cursor: "pointer"
                                }} onClick={() => dispatch(showModal(Modals.HELPER_VIDEO))}>כל התהליך בסרטון
                                    ההדרכה</Typography>
                            </Typography>
                            <div className="text-center">
                                {!paymentLink && <Spinner size={53}/>}
                            </div>

                            <div className="text-center">
                                <Button color="secondary" variant={"contained"}
                                        className='simple-button long blue mt-3'
                                        disabled={!paymentLink}
                                        onClick={() => {
                                            dispatch(hideModal(Modals.MOVING_TO_PAYMENT))
                                            window.location.href = paymentLink!
                                            dispatch(setPaymentLink(undefined))
                                        }}>
                                    למעבר לתשלום
                                </Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
                {isHelperVideoModalVisible && (
                    <HelperVideoModal show={isHelperVideoModalVisible}
                                      handleClose={() => dispatch(hideModal(Modals.HELPER_VIDEO))}/>
                )}
            </main>
            <Footer/>
        </>
    );
};

export default AppLayout;
