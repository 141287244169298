// LinkGrid.tsx
import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {Col, Container, Form, InputGroup, Row} from 'react-bootstrap';
import right0BG from "../assets/images/bg/purple/right0.png"
import {Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useApiService} from "../context/ApiServiceContext";
import Video from "../models/Video";
import magnify from "../assets/images/search.svg";
import SkeletonLoader from "./SkeletonLoader";

const useStyles = makeStyles({
    card: {
        border: '1px solid white',
        borderRadius: 10,
        margin: 10,
    },
    formControl: {
        minWidth: 120,
        marginBottom: 20,
    },
    gridInputImage: {
        boxShadow: `8px 4px 10px 0px rgba(0, 0, 0, 0.12)`,
        border: 0,
        zIndex: 1
    },
    gridInput: {
        boxShadow: `4px 4px 10px 0px rgba(0, 0, 0, 0.12)`,
        border: 0
    },
    bg: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
    },
    cloudStart: {
        position: "absolute",
        right: 0,
        width: "100%",
        height: "calc(100% - 150px)",
        zIndex: -1,
    },
    cloudMiddleStart: {
        position: "absolute",
        top: "150vh",
        right: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
    },
    category: {
        borderRadius: "8px",
        border: "1px solid #EAEDF1",
        background: "#F0EFFA",
        color: "#251FB6",
        display: "inline-block",
        padding: "7px 15px",
        margin: "5px 0!important"
    },
    img: {}
});

const Root = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column'
}));

const Videos: React.FC = ({}) => {
    const classes = useStyles({});

    const [videos, setVideos] = useState<Video[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>(''); // Add this line for search term state
    const apiService = useApiService();

    useEffect(() => {
        fetchVideos(1, searchTerm)
    }, []); // Add searchTerm to the dependency array

    const fetchVideos = useCallback(async (page = 1, title: string) => {
        setIsLoading(true)
        // Update the API request to include searchTerm in the query
        let queryParams = `per_page=50&page=${page}`;
        if (title.trim() !== '') {
            queryParams += `&title=${encodeURIComponent(title.trim())}`;
        }
        apiService.api.get(`/video/list?${queryParams}`)
            .then(response => {
                setVideos(response.data);
                setIsLoading(false)
            });
    }, [apiService]);

    const handleSearch = (e?: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        setVideos([]);
        fetchVideos(1, searchTerm);
    };

    return (
        <Root>
            <div className={classes.bg}>
                <div className="float-start w-100" style={{
                    height: "1000px",
                    background: `radial-gradient(circle at -11% 500px in hsl shorter hue, #0060ff8f 0%, rgba(255, 255, 255, 0) 400px)`
                }}></div>
                <img className={classes.cloudStart} src={right0BG}/>
            </div>

            <Container className="mb-4 mt-5 text-center" style={{height: "auto",}}>
                <Row className="justify-content-md-center">
                    <Col xl={5}>
                        <Typography variant="h3" style={{marginTop: "10px"}}>סרטוני הדרכה ומידע</Typography>
                    </Col>
                </Row>
                {false && <Row xl={5} className="justify-content-md-center">
                    <Col xl={5}>
                        <Typography variant="body1" style={{marginTop: "10px"}}>לורם איפסום דולור סיט אמט, קונסקטורר
                            אדיפיסינג אלית קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף נולום ארווס סאפיאן -
                            פוסיליס קוויס, אקווזמן.</Typography>
                    </Col>
                </Row>}
            </Container>

            <Container className="mb-4 mt-5" style={{height: "auto",}}>
                <Row className="justify-content-md-center mb-5">
                    <Col sm={5} className="mb-md-0">
                        <Form onSubmit={handleSearch}>
                            <InputGroup>
                                <InputGroup.Text className={classes.gridInputImage}>
                                    <img src={magnify}/>
                                </InputGroup.Text>
                                <Form.Control
                                    className={classes.gridInput}
                                    type="text"
                                    placeholder="חפש לפי שם סרטון"
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value ?? "")}
                                />
                            </InputGroup>
                        </Form>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">

                    {isLoading ? (
                        <SkeletonLoader amount={2} marginTop={false}/>
                    ) : (
                        videos.length > 0 ? videos.map((video, index) => (
                                <Col xs={12} md={6} lg={3} key={index} className="mb-5">
                                    <div dir='rtl'>
                                        <div className={classes.img}>
                                            <iframe width="100%" height="100%"
                                                    src={`https://www.youtube.com/embed/${video.youtubeUrl.split("=")?.[1]}`}
                                                    title="YouTube video player" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen></iframe>
                                        </div>
                                        <Typography variant="body2" className={classes.category}>
                                            {video.category}
                                        </Typography>
                                        <div>
                                            <Typography variant="h6" className="fw-bold">
                                                {video.title}
                                            </Typography>
                                            <Typography variant="body1">
                                                {video.description}
                                            </Typography>
                                        </div>
                                    </div>
                                </Col>
                            )) :
                            <Typography variant="h6" className="fw-bold">
                                לא מצאנו סרטונים
                            </Typography>
                    )}
                </Row>
            </Container>
        </Root>
    );
};

export default Videos;