import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type ModalsState = {
    [modalName: string]: boolean;
};

const initialState: ModalsState = {};


export enum Modals {
    LOGIN,
    REGISTER,
    PROFILE,
    MOVING_TO_PAYMENT,
    ORDER_LIST,
    OPEN_ORDER_LIST,
    ORDER_DETAILS,
    CART,
    FORGOT_PASSWORD,
    HELPER_VIDEO
}

export const modalSlice = createSlice({
    name: 'modals',
    initialState, // Initial state is an empty object
    reducers: {
        // Toggle modal's visibility by its name
        toggleModal: (state, action: PayloadAction<Modals>) => {
            const name = action.payload;
            state[name] = !state[name];
        },
        // Explicitly show the modal by its name
        showModal: (state, action: PayloadAction<Modals>) => {
            Object.keys(state).forEach(modal => {
                state[modal] = false
            })

            const name = action.payload;
            state[name] = true;
        },
        // Explicitly hide the modal by its name
        hideModal: (state, action: PayloadAction<Modals>) => {
            const name = action.payload;
            state[name] = false;
        },
        // Explicitly hide the modal by its name
        hideAllModals: (state) => {
            Object.keys(state).forEach(modal => {
                state[modal] = false
            })
        }
    }
});

// Export the actions
export const {toggleModal, showModal, hideModal, hideAllModals} = modalSlice.actions;

// Export the reducer
export default modalSlice.reducer;
