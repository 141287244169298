import Category from "./Category";

interface Metadata {
    traffic: number;
    rd: number;
    dr: number;
}

export interface Link {
    id: string;
    website: string;
    category: Category;
    subCategories: Category[];
    price: number;
    powerSite: boolean;
    info: {
        title?: string;
        description?: string;
        bullets?: string[];
    };
    metadata?: Metadata;
    thumbnail?: string;
    mainImg?: string;
    siteType?: SiteType;
    createdAt: string;
    updatedAt: string;
    type: 'link';
    recommended: boolean;
    exampleSite: boolean;
    exampleArticleUrl?: string;
}

export enum SiteType {
    BOOST="boost",
    SUPER_REGO="super",
    PREMIUM="premium"
}

export default Link