import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, InputGroup, Modal, Row, Table} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {AxiosResponse} from "axios";
import {useApiService} from "../../context/ApiServiceContext";
import Link from "../../models/Link";
import * as yup from 'yup';
import {useFormik} from "formik";
import {CircularProgress} from "@mui/material";
import {OrderTask, OrderTaskStatus} from "../../models/Order";
import Category from "../../models/Category";

const AdminRecommended: React.FC = () => {
    const apiService = useApiService();
    const [links, setLinks] = useState<Link[]>([]);
    const [searchWebsite, setSearchWebsite] = useState('');

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const linksPerPage = 20; // Adjust as needed
    /* End of Pagination */

    const fetchLinks = useCallback(async (page = 1, website: string) => {
        try {
            const url = `/link/list?page=${page}&per_page=${linksPerPage}&recommended=true${website.trim() != '' ? `&website=${website}` : ''}`
            const res = await apiService.api.get(url);
            setLinks(prevLinks => [...prevLinks, ...res.data]);
            setHasMorePages(res.data.length === linksPerPage);
        } catch (error) {
            // Handle error
        }
    }, [apiService, linksPerPage]);

    useEffect(() => {
        fetchLinks(currentPage, searchWebsite);
    }, [currentPage, fetchLinks]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchWebsite(e.target.value);
    };

    const handleSearch = (e?: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        setLinks([])
        fetchLinks(1, searchWebsite);
    };

    const handleDelete = (linkId: string) => {
        if (window.confirm('האם אתה בטוח שברצונך להסיר את הקישור מהמומלצים?')) {
            apiService.api
                .put(`/link/${linkId}`, {recommended: false})
                .then(() => {
                    setLinks(links.filter((link) => link.id !== linkId));
                    toast.success('הקישור הוסר בהצלחה מהרשימה');
                })
                .catch(() => {
                    toast.error('תקלה בעת הסרת הקישור');
                });
        }
    };


    const PaginationBar = () => {
        return (
            <div className="d-flex justify-content-center my-3">
                <Button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="me-2"
                >
                    הקודם
                </Button>
                <Button
                    disabled={!hasMorePages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    הבא
                </Button>
            </div>
        );
    };


    return (
        <Row className="justify-content-center py-md-5">
            <Col md={4}>
                <h1 className="mb-2">ניהול מומלצים</h1>
            </Col>
            <Col md={8} className="d-flex justify-content-end align-items-center">
                <Form onSubmit={handleSearch} className="w-50">
                    <InputGroup>
                        <Form.Control
                            placeholder="חפש לפי אתר"
                            onChange={handleSearchChange}
                            value={searchWebsite}
                        />
                        <Button variant="outline-secondary" onClick={handleSearch}>
                            חפש
                        </Button>
                    </InputGroup>
                </Form>
            </Col>
            <Col md={12}>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>כתובת האתר</th>
                        <th>קטגוריה</th>
                        <th>מחיר</th>
                        {/*<th>אתר חזק</th>*/}
                        <th>תיאור</th>
                        <th>תנועה</th>
                        <th>rd</th>
                        <th>dr</th>
                        <th>תמונה</th>
                        <th>סוג האתר</th>
                        <th>נוצר בתאריך</th>
                        <th>עודכן בתאריך</th>
                        <th>פעולות</th>
                    </tr>
                    </thead>
                    <tbody>
                    {links.map((link, index) => (
                        <tr key={link.id}>
                            <td>{index + 1}</td>
                            <td>{link.website}</td>
                            <td>{link.category.name}</td>
                            <td>{link.price}</td>
                            {/*<td>{link.powerSite ? 'כן' : 'לא'}</td>*/}
                            <td>{link.info?.title}</td>
                            <td>{link.metadata?.traffic}</td>
                            <td>{link.metadata?.rd}</td>
                            <td>{link.metadata?.dr}</td>
                            <td>
                                {link.thumbnail && (
                                    <img src={link.thumbnail} alt={link.website} width={50}/>
                                )}
                            </td>
                            <td>{link.siteType}</td>
                            <td>{new Date(link.createdAt).toLocaleDateString()}</td>
                            <td>
                                {link.updatedAt &&
                                    new Date(link.updatedAt).toLocaleDateString()}
                            </td>
                            <td>
                                <Button variant="danger" onClick={() => handleDelete(link.id)}>
                                    מחק
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <PaginationBar/>

            </Col>

        </Row>
    );
};

export default AdminRecommended;
