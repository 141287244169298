import React from 'react';
import {HashRouter as Router, Route, Routes} from 'react-router-dom';
import AdminRouter from './AdminRouter';
import AdminLogin from "../components/admin/AdminLogin";
import RequireAuth from "../guards/RequireAuth";
import AppLayout from '../components/AppLayout';
import OrderProcessing from "../components/OrderProcessing";
import ResetPassword from "../components/ResetPassword";
import usePageView from "../hooks/usePageView";

const MainRouter: React.FC = () => {

    return (
        <Router>
            <PageViewTracker/>
            <Routes>

                <Route path="admin/*" element={
                    <RequireAuth redirectTo="/login">
                        <AdminRouter/>
                    </RequireAuth>
                }/>
                <Route path="login" Component={AdminLogin}/>
                <Route path="reset-password" Component={ResetPassword}/>
                <Route path="checkout/complete" Component={OrderProcessing}/>
                <Route path="/*" element={<AppLayout/>}/>
            </Routes>
        </Router>
    );
};

const PageViewTracker: React.FC = () => {
    usePageView(); // Hook to track page views
    return null; // No need to render anything
};

export default MainRouter;