import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, InputGroup, Modal, Row, Table} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {AxiosResponse} from "axios";
import {useApiService} from "../../context/ApiServiceContext";
import * as yup from 'yup';
import {useFormik} from "formik";
import Video from "../../models/Video";

interface VideoRequestBody {
    category: string;
    title: string;
    description: string;
    youtubeUrl: string;
}

const AdminVideos: React.FC = () => {
    const apiService = useApiService();
    const [videos, setVideos] = useState<Video[]>([]);
    const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [videoFields, setVideoFields] = useState<VideoRequestBody>({
        category: '',
        title: '',
        description: '',
        youtubeUrl: '',
    });
    const [isFileUploading, setFileUploading] = useState<boolean>(false);
    const [searchName, setSearchName] = useState('');

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const videosPerPage = 20; // Adjust as needed
    /* End of Pagination */

    useEffect(() => {
        fetchVideos(currentPage, searchName);
    }, [currentPage]);

    const fetchVideos = useCallback(async (page = 1, name: string) => {
        try {
            const url = `/video/list?page=${page}&per_page=${videosPerPage}${name.trim() !== '' ? `&name=${name}` : ''}`;
            const res = await apiService.api.get(url);
            setVideos(prevVideos => [...prevVideos, ...res.data]);
            setHasMorePages(res.data.length === videosPerPage);
        } catch (error) {
            // Handle error
        }
    }, [apiService, videosPerPage]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchName(e.target.value);
    };

    const handleSearch = (e?: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        setVideos([]);
        fetchVideos(1, searchName);
    };

    const handleDelete = (videoId: string) => {
        if (window.confirm('האם אתה בטוח שברצונך למחוק את הסרטון?')) {
            apiService.api
                .delete(`/video/${videoId}`)
                .then(() => {
                    setVideos(videos.filter((video) => video.id !== videoId));
                    toast.success('הסרטון נמחקה בהצלחה');
                })
                .catch(() => {
                    toast.error('בעיה במחיקת הסרטון');
                });
        }
    };

    const handleUpdate = (values: any) => {
        if (selectedVideo) {
            const updatedVideo: VideoRequestBody = {
                ...values
            };

            apiService.api
                .put(`/video/${selectedVideo.id}`, updatedVideo)
                .then((res: AxiosResponse<Video>) => {
                    setVideos(
                        videos.map((video) => (video.id === selectedVideo.id ? res.data : video))
                    );
                    toast.success('הסרטון עודכנה בהצלחה');
                })
                .catch(() => {
                    toast.error('בעיה בעדכון הסרטון');
                });

            setShowModal(false);
            formik.resetForm()
        }
    };

    const handleCreate = (values: any) => {
        const newVideo: VideoRequestBody = values;

        apiService.api
            .post('/video/', newVideo)
            .then((res: AxiosResponse<Video>) => {
                setVideos([res.data, ...videos]);
                toast.success('הסרטון נוספה בהצלחה');
            })
            .catch(() => {
                toast.error('בעיה בהוספת הסרטון');
            });

        setShowModal(false);
        formik.resetForm()
    };

    const openModal = (video: Video | null) => {
        setSelectedVideo(video);
        setVideoFields({
            category: video ? video.category : '',
            title: video ? video.title : '',
            description: video ? video.description : '',
            youtubeUrl: video ? video.youtubeUrl : '',
        });
        setShowModal(true);
    };

    const handleFileChange = (file: File | null) => {
        if (file) {
            setFileUploading(true);

            const formData = new FormData();
            formData.append('file', file);

            apiService.api.post(`/video/thumbnail/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    formik.setFieldValue('thumbnailUrl', response.data.url);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setFileUploading(false);
                });
        }
    };

    const PaginationBar = () => {
        return (
            <div className="d-flex justify-content-center my-3">
                <Button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="me-2"
                >
                    הקודם
                </Button>
                <Button
                    disabled={!hasMorePages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    הבא
                </Button>
            </div>
        );
    };

    const videoSchema = yup.object().shape({
        category: yup.string().required('נדרשת קטגוריה'),
        title: yup.string().required('נדרשת כותרת'),
        description: yup.string().required('נדרש תיאור'),
        youtubeUrl: yup.string().url('הכתובת ליוטיוב לא תקינה')
            .matches(/(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/watch\?v=[a-zA-Z0-9_-]{11}/, 'הכתובת ליוטיוב לא תקינה'),
    });

    const formik = useFormik({
        initialValues: videoFields,
        validationSchema: videoSchema,
        onSubmit: values => {
            if (selectedVideo) {
                handleUpdate(values);
            } else {
                handleCreate(values);
            }
        },
        validateOnBlur: true,
        validateOnMount: true,
        enableReinitialize: true,
    });

    return (
        <Row className="justify-content-center py-md-5">
            <Col md={4}>
                <h1 className="mb-2">סרטונים</h1>
            </Col>
            <Col md={8} className="d-flex justify-content-end align-items-center">
                {/*<Form onSubmit={handleSearch} className="me-4 w-50">*/}
                {/*    <InputGroup>*/}
                {/*        <Form.Control*/}
                {/*            placeholder="Search by Name"*/}
                {/*            onChange={handleSearchChange}*/}
                {/*            value={searchName}*/}
                {/*        />*/}
                {/*        <Button variant="outline-secondary" onClick={handleSearch}>*/}
                {/*            חיפוש*/}
                {/*        </Button>*/}
                {/*    </InputGroup>*/}
                {/*</Form>*/}
                <Button onClick={() => openModal(null)}>הוסף סרטון</Button>
            </Col>
            <Col md={12}>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>קטגוריה</th>
                        <th>כותרת</th>
                        <th>תיאור</th>
                        <th>סרטון</th>
                        <th>נוצר בתאריך</th>
                        <th>עודכן בתאריך</th>
                        <th>פעולות</th>
                    </tr>
                    </thead>
                    <tbody>
                    {videos.map((video, index) => (
                        <tr key={video.id}>
                            <td>{index + 1}</td>
                            <td>{video.category}</td>
                            <td>{video.title}</td>
                            <td>{video.description}</td>
                            <td>
                                {video.youtubeUrl && (
                                    <a href={video.youtubeUrl} target="_blank">▶️</a>
                                )}
                            </td>
                            <td>{new Date(video.createdAt).toLocaleDateString()}</td>
                            <td>
                                {video.updatedAt &&
                                    new Date(video.updatedAt).toLocaleDateString()}
                            </td>
                            <td>
                                <Button className="me-2" onClick={() => openModal(video)}>
                                    ערוך
                                </Button>
                                <Button variant="danger" onClick={() => handleDelete(video.id)}>
                                    מחק
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <PaginationBar/>
            </Col>

            <Modal show={showModal} onHide={() => {
                setShowModal(false);
                formik.resetForm();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedVideo ? 'עדכן' : 'הוסף'} סרטון</Modal.Title>
                </Modal.Header>
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>קטגוריה</Form.Label>
                            <Form.Control
                                type="text"
                                name="category"
                                placeholder="הכנס קטגוריה"
                                value={formik.values.category}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.category && !!formik.errors.category}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.category}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>כותרת</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                placeholder="הכנס כותרת"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.title && !!formik.errors.title}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.title}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>תיאור</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                placeholder="הכנס תיאור"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.description && !!formik.errors.description}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.description}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>כתובת לסרטון ביוטיוב - פורמט https://www.youtube.com/watch?v=id</Form.Label>
                            <Form.Control
                                type="text"
                                name="youtubeUrl"
                                placeholder="הכנס כתובת URL לסרטון ביוטיוב"
                                value={formik.values.youtubeUrl}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.youtubeUrl && !!formik.errors.youtubeUrl}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.youtubeUrl}
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            בטל
                        </Button>
                        <Button variant="primary" type="submit">
                            שמור
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Row>
    );
};

export default AdminVideos;
