import Skeleton from '@mui/material/Skeleton';

interface SkeletonLoaderProps {
    amount?: number
    marginTop?: boolean
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({amount = 1, marginTop = true}) => (
    <>
        {
            Array.from({length: amount}, (_, index) => (
                <Skeleton key={index} className={`${marginTop ? 'mt-3 ' : ''}me-4`} variant="rounded" width={280}
                          height={368}/>
            ))
        }
    </>
);

export default SkeletonLoader;