import React from 'react';
import LinkBase from "./LinkBase";
import regoArrow from  "../../assets/images/diamond.png"
import premium from  "../../assets/images/link/premium-bg.png"
import {SiteType} from "../../models/Link";

const LinkPremium: React.FC = () => {
    return <LinkBase
        header={{
            title: "קישורי Premium",
            subtitle: "כאן תמצאו את נבחרת האתרים המובילים ברשת! הקישורים החזקים ביותר לאתרים הפופולריים במדיה יעניקו לכם את החשיפה הרחבה ביותר וכמובן, תוצאות שיווק מקסימליות - כי למותג שלכם מגיע הטוב ביותר :)",
        }}
        lineColor="#46BB52"
        subtitle="קישורי Premium"
        subtitleImage={regoArrow}
        linkBG={premium}
        siteType={SiteType.PREMIUM}
        allowProvideContent={true}
        hideCategories={true}
        fetchAll={true}
    />;
};

export default LinkPremium;