import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, InputGroup, Modal, Row, Table} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {AxiosResponse} from "axios";
import {useApiService} from "../../context/ApiServiceContext";
import * as yup from 'yup';
import {useFormik} from "formik";
import LegalDocument from "../../models/LegalDocument";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {makeStyles} from "@material-ui/core";

interface LegalDocumentRequestBody {
    path: string;
    title: string;
    text: string;
}

const modules = {
    toolbar: [
        [{'header': '1'}, {'header': '2'}, {'font': []}],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
};

// Formats the react-quill editor understands
const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent'
];

const useStyles = makeStyles({
    quillRtl: {
        "& .ql-editor": {
            minHeight: "calc(1.5em * 10)",
            maxHeight: "calc(1.5em * 20)",
            direction: "rtl",
            textAlign: "right"
        }
    }
})

const AdminLegalDocuments: React.FC = () => {
    const classes = useStyles();
    const apiService = useApiService();
    const [legalDocuments, setLegalDocuments] = useState<LegalDocument[]>([]);
    const [selectedLegalDocument, setSelectedLegalDocument] = useState<LegalDocument | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [legalDocumentFields, setLegalDocumentFields] = useState<LegalDocumentRequestBody>({
        title: '',
        path: '',
        text: '',
    });

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const legalDocumentsPerPage = 20; // Adjust as needed
    /* End of Pagination */

    useEffect(() => {
        fetchLegalDocuments(currentPage);
    }, [currentPage]);

    const fetchLegalDocuments = useCallback(async (page = 1) => {
        try {
            const url = `/legal-document/list?page=${page}&per_page=${legalDocumentsPerPage}`;
            const res = await apiService.api.get(url);
            setLegalDocuments(prevLegalDocuments => [...prevLegalDocuments, ...res.data]);
            setHasMorePages(res.data.length === legalDocumentsPerPage);
        } catch (error) {
            // Handle error
        }
    }, [apiService, legalDocumentsPerPage]);


    const handleDelete = (legalDocumentId: string) => {
        if (window.confirm('האם אתה בטוח שברצונך למחוק את המסמך?')) {
            apiService.api
                .delete(`/legal-document/admin/${legalDocumentId}`)
                .then(() => {
                    setLegalDocuments(legalDocuments.filter((legalDocument) => legalDocument.id !== legalDocumentId));
                    toast.success('המסמך נמחק בהצלחה');
                })
                .catch(() => {
                    toast.error('בעיה במחיקת המסמך');
                });
        }
    };

    const handleUpdate = (values: any) => {
        if (selectedLegalDocument) {
            const updatedLegalDocument: LegalDocumentRequestBody = {
                ...values
            };

            apiService.api
                .put(`/legal-document/admin/${selectedLegalDocument.id}`, updatedLegalDocument)
                .then((res: AxiosResponse<LegalDocument>) => {
                    setLegalDocuments(
                        legalDocuments.map((legalDocument) => (legalDocument.id === selectedLegalDocument.id ? res.data : legalDocument))
                    );
                    toast.success('המסמך עודכן בהצלחה');
                })
                .catch(() => {
                    toast.error('בעיה בעדכון המסמך');
                });

            setShowModal(false);
            formik.resetForm()
        }
    };

    const handleCreate = (values: any) => {
        const newLegalDocument: LegalDocumentRequestBody = values;

        apiService.api
            .post('/legal-document/', newLegalDocument)
            .then((res: AxiosResponse<LegalDocument>) => {
                setLegalDocuments([res.data, ...legalDocuments]);
                toast.success('המסמך נוסף בהצלחה');
            })
            .catch(() => {
                toast.error('בעיה בהוספת המסמך');
            });

        setShowModal(false);
        formik.resetForm()
    };

    const openModal = async (legalDocument: LegalDocument | null) => {
        if (legalDocument) {
            try {
                // Make a call to fetch the specific dynamic page details
                const res = await apiService.api.get(`/legal-document/admin/${legalDocument.id}`);
                const data = res.data;

                // Populate the form fields with the fetched data
                setLegalDocumentFields({
                    path: data.path,
                    title: data.title,
                    text: data.text,
                });
            } catch (error) {
                // Handle potential errors, such as displaying a notification
                toast.error('Failed to fetch dynamic page details');
            }
        } else {
            // If creating a new dynamic page, reset fields
            setLegalDocumentFields({
                path: '',
                title: '',
                text: '',
            });
        }

        setSelectedLegalDocument(legalDocument);
        setShowModal(true);
    };

    const PaginationBar = () => {
        return (
            <div className="d-flex justify-content-center my-3">
                <Button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="me-2"
                >
                    הקודם
                </Button>
                <Button
                    disabled={!hasMorePages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    הבא
                </Button>
            </div>
        );
    };

    const legalDocumentSchema = yup.object().shape({
        path: yup.string().required('נדרש נתיב'),
        title: yup.string().required('נדרשת כותרת'),
        text: yup.string().min(50, "המסמך חייב להכיל לפחות 100 תווים")
    });

    const formik = useFormik({
        initialValues: legalDocumentFields,
        validationSchema: legalDocumentSchema,
        onSubmit: values => {
            if (selectedLegalDocument) {
                handleUpdate(values);
            } else {
                handleCreate(values);
            }
        },
        validateOnBlur: true,
        validateOnMount: true,
        enableReinitialize: true,
    });

    return (
        <Row className="justify-content-center py-md-5">
            <Col md={4}>
                <h1 className="mb-2">מסמכים משפטיים</h1>
            </Col>
            <Col md={8} className="d-flex justify-content-end align-items-center">
                {/*<Form onSubmit={handleSearch} className="me-4 w-50">*/}
                {/*    <InputGroup>*/}
                {/*        <Form.Control*/}
                {/*            placeholder="Search by Name"*/}
                {/*            onChange={handleSearchChange}*/}
                {/*            value={searchName}*/}
                {/*        />*/}
                {/*        <Button variant="outline-secondary" onClick={handleSearch}>*/}
                {/*            חיפוש*/}
                {/*        </Button>*/}
                {/*    </InputGroup>*/}
                {/*</Form>*/}
                <Button onClick={() => openModal(null)}>הוסף מסמך חדש</Button>
            </Col>
            <Col md={12}>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>נתיב</th>
                        <th>כותרת</th>
                        <th>נוצר בתאריך</th>
                        <th>עודכן בתאריך</th>
                        <th>פעולות</th>
                    </tr>
                    </thead>
                    <tbody>
                    {legalDocuments.map((legalDocument, index) => (
                        <tr key={legalDocument.id}>
                            <td>{index + 1}</td>
                            <td>{legalDocument.path}</td>
                            <td>{legalDocument.title}</td>
                            <td>{new Date(legalDocument.createdAt).toLocaleDateString()}</td>
                            <td>
                                {legalDocument.updatedAt &&
                                    new Date(legalDocument.updatedAt).toLocaleDateString()}
                            </td>
                            <td>
                                <Button className="me-2" onClick={() => openModal(legalDocument)}>
                                    ערוך
                                </Button>
                                <Button variant="danger" onClick={() => handleDelete(legalDocument.id)}>
                                    מחק
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <PaginationBar/>
            </Col>

            <Modal show={showModal} onHide={() => {
                setShowModal(false);
                formik.resetForm();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedLegalDocument ? 'עדכן' : 'הוסף'} מסמך</Modal.Title>
                </Modal.Header>
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Modal.Body>

                        <Form.Group className="mt-2">
                            <Form.Label>נתיב</Form.Label>
                            <Form.Control
                                type="text"
                                name="path"
                                placeholder="הכנס נתיב"
                                value={formik.values.path}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.path && !!formik.errors.path}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.path}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>כותרת</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                placeholder="הכנס כותרת"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.title && !!formik.errors.title}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.title}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>מסמך</Form.Label>
                            <ReactQuill
                                className={classes.quillRtl}
                                placeholder="הכנס את המסמך"
                                value={formik.values.text}
                                onChange={value => formik.setFieldValue('text', value)}
                                modules={modules}
                                formats={formats}
                            />
                            {formik.touched.text && formik.errors.text && (
                                <div className="invalid-feedback d-block">
                                    {formik.errors.text}
                                </div>
                            )}
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            בטל
                        </Button>
                        <Button variant="primary" type="submit">
                            שמור
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Row>
    );
};


export default AdminLegalDocuments;
