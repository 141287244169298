import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, InputGroup, Modal, Row, Table} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {AxiosResponse} from "axios";
import {useApiService} from "../../context/ApiServiceContext";
import * as yup from 'yup';
import {useFormik} from "formik";
import Recommendation from "../../models/Recommendation";
import {CircularProgress} from "@mui/material";

interface RecommendationRequestBody {
    category: string;
    title: string;
    description: string;
    url: string;
    callToAction: string;
    img: string;
}

const AdminRecommendations: React.FC = () => {
    const apiService = useApiService();
    const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
    const [selectedRecommendation, setSelectedRecommendation] = useState<Recommendation | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [recommendationFields, setRecommendationFields] = useState<RecommendationRequestBody>({
        category: '',
        title: '',
        description: '',
        url: '',
        callToAction: '',
        img: '',
    });
    const [isFileUploading, setFileUploading] = useState<boolean>(false);
    const [searchName, setSearchName] = useState('');

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const recommendationsPerPage = 20; // Adjust as needed
    /* End of Pagination */

    useEffect(() => {
        fetchRecommendations(currentPage, searchName);
    }, [currentPage]);

    const fetchRecommendations = useCallback(async (page = 1, name: string) => {
        try {
            const url = `/recommendation/list?page=${page}&per_page=${recommendationsPerPage}${name.trim() !== '' ? `&name=${name}` : ''}`;
            const res = await apiService.api.get(url);
            setRecommendations(prevRecommendations => [...prevRecommendations, ...res.data]);
            setHasMorePages(res.data.length === recommendationsPerPage);
        } catch (error) {
            // Handle error
        }
    }, [apiService, recommendationsPerPage]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchName(e.target.value);
    };

    const handleSearch = (e?: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        setRecommendations([]);
        fetchRecommendations(1, searchName);
    };

    const handleDelete = (recommendationId: string) => {
        if (window.confirm('האם אתה בטוח שברצונך למחוק את ההמלצה?')) {
            apiService.api
                .delete(`/recommendation/${recommendationId}`)
                .then(() => {
                    setRecommendations(recommendations.filter((recommendation) => recommendation.id !== recommendationId));
                    toast.success('ההמלצה נמחקה בהצלחה');
                })
                .catch(() => {
                    toast.error('בעיה במחיקת ההמלצה');
                });
        }
    };

    const handleUpdate = (values: any) => {
        if (selectedRecommendation) {
            const updatedRecommendation: RecommendationRequestBody = {
                ...values
            };

            apiService.api
                .put(`/recommendation/${selectedRecommendation.id}`, updatedRecommendation)
                .then((res: AxiosResponse<Recommendation>) => {
                    setRecommendations(
                        recommendations.map((recommendation) => (recommendation.id === selectedRecommendation.id ? res.data : recommendation))
                    );
                    toast.success('ההמלצה עודכנה בהצלחה');
                })
                .catch(() => {
                    toast.error('בעיה בעדכון ההמלצה');
                });

            setShowModal(false);
            formik.resetForm()
        }
    };

    const handleCreate = (values: any) => {
        const newRecommendation: RecommendationRequestBody = values;

        apiService.api
            .post('/recommendation/', newRecommendation)
            .then((res: AxiosResponse<Recommendation>) => {
                setRecommendations([res.data, ...recommendations]);
                toast.success('ההמלצה נוספה בהצלחה');
            })
            .catch(() => {
                toast.error('בעיה בהוספת ההמלצה');
            });

        setShowModal(false);
        formik.resetForm()
    };

    const openModal = (recommendation: Recommendation | null) => {
        setSelectedRecommendation(recommendation);
        setRecommendationFields({
            category: recommendation ? recommendation.category : '',
            title: recommendation ? recommendation.title : '',
            description: recommendation ? recommendation.description : '',
            url: recommendation ? recommendation.url : '',
            callToAction: recommendation ? recommendation.callToAction : '',
            img: recommendation ? recommendation.img : '',
        });
        setShowModal(true);
    };

    const handleFileChange = (files: File[]) => {
        if (files && files.length > 0) {
            setFileUploading(true);

            const formData = new FormData();
            formData.append('file0', files[0]);

            apiService.api.post(`/recommendation/img/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    formik.setFieldValue('img', response.data.urls[0]);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setFileUploading(false);
                });
        }
    };

    const PaginationBar = () => {
        return (
            <div className="d-flex justify-content-center my-3">
                <Button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="me-2"
                >
                    הקודם
                </Button>
                <Button
                    disabled={!hasMorePages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    הבא
                </Button>
            </div>
        );
    };

    const recommendationSchema = yup.object().shape({
        category: yup.string().required('נדרשת קטגוריה'),
        title: yup.string().required('נדרשת כותרת'),
        description: yup.string().required('נדרש תיאור'),
        url: yup.string().url('הכתובת לא תקינה'),
        callToAction: yup.string().required('חובה להכניס טקסט לכפתור'),
        img: yup.string().required('חובה להכניס תמונה'),
    });

    const formik = useFormik({
        initialValues: recommendationFields,
        validationSchema: recommendationSchema,
        onSubmit: values => {
            if (selectedRecommendation) {
                handleUpdate(values);
            } else {
                handleCreate(values);
            }
        },
        validateOnBlur: true,
        validateOnMount: true,
        enableReinitialize: true,
    });

    return (
        <Row className="justify-content-center py-md-5">
            <Col md={4}>
                <h1 className="mb-2">המלצות חמות</h1>
            </Col>
            <Col md={8} className="d-flex justify-content-end align-items-center">
                {/*<Form onSubmit={handleSearch} className="me-4 w-50">*/}
                {/*    <InputGroup>*/}
                {/*        <Form.Control*/}
                {/*            placeholder="Search by Name"*/}
                {/*            onChange={handleSearchChange}*/}
                {/*            value={searchName}*/}
                {/*        />*/}
                {/*        <Button variant="outline-secondary" onClick={handleSearch}>*/}
                {/*            חיפוש*/}
                {/*        </Button>*/}
                {/*    </InputGroup>*/}
                {/*</Form>*/}
                <Button onClick={() => openModal(null)}>הוסף המלצה</Button>
            </Col>
            <Col md={12}>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>קטגוריה</th>
                        <th>כותרת</th>
                        <th>תיאור</th>
                        <th>המלצה</th>
                        <th>נוצר בתאריך</th>
                        <th>עודכן בתאריך</th>
                        <th>פעולות</th>
                    </tr>
                    </thead>
                    <tbody>
                    {recommendations.map((recommendation, index) => (
                        <tr key={recommendation.id}>
                            <td>{index + 1}</td>
                            <td>{recommendation.category}</td>
                            <td>{recommendation.title}</td>
                            <td>{recommendation.description}</td>
                            <td>
                                {recommendation.url && (
                                    <a href={recommendation.url} target="_blank">פתח️</a>
                                )}
                            </td>
                            <td>{new Date(recommendation.createdAt).toLocaleDateString()}</td>
                            <td>
                                {recommendation.updatedAt &&
                                    new Date(recommendation.updatedAt).toLocaleDateString()}
                            </td>
                            <td>
                                <Button className="me-2" onClick={() => openModal(recommendation)}>
                                    ערוך
                                </Button>
                                <Button variant="danger" onClick={() => handleDelete(recommendation.id)}>
                                    מחק
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <PaginationBar/>
            </Col>

            <Modal show={showModal} onHide={() => {
                setShowModal(false);
                formik.resetForm();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedRecommendation ? 'עדכן' : 'הוסף'} המלצה</Modal.Title>
                </Modal.Header>
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>קטגוריה</Form.Label>
                            <Form.Control
                                type="text"
                                name="category"
                                placeholder="הכנס קטגוריה"
                                value={formik.values.category}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.category && !!formik.errors.category}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.category}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>כותרת</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                placeholder="הכנס כותרת"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.title && !!formik.errors.title}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.title}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>תיאור</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                placeholder="הכנס תיאור"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.description && !!formik.errors.description}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.description}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>כתובת אפיליאציה</Form.Label>
                            <Form.Control
                                type="text"
                                name="url"
                                placeholder="הכנס כתובת URL להמלצה ביוטיוב"
                                value={formik.values.url}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.url && !!formik.errors.url}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.url}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>טקסט לכפתור</Form.Label>
                            <Form.Control
                                type="text"
                                name="callToAction"
                                placeholder="הכנס טקסט לכפתור"
                                value={formik.values.callToAction}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.callToAction && !!formik.errors.callToAction}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.callToAction}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>תמונה
                                {formik.values.img && formik.values.img.trim() !== "" &&
                                    <>
                                        {" ("}
                                        <a href={formik.values.img} target="_blank">פתח בחלון חדש</a>
                                        {")"}
                                    </>
                                }
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="img"
                                placeholder="העלה תמונה"
                                value={formik.values.img}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.img && !!formik.errors.img}
                                disabled
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.img}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId={`file`}>
                            <Form.Label>
                                <div>
                                    <span>העלאת קובץ לתמונה</span>
                                    <CircularProgress className="ms-3" color="secondary"
                                                      size={13}
                                                      hidden={!isFileUploading}/>
                                </div>
                            </Form.Label>
                            <Form.Control
                                type="file"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleFileChange(e.target.files ? Array.from(e.target.files) : [])}
                                accept=".png,.jpg,.jpeg,.webp"
                            />
                        </Form.Group>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            בטל
                        </Button>
                        <Button variant="primary" type="submit" disabled={isFileUploading}>
                            שמור
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Row>
    );
};

export default AdminRecommendations;
