import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import userImage1 from "../../assets/images/testimonial/idan.png";
import testimonialQuete from "../../assets/images/testimonial-quete.png";
import {Theme} from "@mui/material";

// Define the type for a single testimonial
type Testimonial = {
    quote: string;
    image: string;
    attribution: string;
};

// Define the type for the props in case you need to pass any in the future
type TestimonialSliderProps = {};

const useStyles = makeStyles((theme: Theme) => ({
    testimonial: {
        boxShadow: '0px 3px 15px rgba(0,0,0,0.2)',
        borderRadius: '15px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        position: 'relative',
        maxWidth: '80%',
        margin: 'auto',
        height: '320px',

        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            flexDirection: 'column',
            maxWidth: '90%',
        }
    },
    quote: {
        fontSize: '23px',
        fontWeight: 500,
        whiteSpace: 'pre-line',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        }
    },
    image: {
        borderRadius: '50%',
        width: '160px',
        height: '160px',
        objectFit: 'cover',
    },
    attribution: {
        marginTop: '10px',
    },
    quoteImage: {
        position: "absolute",
        top: "-26px",
        left: "6%",
    },
    // Other styles...
}));

const testimonials: Testimonial[] = [
    {
        quote: "גם מי שבתחילת דרכו בעולם ה-SEO, יודע שהצעד הראשון במסע הטיפוס בגוגל, הוא קישורים איכותיים. פיתחנו את link&go במטרה לתת מענה לכל אחד להתברג בצורה אפקטיבית. בעצם, פתחנו בפניכם את מערכת האתרים שלנו, עם דומיינים חזקים ומקצועיים, ועכשיו יש לכם ההזדמנות ליהנות מהאיכויות , ולכבוש איתנו את הפסגה הבאה.",
        image: userImage1,
        attribution: "עידן בן אור, מנכ\"ל חברת ריגו מרקטינג",
    },
    // Add more testimonials as needed
];

const TestimonialSlider: React.FC<TestimonialSliderProps> = () => {
    const classes = useStyles();
    const [activeIndex, setActiveIndex] = useState<number>(0);

    useEffect(() => {
        // const interval = setInterval(() => {
        //     setActiveIndex(current => (current + 1) % testimonials.length);
        // }, 5000); // Change slide every 5 seconds
        //
        // return () => clearInterval(interval);
    }, [testimonials.length]);

    const activeTestimonial = testimonials[activeIndex];

    return (
        <div className={classes.testimonial}>
            <div className="p-2 p-md-5 text-center">
                <blockquote className={classes.quote}>
                    “{activeTestimonial.quote}”
                </blockquote>
                <div className={classes.attribution}>
                    {activeTestimonial.attribution}
                </div>
            </div>
            <div className="p-4 p-md-5">
                <img src={activeTestimonial.image} alt="Testimonial" className={classes.image}/>
            </div>
            <img className={classes.quoteImage} src={testimonialQuete} alt="quote"/>
        </div>
    );
};

export default TestimonialSlider;
