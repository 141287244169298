// LinkGrid.tsx
import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {Card, Col, Container, Row} from 'react-bootstrap';
import {Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useApiService} from "../context/ApiServiceContext";
import SkeletonLoader from "./SkeletonLoader";
import wordLogo from "../assets/images/word-logo.png"
import right0BG from "../assets/images/bg/purple/right0.png";
import {useNavigate} from "react-router-dom";
import LegalDocument from "../models/LegalDocument";

const useStyles = makeStyles({
    card: {
        width: '100%',
        height: '220px',
        borderRadius: '10px',
        border: '0',
        boxShadow: '0 8px 13px 2px rgba(163, 163, 163, 0.40)',
        cursor: 'pointer'
    },
    formControl: {
        minWidth: 120,
        marginBottom: 20,
    },
    gridInputImage: {
        boxShadow: `8px 4px 10px 0px rgba(0, 0, 0, 0.12)`,
        border: 0,
        zIndex: 1
    },
    gridInput: {
        boxShadow: `4px 4px 10px 0px rgba(0, 0, 0, 0.12)`,
        border: 0
    },
    bg: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
    },
    cloudStart: {
        position: "absolute",
        right: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
    },
    cloudMiddleStart: {
        position: "absolute",
        top: "150vh",
        right: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
    },
    category: {
        borderRadius: "8px",
        border: "1px solid #EAEDF1",
        background: "#F0EFFA",
        color: "#251FB6",
        display: "inline-block",
        padding: "7px 15px",
        margin: "5px 0!important"
    },
    img: {}
});

const Root = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column'
}));

const Legals: React.FC = ({}) => {
    const classes = useStyles({});
    const navigate = useNavigate();

    const [legals, setLegals] = useState<LegalDocument[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>(''); // Add this line for search term state
    const apiService = useApiService();

    useEffect(() => {
        fetchLegals()
    }, []);

    const fetchLegals = useCallback(async () => {
        setIsLoading(true)
        // Update the API request to include searchTerm in the query
        apiService.api.get(`/legal-document/list?`)
            .then(response => {
                setLegals(response.data);
                setIsLoading(false)
            });
    }, [apiService]);

    // Function to calculate the Bootstrap column size class for each row
    const getColumnSizeClass = (total: number, currentIndex: number) => {
        return total === 1 ? 4 : currentIndex > 2 ? 6 : 4;
    };

    return (
        <Root>
            <div className={classes.bg}>
                <div className="float-start w-100" style={{
                    height: "1000px",
                    background: `radial-gradient(circle at -6% 500px in hsl shorter hue, #0060ff8f 0%, rgba(255, 255, 255, 0) 400px)`
                }}></div>
                <img className={classes.cloudStart} src={right0BG}/>
            </div>
            <Container className="mb-4 mt-5 text-center" style={{height: "auto",}}>
                <Row className="justify-content-md-center">
                    <Col xl={5}>
                        <Typography variant="h3" style={{marginTop: "10px"}}>מסמכים משפטיים</Typography>
                    </Col>
                </Row>
                {false && <Row xl={5} className="justify-content-md-center">
                    <Col xl={5}>
                        <Typography variant="body1" style={{marginTop: "10px"}}>לורם איפסום דולור סיט אמט, קונסקטורר
                            אדיפיסינג אלית קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף נולום ארווס סאפיאן -
                            פוסיליס קוויס, אקווזמן.</Typography>
                    </Col>
                </Row>}
            </Container>

            <Container className="mb-4 mt-5" style={{height: "auto",}}>
                <Row className="justify-content-md-center">

                    {isLoading ? (
                        <SkeletonLoader amount={2} marginTop={false}/>
                    ) : (
                        legals.length > 0 ? legals.map((legal, index) => (
                                <>
                                    <Col
                                        key={index}
                                        xs={12}
                                        sm={getColumnSizeClass(legals.length, index)}
                                        className="mb-4"
                                    >
                                        <Card className={classes.card} style={{
                                            backgroundSize: 'contain',
                                            backgroundColor: 'white',
                                            overflow: 'hidden'
                                        }}
                                              onClick={() => navigate(`/legal/${legal.path}`)}>
                                            <Card.Body
                                                className={"d-flex text-center align-items-center justify-content-center"}
                                                style={{padding: "24px 16px"}}>

                                                <div dir='rtl'>
                                                    <div className={classes.img}>
                                                        <img src={wordLogo}/>
                                                    </div>
                                                    <div className="mt-2">
                                                        <Typography variant="body1" style={{fontSize: "18px"}}>
                                                            {legal.title}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </>
                            )) :
                            <Typography variant="h6" className="fw-bold">
                                לא מצאנו מסמכים
                            </Typography>
                    )}
                </Row>
            </Container>
        </Root>
    );
};

export default Legals;