import React, {useState} from 'react';
import {Form, Modal} from 'react-bootstrap';
import {register} from "../../utils/userSlice";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../utils/store";
import {Button} from "@mui/material";
import * as yup from 'yup';
import {useFormik} from 'formik';

interface RegisterModalProps {
    show: boolean;
    handleClose: () => void;
}

export interface RegisterFormState {
    name: string;
    email: string;
    password: string;
    field: string;
    // clientType: string;
    companySize?: number;
    websites: string[];
    allowMarketing?: boolean;
}

const RegisterModal: React.FC<RegisterModalProps> = ({show, handleClose}) => {
    const dispatch: AppDispatch = useDispatch();

    // Define the validation schema using yup
    const validationSchema = yup.object({
        name: yup.string().required('חובה למלא שם מלא'),
        email: yup.string().email('אימייל לא תקין').required('חובה למלא אימייל'),
        password: yup.string()
            .required('שדה חובה')
            .min(6, 'הסיסמא צריכה להכיל לפחות 6 תווים')
            .matches(/[a-z]/, 'הסיסמא צריכה להכיל לפחות אות קטנה אחת')
            .matches(/[A-Z]/, 'הסיסמא צריכה להכיל לפחות אות גדולה אחת')
            .matches(/[0-9]/, 'הסיסמא צריכה להכיל לפחות מספר אחד')
            .matches(/[!@#$%^&*(),.?":{}|<>]/, 'הסיסמא צריכה להכיל לפחות תו מיוחד אחד'),
        field: yup.string().required('שדה חובה'),
        // clientType: yup.string().required('שדה חובה'),
        companySize: yup.number()
            // .required('שדה חובה')
            .nullable(),
        websites: yup.string()
            // .required('שדה חובה'),
        // Add any additional validation rules here
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            field: '',
            clientType: '',
            companySize: undefined,
            websites: '',
            allowMarketing: true,
        },
        validationSchema,
        onSubmit: async (values) => {
            if (formik.isValid) {
                const websitesSplit = (values.websites as string).split(',');
                const success = await dispatch(await register({
                    ...values,
                    websites: websitesSplit.length > 0 ? websitesSplit : [values.websites]
                }));
                if (success) {
                    handleClose();
                }
            }
        },
        validateOnMount: true,
    });

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Form noValidate onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>הרשמה</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* Name Field */}
                    <Form.Group controlId="formName">
                        <Form.Label>שם*</Form.Label>
                        <Form.Control
                            name="name"
                            type="text"
                            placeholder="שם"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            isInvalid={formik.touched.name && !!formik.errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {/* Email Field */}
                    <Form.Group className="mt-2" controlId="formEmail">
                        <Form.Label>אימייל*</Form.Label>
                        <Form.Control
                            name="email"
                            type="email"
                            placeholder="אימייל"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            isInvalid={formik.touched.email && !!formik.errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {/* Password Field */}
                    <Form.Group className="mt-2" controlId="formPassword">
                        <Form.Label>סיסמא*</Form.Label>
                        <Form.Control
                            name="password"
                            type="password"
                            placeholder="סיסמא"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            isInvalid={formik.touched.password && !!formik.errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {/* Field */}
                    <Form.Group className="mt-2" controlId="formField">
                        <Form.Label>תחום*</Form.Label>
                        <Form.Control
                            name="field"
                            type="text"
                            placeholder="תחום"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.field}
                            isInvalid={formik.touched.field && !!formik.errors.field}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.field}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {/* Client Type */}
                    {/*<Form.Group className="mt-2" controlId="formClientType">*/}
                    {/*    <Form.Label>סוג לקוח</Form.Label>*/}
                    {/*    <Form.Control*/}
                    {/*        name="clientType"*/}
                    {/*        type="text"*/}
                    {/*        placeholder="סוג לקוח"*/}
                    {/*        onChange={formik.handleChange}*/}
                    {/*        onBlur={formik.handleBlur}*/}
                    {/*        value={formik.values.clientType}*/}
                    {/*        isInvalid={formik.touched.clientType && !!formik.errors.clientType}*/}
                    {/*    />*/}
                    {/*    <Form.Control.Feedback type="invalid">*/}
                    {/*        {formik.errors.clientType}*/}
                    {/*    </Form.Control.Feedback>*/}
                    {/*</Form.Group>*/}

                    {/* Company Size */}
                    <Form.Group className="mt-2" controlId="formCompanySize">
                        <Form.Label>גודל החברה</Form.Label>
                        <Form.Control
                            name="companySize"
                            type="number"
                            placeholder="הכנס את גודל החברה כמספר"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.companySize}
                            isInvalid={formik.touched.companySize && !!formik.errors.companySize}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companySize}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {/* Websites */}
                    <Form.Group className="mt-2" controlId="formWebsites">
                        <Form.Label className="mb-0">אתרים</Form.Label>
                        <Form.Text className="d-block mt-0">רשימת אתרים מופרדים בפסיק (,)</Form.Text>
                        <Form.Control
                            name="websites"
                            type="text"
                            placeholder="אתרים"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.websites}
                            isInvalid={formik.touched.websites && !!formik.errors.websites}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.websites}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mt-2" controlId="formAllowMarketing">
                        <Form.Check
                            name="allowMarketing"
                            type="checkbox"
                            label="אני מרשה לשלוח לי דיוור במייל ו/או SMS"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik.values.allowMarketing}
                            isInvalid={formik.touched.allowMarketing && !!formik.errors.allowMarketing}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.allowMarketing}
                        </Form.Control.Feedback>
                    </Form.Group>


                </Modal.Body>
                <Modal.Footer>

                    <Button className="simple-button medium blue mb-2 w-auto p-3"
                            type="submit"
                            disabled={!formik.isValid || formik.isSubmitting}>
                        הרשם
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default RegisterModal;