import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Row, Table} from 'react-bootstrap';
import {toast} from "react-toastify";
import ConfirmationModal from "../../../utils/ConfirmationModal";
import {useApiService} from "../../../context/ApiServiceContext";
import Promo from "../../../models/Promo";
import {useNavigate} from "react-router-dom";


const AdminPromos: React.FC = () => {
    const apiService = useApiService();
    const navigate = useNavigate();
    const [promos, setPromos] = useState<Promo[]>([]);
    const [selectedPromo, setSelectedPromo] = useState<Promo | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const linksPerPage = 20; // Adjust as needed
    /* End of Pagination */

    const fetchPromos = useCallback(async (page = 1) => {
        try {
            const res = await apiService.api.get(`/promo/list?page=${page}&per_page=${linksPerPage}`);
            if (Array.isArray(res.data)) {
                setPromos(prevPromos => [...prevPromos, ...res.data]);
                setHasMorePages(res.data.length === linksPerPage);
            }
        } catch (error: any) {
            toast.error(`בעיה בעת משיכת החבילות: ${error.message}`);
        }
    }, [apiService, linksPerPage]);

    useEffect(() => {
        fetchPromos(currentPage);
    }, [currentPage, fetchPromos]);

    const apiCall = useCallback(async (apiFunc: () => Promise<any>, successMessage: string, errorMessage: string) => {
        try {
            const res = await apiFunc();
            toast.success(successMessage);
            return res.data;
        } catch (error) {
            toast.error(errorMessage);
        }
    }, []);

    const handleDeleteConfirmation = useCallback(() => {
        if (selectedPromo) {
            const promoId = selectedPromo.id
            apiCall(
                () => apiService.api.delete(`/promo/${promoId}`),
                'החבילה נמחקה בהצלחה',
                'בעיה בעת מחיקת החבילה'
            ).then(() => {
                setPromos(prevPromos => prevPromos.filter(pkg => pkg.id !== promoId));
                setSelectedPromo(null)
                setDeleteModalShow(false)
            });
        }
        setSelectedPromo(null)
    }, [selectedPromo, apiCall]);

    const openDeleteModal = (pkg: Promo) => {
        setSelectedPromo(pkg);
        setDeleteModalShow(true);
    };

    const PaginationBar = () => {
        return (
            <div className="d-flex justify-content-center my-3">
                <Button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="me-2"
                >
                    הקודם
                </Button>
                <Button
                    disabled={!hasMorePages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    הבא
                </Button>
            </div>
        );
    };

    function setActivePromo(promoId: string) {
        apiService.api.put(`/promo/active/${promoId}`, {})
            .then(() => {
                setPromos(promos.map(x => ({
                    ...x,
                    active: x.id === promoId
                })))
                toast.success("המבצע הפעיל שונה בהצלחה")
            })
            .catch(() => {
                toast.error("בעיה בשינוי המבצע הפעיל")
            })
    }

    function setAllToNonActive() {
        apiService.api.delete(`/promo/active`)
            .then(() => {
                setPromos(promos.map(x => ({
                    ...x,
                    active: false
                })))
                toast.success("כל המבצעים כובו בהצלחה")
            })
            .catch(() => {
                toast.error("בעיה בכיבוי המבצעים")
            })
    }

    return (
        <>
            <Row className="justify-content-center py-md-5">
                <Col md={5}>
                    <h1 className="mb-2">מבצעים</h1>
                </Col>
                <Col md={5} className="d-flex justify-content-end align-items-center">
                    <Button className="me-2" variant="danger" onClick={setAllToNonActive}>כבה את כל המבצעים</Button>
                    <Button onClick={() => navigate("/admin/promo")}>הוספת מבצע</Button>
                </Col>
                <Col md={10}>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>שם המבצע</th>
                            <th>זמן סיום</th>
                            <th>פעיל</th>
                            <th>פעולות</th>
                        </tr>
                        </thead>
                        <tbody>
                        {promos.map((promo) => (
                            <tr key={promo.id}>
                                <td>{promo.name}</td>
                                <td>{promo.deadline}</td>
                                <td>{promo.active ? '✔️' : ''}</td>
                                {/*<td>{pkg.createdAt}</td>*/}
                                {/*<td>{pkg.updatedAt}</td>*/}
                                <td>
                                    <Button className="me-2"
                                            onClick={() => navigate(`/admin/promo/${promo.id}`)}>עריכה</Button>
                                    <Button className="me-2" variant="danger" onClick={() => openDeleteModal(promo)}>מחק</Button>
                                    <Button variant="success" onClick={() => setActivePromo(promo.id)}>הפוך פעיל</Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <PaginationBar/>
                </Col>
            </Row>

            <ConfirmationModal
                show={deleteModalShow}
                onClose={() => setDeleteModalShow(false)}
                onConfirm={handleDeleteConfirmation}
                title="אישור מחיקה"
                message="האם אתה בטוח שאתה רוצה למחוק את החבילה?"
            />
        </>
    );

};

export default AdminPromos;
