import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import AnalyticsService from "../services/AnalyticsService";

const usePageView = () => {
    const location = useLocation();
    const analyticsService = AnalyticsService.getInstance();

    useEffect(() => {
        analyticsService.sendPageViewEvent({
            pagePath: location.pathname,
        });
    }, [location.pathname]);
};

export default usePageView;
