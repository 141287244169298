import React, {useState} from 'react';
import {Form, Modal} from 'react-bootstrap';
import {forgotPassword} from "../../utils/userSlice";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../utils/store";
import {Button} from "@mui/material";

interface ForgotPasswordModalProps {
    show: boolean;
    handleClose: () => void;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({show, handleClose}) => {
    const dispatch: AppDispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true)
        const success = await dispatch(await forgotPassword(email))
        setIsLoading(false)
        if (success) {
            handleClose()
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>שכחתי סיסמא</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="formEmail">
                    <Form.Label>הכנס את האיימיל שלך במערכת</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="אימייל"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button className="simple-button medium blue w-auto p-3"
                        disabled={isLoading}
                        onClick={handleSubmit}>
                    שלח
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ForgotPasswordModal;