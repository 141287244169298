import React from 'react';
import {useTheme} from "@mui/material";
import {CSSProperties} from "react/index";

type BottomBorderProps = {
    width: number;
    color?: string;
    addMargin?: boolean;
    style?: CSSProperties | undefined;
};

const BottomBorder: React.FC<BottomBorderProps> = ({width, color, addMargin = true, style}) => {
    const theme = useTheme();

    return (
        <div style={{
            height: "8px",
            width: `${width}px`,
            marginRight: addMargin ? '12px' : '0',
            background: color ?? theme.palette.secondary.main,
            ...style
        }}>
        </div>
    );
};

export default BottomBorder;